import { faUser } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import DownloadTabCard from '^/common/components/navigation/download-tab-card';

export class DownloadCsvBar extends React.PureComponent<WithTranslation> {
  public render() {
    const { t } = this.props;

    return (
      <div className="tab-bar tab-bar-download">
        <DownloadTabCard icon={faUser} link="/api/patients/csv/">
          {t('download-patients-csv')}
        </DownloadTabCard>
      </div>
    );
  }
}

export default withTranslation('home')(DownloadCsvBar);
