import {
  AsyncActionSet,
  getErrorData,
  ResponsesReducerState,
} from '@dabapps/redux-requests';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { StoreState } from '^/common/types';

interface OwnProps {
  actions?: ReadonlyArray<AsyncActionSet>;
  fields?: ReadonlyArray<string>;
  tag?: string;
  formErrors?: ReadonlyArray<string>;
  showStatusErrors?: boolean;
}

interface ConnectedProps {
  responses?: ResponsesReducerState;
}

type Props = OwnProps & ConnectedProps & WithTranslation;

export class ErrorRenderer extends React.PureComponent<Props> {
  public render() {
    const {
      t,
      responses,
      actions = [],
      fields = [],
      formErrors = [],
      showStatusErrors,
      tag,
    } = this.props;

    const errors = formErrors
      .filter(error => error && error.length >= 1)
      .map(error => t(`fields.${this.formatErrorKey(error)}`));

    if (responses) {
      const responseErrors = actions.reduce((actionMemo: string[], action) => {
        const error = getErrorData(responses, action, tag);

        if (error && error.response && error.response.data) {
          const errorData = error.response.data;

          const actionErrors = actionMemo.concat(
            fields.reduce((fieldMemo, field) => {
              if (errorData && errorData[field]) {
                const actionKey =
                  'actions.' + this.formatErrorKey(action.REQUEST);
                const errorKey = this.formatErrorKey(errorData[field]);

                if (field === 'non_field_errors') {
                  return fieldMemo.concat(t(`${actionKey}.${errorKey}`));
                }

                return fieldMemo.concat(t(`${actionKey}.${field}.${errorKey}`));
              }

              return fieldMemo;
            }, [])
          );

          const statusError = this.getStatusError(
            error.response.status,
            errorData.detail
          );

          if (showStatusErrors && statusError) {
            return actionErrors.concat([statusError]);
          } else {
            return actionErrors;
          }
        }

        return actionMemo;
      }, []);

      errors.push(...responseErrors);
    }

    if (errors.length <= 0) {
      return null;
    }

    return (
      <div className="errors">
        {errors.map(error => (
          <p className="error" key={error}>
            {error}
          </p>
        ))}
      </div>
    );
  }

  private formatErrorKey(error?: string | null) {
    if (!error) {
      return 'unknown';
    }

    return String(error)
      .toLowerCase()
      .replace(/ |_|:/g, '-')
      .replace(/\./g, '');
  }

  private getStatusError = (
    statusCode: number,
    detail: string
  ): string | null => {
    const { t } = this.props;

    return t(`status.${detail ? detail : statusCode}`, '');
  };
}

const mapStateToProps = ({ responses }: StoreState): ConnectedProps => {
  return {
    responses,
  };
};

export default compose<Props, OwnProps>(
  connect(mapStateToProps),
  withTranslation('errors')
)(ErrorRenderer);
