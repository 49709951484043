import { Button } from '@dabapps/roe';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { InjectedFormProps, reduxForm } from 'redux-form';

import ErrorRenderer from '^/common/components/form/error-renderer';
import TrialFormGroups, {
  validate,
} from '^/trials/components/trial-form-group';
import { UPDATE_TRIAL } from '^/trials/trial-actions';
import { Trial } from '^/trials/trial-types';

const actions = [UPDATE_TRIAL];

export type Props = InjectedFormProps<Trial, {}> & WithTranslation;

export class EditTrialForm extends React.Component<Props> {
  public render() {
    const { t, handleSubmit, submitting, pristine } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <hr />
        <TrialFormGroups formName={this.props.form} />
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors']}
          showStatusErrors
        />
        <hr />
        <div className="form-buttons">
          <Button
            type="submit"
            className="primary pill"
            disabled={submitting || pristine}
          >
            {t('buttons.save-changes')}
          </Button>
        </div>
      </form>
    );
  }
}

export default reduxForm<Trial, {}>({
  form: 'editTrialForm',
  destroyOnUnmount: true,
  validate,
})(withTranslation('trials')(EditTrialForm));
