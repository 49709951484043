import * as Sentry from '@sentry/react';
import { ConnectedRouter } from 'connected-react-router';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import PrivacyPage from '^/common/components/containers/footer/privacy-page';
import TermsPage from '^/common/components/containers/footer/terms-page';
import TrainingPage from '^/common/components/containers/footer/training-page/training-page';
import LoadingPage from '^/common/components/loading-page';
import NotFoundPage from '^/common/components/navigation/not-found-page';
import { history, store } from '^/common/store';
import DownloadCsvPage from '^/download-csv/components/download-csv-page';
import HomePage from '^/home/components/home-page';
import LoginPage from '^/login/components/login-page';
import PasswordResetPage from '^/password-reset/components/password-reset-page';
import PasswordResetPageConfirm from '^/password-reset/components/password-reset-page-confirm';
import PatientPageList from '^/patients/components/patient-page-list';
import PICInformationPage from '^/pic-information/components/pic-information-page';
import PICInformationPageEdit from '^/pic-information/components/pic-information-page-edit';
import ReportPage from '^/reports/components/report-page';
import TrialPageEdit from '^/trials/components/trial-page-edit';
import TrialsPage from '^/trials/components/trial-page-list';
import UserPageEdit from '^/users/components/user-page-edit';
import UsersPage from '^/users/components/user-page-list';
import './i18n';

Sentry.init({
  dsn: (window as any).SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/login" component={LoginPage} />
          <Route exact path="/password-reset" component={PasswordResetPage} />
          <Route
            path="/password-reset/confirm"
            component={PasswordResetPageConfirm}
          />
          <Route exact path="/reports" component={ReportPage} />
          <Route exact path="/home" component={DownloadCsvPage} />
          <Route exact path="/trials" component={TrialsPage} />
          <Route path="/trials/:id" component={TrialPageEdit} />
          <Route exact path="/users" component={UsersPage} />
          <Route path="/users/:id" component={UserPageEdit} />
          <Route path="/patients" component={PatientPageList} />
          <Route exact path="/pic" component={PICInformationPage} />
          <Route path="/pic/:id/" component={PICInformationPageEdit} />
          <Route path="/terms" component={TermsPage} />
          <Route path="/privacy" component={PrivacyPage} />
          <Route path="/training" component={TrainingPage} />
          <Route path="*" exact component={NotFoundPage} />
        </Switch>
      </Suspense>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app')
);
