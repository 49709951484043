import {
  dispatchGenericRequest,
  makeAsyncActionSet,
} from '@dabapps/redux-requests';
import { Dispatch } from 'redux';

export const GET_ALL_TRIALS = makeAsyncActionSet('GET_ALL_TRIALS');
export function getAllTrials() {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      GET_ALL_TRIALS,
      '/api/reports/trials/',
      'GET'
    )(dispatch);
  };
}

export const GET_ALL_SITE_OPTIONS = makeAsyncActionSet('GET_ALL_SITE_OPTIONS');
export function getAllSiteOptions(trial?: string) {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      GET_ALL_SITE_OPTIONS,
      `/api/reports/sites/${trial ? trial : ''}`,
      'GET',
      '',
      'single'
    )(dispatch);
  };
}

export const GET_ALL_COMPARISON_SITE_OPTIONS = makeAsyncActionSet(
  'GET_ALL_COMPARISON_SITE_OPTIONS'
);
export function getAllComparisonSiteOptions(trial?: string) {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      GET_ALL_COMPARISON_SITE_OPTIONS,
      `/api/reports/sites/${trial ? trial : ''}`,
      'GET',
      '',
      'comparison'
    )(dispatch);
  };
}

export const TOGGLE_FILTER_SITES_ACTIVE = 'TOGGLE_FILTER_SITES_ACTIVE';
export const toggleFilterSitesActive = () => ({
  type: TOGGLE_FILTER_SITES_ACTIVE,
});

export const TOGGLE_SITES_COLLAPSED = 'TOGGLE_SITES_COLLAPSED';
export const toggleSitesCollapsed = () => ({
  type: TOGGLE_SITES_COLLAPSED,
});

export const TOGGLE_SCREENINGS_BOOKED_VIEW_MODE =
  'TOGGLE_SCREENINGS_BOOKED_VIEW_MODE';
export const toggleScreeningsBookedViewMode = () => ({
  type: TOGGLE_SCREENINGS_BOOKED_VIEW_MODE,
});
