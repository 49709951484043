import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import LoadingRenderer from '^/common/components/form/loading-renderer';
import { StoreState } from '^/common/types';
import { setUserFilterTerm } from '^/users/user-actions';

export type DropdownOptions = ReadonlyArray<{
  label: string;
  value: string;
}>;

interface DispatchProps {
  setUserFilterTerm: typeof setUserFilterTerm;
}

interface ConnectedProps {
  roles?: StoreState['roles'];
  userFilterTerm: string | null;
}

export type Props = DispatchProps & ConnectedProps & WithTranslation;

export class DropdownFilter extends React.PureComponent<Props> {
  public render() {
    const { t, roles, userFilterTerm } = this.props;

    if (!roles) {
      return <LoadingRenderer />;
    }

    const roleOptions = roles.map(role => {
      return { label: t(`roles.${role.id}`), value: role.id };
    });

    return (
      <div>
        {roleOptions && (
          <select
            onChange={this.handleSelectChange}
            className=""
            value={userFilterTerm ? userFilterTerm : 'None'}
          >
            <option value="None" disabled hidden>
              {t('user-role-filter-label')}
            </option>
            <option value="">{t('user-role-filter-none')}</option>
            {roleOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        )}
      </div>
    );
  }
  private handleSelectChange = (event: React.ChangeEvent | undefined) => {
    if (event) {
      this.props.setUserFilterTerm((event.target as HTMLInputElement).value);
    }
  };
}

export const mapStateToProps = ({ userFilterTerm, roles }: StoreState) => {
  return {
    userFilterTerm,
    roles,
  };
};

export default compose<Props, {}>(
  connect(mapStateToProps, {
    setUserFilterTerm,
  }),
  withTranslation('users')
)(DropdownFilter);
