import {
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faSortShapesDown,
  faSortShapesUp,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { WrappedFieldProps } from 'redux-form';

interface OwnProps {
  placeholder?: string;
  sortType?: 'numeric' | 'alpha';
}

export type RenderSortDirectionProps = OwnProps & WrappedFieldProps;

const RenderSortDirection: React.FC<RenderSortDirectionProps> = ({
  input,
  placeholder,
  sortType,
}) => {
  const toggleSort = () => input.onChange(!Boolean(input.value));

  return (
    <button
      className="render-sort-direction"
      onClick={toggleSort}
      type="button"
    >
      <FontAwesomeIcon icon={getSortIcon(Boolean(input.value), sortType)} />
      {placeholder}
    </button>
  );
};

function getSortIcon(down: boolean, sortType?: 'numeric' | 'alpha') {
  switch (sortType) {
    case 'numeric':
      return down ? faSortNumericUp : faSortNumericDown;
    case 'alpha':
      return down ? faSortAlphaDown : faSortAlphaUp;
    default:
      return down ? faSortShapesDown : faSortShapesUp;
  }
}

export { RenderSortDirection as TestableRenderSortDirection };

export default RenderSortDirection;
