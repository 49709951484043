import React from 'react';
import { Trans } from 'react-i18next';

export const OPENING_PARAGRAPH: React.ReactNode = (
  <p>
    <Trans i18nKey="privacy:opening-paragraph">
      <p>
        Privacy, cookies and legal disclaimer - your privacy at
        novonordisk.co.uk
      </p>
      This website is created to provide information about our company, products
      and services. This disclaimer applies only to this website and describes
      how and why we collect information about you as a user.
    </Trans>
  </p>
);

export const LIST_ITEM_1: React.ReactNode = (
  <div>
    <Trans i18nKey="privacy:The information we collect">
      <strong>
        <li>The information we collect</li>
      </strong>
      <p>
        We collect information that can help us improve our websites. Some
        information we ask you for directly, but other information is collected
        automatically. All information collected automatically is stored in an
        aggregate form and cannot be used to identify you as a specific
        individual. We occasionally ask you for information that can at times be
        personally identifiable and private. As a result, information collected
        by us can be divided into two main categories:
        <ol type="a">
          <li>Aggregated visitor statistics</li>
          <li>Personally identifiable information</li>
        </ol>
        <p>
          We do not link aggregated visitor statistics with personally
          identifiable information.
        </p>
        <p>
          <u>Aggregated visitor statistics</u>

          <p>
            When you visit our website, we automatically collect general
            information about your computer and its location as well as the
            website you came from, if applicable. This information cannot be
            used to identify you as an individual. This information is only used
            in aggregate form to inform us where our visitors come from, what
            they look at and act on, and where the most time is spent.
            Information we collect automatically:
          </p>
          <ul>
            <li>
              First-party cookie support (whether you allow us to place a cookie
              on your computer)
            </li>
            <li>
              Visitor ID (provided by a cookie we place on your computer when
              possible)
            </li>
            <li>Referrer (i.e. where you came from, eg google.com)</li>
            <li>Date and time of visit</li>
            <li>
              Regional and language settings (to determine which country you are
              in)
            </li>
            <li>Operating System (Windows, OS X, Linux, iOS, Android etc.)</li>
            <li>
              Browser & Browser Version (Chrome, Internet Explorer, Firefox,
              Opera, Safari etc.)
            </li>
            <li>Screen Resolution (1280x1024, 1024x768, etc.)</li>
            <li>JavaScript support</li>
            <li>Java support</li>
            <li>IP address (computer address on the internet)</li>
            <li>The title of the page you are viewing</li>
            <li>The URL of the page you are viewing</li>
          </ul>
        </p>
        <p>
          <u>Personally identifiable information</u>
          <p>
            At times, we need to collect more personal data about you. Some
            areas/parts of our website might require you to register in order to
            log in; we might ask you for information such as your age and
            occupation to better understand the purpose of your visit and to
            improve your visitor experience. In any case, whenever we ask you
            for any personally identifiable information that can be used to
            identify you as an individual, we will always provide an explanation
            as to why we collect the information, how we collect it, how we
            store it and how we plan on treating and using it.
          </p>
        </p>
      </p>
    </Trans>
  </div>
);

export const LIST_ITEM_2: React.ReactNode = (
  <div>
    <Trans i18nKey="privacy:How information is used">
      <strong>
        <li>How information is used</li>
      </strong>
      <p>
        We will only collect and store personally identifiable information for
        the purpose stated during the collecting process. When we finish
        processing your information for the stated purpose, we will erase and
        destroy it to ensure your privacy.
      </p>
    </Trans>
  </div>
);

export const LIST_ITEM_3: React.ReactNode = (
  <div>
    <Trans i18nKey="privacy:Collection of sensitive data">
      <strong>
        <li>Collection of sensitive data</li>
      </strong>
      <p>
        We do not collect or retain sensitive personal data relating to your
        health, ethnic origin, religious beliefs or political conviction etc at
        this site. In the rare case where we do seek to collect other sensitive
        data we will do so in strict compliance with local data privacy law.
      </p>
    </Trans>
  </div>
);

export const LIST_ITEM_4: React.ReactNode = (
  <div>
    <Trans i18nKey="privacy:Protection of children">
      <strong>
        <li>Protection of children</li>
      </strong>
      <p>
        <p>
          Protecting the privacy of children is important. Novo Nordisk does not
          intend to collect personally identifiable information from children (a
          child is defined as being under the age of 18) without permission of
          the parent or legal guardian. Children should NOT submit personally
          identifiable information to Novo Nordisk without the explicit
          permission of their parent or legal guardian.
        </p>
        <p>
          Where appropriate, we will instruct children not to submit personal
          data. If your child has submitted personal data and you would like to
          request that such information be removed, please contact{' '}
          <a href="mailto: privacyuk@novnonordisk.com">
            privacyuk@novonordisk.com
          </a>
          .
        </p>
      </p>
    </Trans>
  </div>
);

export const LIST_ITEM_5: React.ReactNode = (
  <div>
    <Trans i18nKey="privacy:How we ensure data security">
      <strong>
        <li>How we ensure data security</li>
      </strong>
      <p>
        Personal data are collected on this website only to the extent required.
        Under no circumstances are the collected data sold on to third parties
        for any reason.
      </p>
    </Trans>
  </div>
);

export const LIST_ITEM_6: React.ReactNode = (
  <div>
    <Trans i18nKey="privacy:Where are the data processed?">
      <strong>
        <li>Where are the data processed?</li>
      </strong>
      <p>
        Any personal data we collect may be transferred internationally within
        Novo Nordisk’s global organisation. We have internal policies in place
        to ensure an adequate level of protection irrespective of where in Novo
        Nordisk your data are located. Please learn more about our position on
        personal data protection by clicking{' '}
        <a href="https://www.novonordisk.com/about-novo-nordisk/corporate-governance/personal-data-protection.html">
          <u>here</u>
        </a>
        .
      </p>
    </Trans>
  </div>
);

export const LIST_ITEM_7: React.ReactNode = (
  <div>
    <Trans i18nKey="privacy:Disclosure to third parties">
      <strong>
        <li>Disclosure to third parties</li>
      </strong>
      <p>
        <p>
          We will never transfer or sell personally identifiable information
          about our visitors to a third party.
        </p>
        <p>
          We will generally only share information with our contracted service
          providers and advisors. However, in order to comply with legal
          obligations, to protect the rights, property or safety of Novo
          Nordisk, its employees and others, or in the event we sell or
          liquidate any part of business or assets, personal data may be shared.
          Any such sharing will be in compliance with data privacy law.
        </p>
      </p>
    </Trans>
  </div>
);

export const LIST_ITEM_8: React.ReactNode = (
  <div>
    <Trans i18nKey="privacy:Visitor rights">
      <strong>
        <li>Visitor rights</li>
      </strong>
      <p>
        You are entitled to know whether we hold personal data about you and, if
        we do, obtain insight to that data and require the data to be corrected
        if inaccurate. You are also entitled to know the purposes and you may
        object to Novo Nordisk’s use of your personal data. Please contact
        <a href="mailto: privacyuk@novnonordisk.com">
          privacyuk@novonordisk.com
        </a>{' '}
        if you wish to obtain insight in your personal data or if you have any
        concerns in this regard.
      </p>
    </Trans>
  </div>
);

export const LIST_ITEM_9: React.ReactNode = (
  <div>
    <Trans i18nKey="privacy:Use of cookies">
      <strong>
        <li>Use of cookies</li>
      </strong>
      <p>
        <p>
          A cookie is a simple text file that is stored on your computer or
          mobile device by a website’s server; only that server will be able to
          retrieve or read the contents of that cookie. Each cookie is unique to
          your web browser. It will contain some anonymous information such as a
          unique identifier and the site name and some digits and numbers. It
          allows a website to remember your preferences – eg choice of language.
        </p>
        <p>
          At Novo Nordisk we use cookies that are strictly necessary to enable
          you to move around the site or to provide certain basic features. We
          use cookies to enhance the functionality of the website by storing
          your preferences. We also use cookies to help us to improve the
          performance of our website to provide you with a better user
          experience.
        </p>
        <p>
          Strictly necessary cookies; Cookies that are strictly necessary in
          order to enable you to move around the website and use its features.
        </p>
        <p>
          Functionality cookies; Functionality cookies record information about
          choices you’ve made and allow us to tailor the website to you.
        </p>
        <p>
          Performance cookies; Performance cookies are used across Novo Nordisk
          websites for internal purposes to help us to provide you with a better
          user experience.
        </p>
        <p>
          Information supplied by these cookies helps us understand how our
          visitors use novonordisk.com so that we can improve the content. You
          can learn more about cookies in general{' '}
          <a href="http://www.aboutcookies.org.uk">
            <u>here</u>
          </a>
          .
        </p>
      </p>
    </Trans>
  </div>
);

export const LIST_ITEM_10: React.ReactNode = (
  <div>
    <Trans i18nKey="privacy:Legal disclaimers">
      <strong>
        <li>Legal disclaimers</li>
      </strong>
      <p>
        <u>Information purposes</u>
        <p>
          The content presented on the Novo Nordisk website is presented solely
          for informational purposes. The site does not provide you with advice
          or recommendation of any kind and should not be relied on as the basis
          for any decision or action. You are advised to consult professional
          advisors in the appropriate field with respect to the applicability of
          any particular aspect of the contents. In particular, nothing on this
          website constitutes an invitation or offer to invest or deal in Novo
          Nordisk securities. Further, the site provides selected information of
          diseases and their treatment. Such information is not intended as
          medical advice. Such information is not a substitute for the advice of
          a healthcare professional. If you have, or suspect having, any health
          problems, you should consult your general practitioner or other
          qualified health provider.
        </p>
        <u>Information provided ''as is''</u>
        <p>
          The information on this site is provided ‘as is’ and Novo Nordisk
          makes no representations or warranties, expressed or implied,
          including but not limited to the implied warranties of
          merchantability, fitness for a particular purpose or non-infringement.
          Novo Nordisk makes no representations or warranties of any kind as to
          the completeness, accuracy, timeliness, availability, functionality
          and compliance with applicable laws. By using this website you accept
          the risk that the information may be incomplete or inaccurate or may
          not meet your needs or requirements.
        </p>
      </p>
      <p>
        <u>Disclaimed liability</u>
        <p>
          Neither Novo Nordisk nor our content providers shall be liable for any
          damages or injury arising out of your access to, or inability to
          access, this site or from your reliance on any information provided
          herein. Novo Nordisk disclaims any and all liability for direct,
          indirect, incidental, consequential, punitive, and special or other
          damages, lost opportunities, lost profit or any other loss or damages
          of any kind. This limitation includes damages or any viruses, which
          may affect your computer equipment.
        </p>
      </p>
      <p>
        <u>Links to other sites</u>
        <p>
          This website contains links to other sites that are not owned or
          controlled by Novo Nordisk; please be aware that we are not
          responsible for or have control over the privacy policies of these
          sites.This privacy statement applies only to information collected on
          this site. We strongly encourage you to read the privacy statements of
          every site you visit that collects personally identifiable
          information.
        </p>
      </p>
      <p>
        <u>Sites linking to us</u>
        <p>
          Novo Nordisk does not endorse websites linking to Novo Nordisk sites.
          Novo Nordisk is not responsible for content on these liked sites or
          for control over information that users may choose to provide to these
          sites.
        </p>
      </p>
      <p>
        <u>Alterations</u>
        <p>
          Novo Nordisk reserves its right to alter, modify, substitute or delete
          any content of or may restrict access to or discontinue distribution
          of this site at any time and at its sole discretion.
        </p>
      </p>
      <p>
        <u>Copyright and use of content</u>
        <p>
          The content of this website is the property of Novo Nordisk and is
          protected by copyright laws. The trademarks, service marks, trade
          names, logos and product displayed on this site are protected
          worldwide, and no use of any of these may be made without the prior
          written consent of Novo Nordisk. You are welcome to download the
          content of this website, however, only for your personal and
          non-commercial use. No modification or further reproduction of the
          contents is permitted. The content may otherwise not be copied or used
          in any other manner.
        </p>
      </p>
      <p>
        <u>Use of questions and comments</u>
        <p>
          Any questions, comments, suggestions or any other communications,
          including any ideas, inventions, concepts, techniques or know-how you
          may forward to this site or otherwise to Novo Nordisk, electronically
          or by any other means, are on a non-confidential basis and will become
          the property of Novo Nordisk, which Novo Nordisk without restriction
          may use in any fashion and for any purposes whatsoever, including
          developing, manufacturing and/or marketing goods or services.
        </p>
      </p>
      <p>
        <u>Governing Law</u>
        <p>
          Your access and use of this website and the contents hereof shall be
          governed by and interpreted in accordance with the Laws of United
          Kingdom.
        </p>
      </p>
    </Trans>
  </div>
);

export const LIST_ITEM_11: React.ReactNode = (
  <div>
    <Trans i18nKey="privacy:Who to contact">
      <strong>
        <li>Who to contact</li>
      </strong>
      <p>
        If you have any questions about this privacy, cookies and legal
        disclaimer, if you want to obtain insight in any personal data held
        about you, or if you have any concerns regarding Novo Nordisk’s use of
        your personal data you may contact us via{' '}
        <a href="mailto: privacyuk@novnonordisk.com">
          privacyuk@novonordisk.com
        </a>
        .
      </p>
    </Trans>
  </div>
);
