import { ModalRenderer } from '@dabapps/roe';
import React from 'react';
import { connect } from 'react-redux';

import { StoreState } from '^/common/types';

interface ConnectedProps {
  modals: StoreState['modals'];
}

export type GlobalModalRendererProps = ConnectedProps;

class GlobalModalRenderer extends React.PureComponent<
  GlobalModalRendererProps
> {
  public render() {
    return <ModalRenderer modals={this.props.modals} />;
  }
}

// Disconnected version used for testing
export { GlobalModalRenderer as TestableGlobalModalRenderer };

export const mapState = (state: StoreState) => ({
  modals: state.modals,
});

const connector = connect(mapState, {});

export default connector(GlobalModalRenderer);
