import { ContentBox, ContentBoxHeader } from '@dabapps/roe';
import { faCheck, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import { StoreState } from '^/common/types';
import {
  addPendingTrialsiteUser,
  getTrialsiteUsersByTrialsite,
  removePendingTrialsiteUser,
} from '^/trialsites/trialsite-actions';
import { PendingTrialsiteUser } from '^/trialsites/trialsite-types';
import { User } from '^/users/user-types';

export interface OwnProps {
  user: User;
  trialsiteId: string;
}

export interface ConnectedProps {
  pendingTrialsiteUsers: ReadonlyArray<PendingTrialsiteUser>;
}

export interface DispatchProps {
  addPendingTrialsiteUser: typeof addPendingTrialsiteUser;
  removePendingTrialsiteUser: typeof removePendingTrialsiteUser;
  getTrialsiteUsersByTrialsite: typeof getTrialsiteUsersByTrialsite;
}

export type AddUserCardProps = OwnProps & ConnectedProps & DispatchProps;

class AddUserCard extends React.PureComponent<AddUserCardProps> {
  public componentWillMount() {
    this.props.getTrialsiteUsersByTrialsite(this.props.trialsiteId);
  }

  public render() {
    const { user, trialsiteId, pendingTrialsiteUsers } = this.props;

    const pendingTrialsiteUser = pendingTrialsiteUsers.find(
      x => x.userId === user.id && x.trialsiteId === trialsiteId
    );

    return (
      <ContentBox
        className={classNames('collapsable-card', 'add-trialsite-user', {
          expanded: pendingTrialsiteUser,
        })}
      >
        <ContentBoxHeader className={classNames('card-header')}>
          <button
            id="add-trialsite-card-click"
            className="card-header-button"
            onClick={this.onAddNewCollapseClick}
          >
            <FontAwesomeIcon
              className="trialsite-add-button"
              size="2x"
              icon={pendingTrialsiteUser ? faCheck : faPlus}
            />
          </button>
          <div className="add-trialsites-user-info">
            <p className="user-name">{user.full_name}</p>
            <p className="user-email">{user.email}</p>
          </div>
          <div className="card-header-button" />
        </ContentBoxHeader>
      </ContentBox>
    );
  }

  public onAddNewCollapseClick = () => {
    const { trialsiteId, user, pendingTrialsiteUsers } = this.props;
    const pendingTrialsiteUser = pendingTrialsiteUsers.find(
      x => x.trialsiteId === trialsiteId && x.userId === user.id
    );
    pendingTrialsiteUser
      ? this.props.removePendingTrialsiteUser(trialsiteId, user.id)
      : this.props.addPendingTrialsiteUser(trialsiteId, user.id);
  };
}

export { AddUserCard as TestableAddUserCard };

export const mapStateToProps = ({
  pendingTrialsiteUsers,
}: StoreState): ConnectedProps => {
  return {
    pendingTrialsiteUsers,
  };
};

export default connect(mapStateToProps, {
  addPendingTrialsiteUser,
  removePendingTrialsiteUser,
  getTrialsiteUsersByTrialsite,
})(AddUserCard);
