import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import ErrorRenderer from '^/common/components/form/error-renderer';

export type DropdownOptions = ReadonlyArray<{
  label: string;
  value: string;
  disabled?: boolean;
}>;

interface OwnProps {
  label: string;
  placeholder?: string;
  options: DropdownOptions;
  disabled?: boolean;
  icon?: IconProp;
  size?: 'sm';
}

export type Props = OwnProps &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps;

export class RenderDropdown extends React.PureComponent<Props> {
  public render() {
    const {
      input,
      label,
      placeholder,
      meta: { touched, error, warning },
      options = [],
      disabled,
      icon,
      size,
    } = this.props;

    return (
      <div
        className={classNames('form-field', {
          sm: size === 'sm',
          lg: size === 'lg',
          placeholder: placeholder && (!input || !input.value),
        })}
      >
        {label && <label>{label}</label>}
        <div
          className={classNames(
            'form-field-input',
            { error: touched && error },
            { warning: touched && warning }
          )}
        >
          {icon && <FontAwesomeIcon className="field-icon" icon={icon} />}
          <select {...input} disabled={disabled}>
            {placeholder && (
              <option value="" disabled>
                {placeholder}
              </option>
            )}
            {options.map(option => (
              <option
                key={option.value}
                value={option.value}
                disabled={option.disabled}
              >
                {option.label}
              </option>
            ))}
          </select>
        </div>
        {touched && <ErrorRenderer formErrors={[error, warning]} />}
      </div>
    );
  }
}

export default RenderDropdown;
