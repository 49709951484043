import { SimpleRecord } from '@dabapps/simple-records';
import { Moment } from 'moment';

import { UserRoles } from '^/roles/role-types';
import { Trial } from '^/trials/trial-types';
import { User } from '^/users/user-types';

export type Trialsite = Readonly<{
  id?: string;
  created?: string;
  modified?: string;
  site_reference: string;
  trial: string;
  trial_detail?: Trial;
  country: string;
}>;

export type TrialsiteUser = Readonly<{
  id: string;
  trialsite: string;
  user: string;
  user_detail: User;
}>;

export type PendingTrialsiteUser = Readonly<{
  trialsiteId: string;
  userId: string;
}>;

export interface SelectedTrialsite {
  trialsite: Trialsite;
  lastUpdatedPatient?: Moment | null;
  lastUpdatedPICInformation?: Moment | null;
}

export const TrialsiteRecord = SimpleRecord<Trialsite>({
  id: '',
  site_reference: '',
  trial: '',
  country: 'GB',
});

export const TrialsiteUserRecord = SimpleRecord<TrialsiteUser>({
  id: '',
  trialsite: '',
  user: '',
  user_detail: {
    active: true,
    full_name: '',
    id: '',
    email: '',
    roles: UserRoles.SITE,
    sso_id: '',
    impact_id: '',
  },
});
