import { makeAsyncActionSet } from '@dabapps/redux-requests';
import { faChartLine } from '@fortawesome/pro-light-svg-icons';
import { LineSerieData, LineSliceData, ResponsiveLine } from '@nivo/line';
import moment from 'moment';
import React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { compose } from 'recompose';

import {
  createWidget,
  InjectedWidgetProps,
} from '^/reports/components/hoc/create-widget';
import { RecruitmentSourceData } from '^/reports/components/widgets/recruitment-source/recruitment-source-types';

type Props = InjectedWidgetProps<RecruitmentSourceData> & WithTranslation;

export class RecruitmentSource extends React.PureComponent<Props> {
  public render() {
    const {
      t,
      data: { sources, resolution },
    } = this.props;

    if (!sources || !sources.length) {
      return <p>{t('empty-state')}</p>;
    }

    const translateDataPoint = (x: LineSerieData) => ({
      ...x,
      id: t(`patients:sources.${x.id || 'unknown'}`),
      data: x.data.map(p => {
        const parsedDate = moment(p.x || 'invalid', 'YYYY-MM-DD');
        const formatString =
          resolution === 'YEARS'
            ? 'YYYY'
            : resolution === 'DAYS'
            ? 'Do'
            : 'MMM YY';

        return {
          ...p,
          x: parsedDate.isValid ? parsedDate.format(formatString) : p.x,
        };
      }),
    });

    return (
      <div className="recruitment-source-container">
        <ResponsiveLine
          tooltip={this.tooltip}
          data={sources.map(translateDataPoint)}
          margin={{ top: 50, right: 30, bottom: 60, left: 60 }}
          xScale={{ type: 'point' }}
          yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: 36,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: t('recruitment-source.left-axis'),
            legendOffset: -40,
            legendPosition: 'middle',
          }}
          legends={[
            {
              anchor: 'top',
              direction: 'row',
              translateY: -20,
              itemDirection: 'left-to-right',
              itemWidth: 150,
              itemHeight: 10,
              itemOpacity: 1,
              symbolSize: 12,
              symbolShape: 'circle',
            },
          ]}
          enableGridX={false}
        />
      </div>
    );
  }

  public tooltip(slice: LineSliceData) {
    return (
      <div className="recruitment-source-tooltip">
        <h1>{slice.id}</h1>
        <ul>
          {slice.data.map(datum => (
            <li key={datum.serie.id} className="datum">
              <strong className="key">{datum.serie.id || 'Unknown'}</strong>
              {': '}
              <span className="value">{datum.data.y}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

const GET_RECRUITMENT_SOURCE_DATA = makeAsyncActionSet(
  'GET_RECRUITMENT_SOURCE_DATA'
);

export default compose<Props, {}>(
  createWidget<RecruitmentSourceData>({
    icon: faChartLine,
    title: (
      <Trans i18nKey="reports:recruitment-source.heading">
        Recruitment Source
      </Trans>
    ),
    url: '/api/reports/recruitment-source/',
    actionSet: GET_RECRUITMENT_SOURCE_DATA,
    halfWidth: true,
  }),
  withTranslation(['reports', 'patients'])
)(RecruitmentSource);
