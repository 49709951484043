import { anyPending } from '@dabapps/redux-requests';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Card from '^/common/components/containers/card';
import CollapsableCard from '^/common/components/containers/collapsable-card';
import PageContainer from '^/common/components/containers/page-container';
import ErrorRenderer from '^/common/components/form/error-renderer';
import LoadingRenderer from '^/common/components/form/loading-renderer';
import { StoreState } from '^/common/types';
import CreatePICInformationForm from '^/pic-information/components/pic-information-form-create';
import PICInformationTable from '^/pic-information/components/pic-information-table';
import {
  createPICInformation,
  GET_PIC_INFORMATION_BY_TRIALSITE,
  toggleAddPICInformation,
} from '^/pic-information/pic-information-actions';
import {
  PICInformationInput,
  PICInformationRecord,
} from '^/pic-information/pic-information-types';
import { SelectedTrialsite } from '^/trialsites/trialsite-types';

const actions = [GET_PIC_INFORMATION_BY_TRIALSITE];

interface ConnectedProps {
  addPICInformationExpanded: boolean;
  selectedTrialsite: SelectedTrialsite | null;
  isLoading: boolean;
}

interface DispatchProps {
  createPICInformation: typeof createPICInformation;
  toggleAddPICInformation: typeof toggleAddPICInformation;
}

export type PICInformationPageProps = DispatchProps &
  ConnectedProps &
  WithTranslation;

class PICInformationPage extends React.PureComponent<PICInformationPageProps> {
  public render() {
    const { t } = this.props;

    return (
      <PageContainer>
        <CollapsableCard
          header={t('cards.add-new-pic-information')}
          icon="add"
          expanded={this.props.addPICInformationExpanded}
          onClick={this.props.toggleAddPICInformation}
        >
          {this.props.selectedTrialsite &&
          this.props.selectedTrialsite.trialsite.id ? (
            <CreatePICInformationForm
              onSubmit={this.onCreatePICInformationSubmit}
              trialsite={this.props.selectedTrialsite.trialsite.id}
            />
          ) : (
            <LoadingRenderer />
          )}
        </CollapsableCard>
        <Card type="PICInformation" header={t('cards.pic-information')}>
          {this.props.isLoading && <LoadingRenderer />}
          <PICInformationTable />

          <ErrorRenderer
            actions={actions}
            fields={['non_field_errors']}
            showStatusErrors
          />
        </Card>
      </PageContainer>
    );
  }

  public onCreatePICInformationSubmit = (values: PICInformationInput) => {
    if (
      this.props.selectedTrialsite &&
      this.props.selectedTrialsite.trialsite.id
    ) {
      values = {
        ...values,
        trialsite: this.props.selectedTrialsite.trialsite.id,
      };
      this.props.createPICInformation(PICInformationRecord({ ...values }));
    }
  };
}

export { PICInformationPage as TestablePICInformationPage };

export const mapStateToProps = ({
  addPICInformationExpanded,
  selectedTrialsite,
  responses,
}: StoreState): ConnectedProps => {
  return {
    addPICInformationExpanded,
    selectedTrialsite,
    isLoading: anyPending(responses, actions),
  };
};

export default compose<PICInformationPageProps, {}>(
  connect(mapStateToProps, {
    createPICInformation,
    toggleAddPICInformation,
  }),
  withTranslation('pic')
)(PICInformationPage);
