import { Modal } from '@dabapps/roe';

import { ModalsState, ModalType } from '^/modals/modal-types';

export const SHOW_MODAL = 'SHOW_MODAL';
export const showModal = (modal: Partial<ModalsState>) => ({
  type: SHOW_MODAL,
  payload: modal,
});

export const HIDE_MODAL = 'HIDE_MODAL';
export const hideModal = (modal: ModalType) => ({
  type: HIDE_MODAL,
  payload: modal,
});

export const OPEN_MODAL = 'OPEN_MODAL';
export const openModal = (element: React.ReactElement<Modal>) => ({
  type: OPEN_MODAL,
  payload: element,
});

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const closeModal = () => ({
  type: CLOSE_MODAL,
});

export const CLOSE_ALL_MODALS = 'CLOSE_ALL_MODALS';
export const closeAllModals = () => ({
  type: CLOSE_ALL_MODALS,
});
