import { Moment } from 'moment';

import { AnyAction } from '^/common/types';
import { CHANGE_CALENDAR_MONTH } from '^/reports/components/widgets/screenings-booked/screenings-booked-actions';

export function screeningsBookedSelectedMonth(
  state: Moment | null = null,
  action: AnyAction
) {
  switch (action.type) {
    case CHANGE_CALENDAR_MONTH:
      return action.payload;
    default:
      return state;
  }
}
