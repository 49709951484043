import { faArrowDown, faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';

interface ExternalProps {
  value: string | number;
  rate?: number;
  description: string;
  subDescription?: JSX.Element;
  colorAlt?: boolean;
}

type Props = ExternalProps;

const ScreeningsData = (props: Props) => {
  return (
    <div className="screening-data">
      <div className="screening-inner">
        <div className="screening-data-info">
          <div
            className={classNames('screening-data-wrapper', {
              'screenings-color-alt': Boolean(props.colorAlt),
            })}
          >
            <p className="screening-data-value">
              {props.value}
              {props.rate !== undefined && (
                <div className="screening-data-change">
                  {props.rate !== 0 && (
                    <FontAwesomeIcon
                      icon={props.rate > 0 ? faArrowUp : faArrowDown}
                    />
                  )}
                  <p>{`${Math.floor(Math.abs(props.rate))}%`}</p>
                </div>
              )}
            </p>
          </div>
        </div>
        <div className="screening-description">
          <p>{props.description.toUpperCase()}</p>
          {props.subDescription && props.subDescription}
        </div>
      </div>
    </div>
  );
};

export default ScreeningsData;
