import { connectRouter, routerMiddleware } from 'connected-react-router';
import createBrowserHistory from 'history/createBrowserHistory';
import { applyMiddleware, createStore, Middleware } from 'redux';
import thunk from 'redux-thunk';

import checkForLogoutMiddleware from '^/common/middleware/check-for-logout';
import rootReducer from '^/common/reducers';
import { StoreState } from '^/common/types';
import { UserRecord } from '^/users/user-types';

export const history = createBrowserHistory();

const loggedInUser = window.LOGGED_IN_USER
  ? UserRecord(window.LOGGED_IN_USER)
  : undefined;

const middleware: Array<Middleware<{}, StoreState>> = [
  checkForLogoutMiddleware,
  routerMiddleware(history),
  thunk,
];

export const store = createStore(
  connectRouter(history)(rootReducer),
  { loggedInUser },
  applyMiddleware(...middleware)
);

export default store;
