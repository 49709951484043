import { anyPending } from '@dabapps/redux-requests';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Section,
} from '@dabapps/roe';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import ErrorRenderer from '^/common/components/form/error-renderer';
import LoadingRenderer from '^/common/components/form/loading-renderer';
import { StoreState } from '^/common/types';
import { hideModal } from '^/modals/modal-actions';
import { ModalType } from '^/modals/modal-types';
import { DELETE_PATIENT, deletePatient } from '^/patients/patient-actions';

const actions = [DELETE_PATIENT];

interface ConnectedProps {
  isLoading: boolean;
  patientId?: string;
}

interface DispatchProps {
  deletePatient: typeof deletePatient;
  hideModal: typeof hideModal;
}

export type ConfirmDeletePatientModalProps = ConnectedProps &
  DispatchProps &
  WithTranslation;

class ConfirmDeletePatientModal extends React.PureComponent<
  ConfirmDeletePatientModalProps
> {
  public render() {
    const { t, isLoading, patientId } = this.props;

    if (isLoading) {
      return (
        <Modal onClickOutside={this.onCancel}>
          <LoadingRenderer />
        </Modal>
      );
    }

    return (
      <Modal onClickOutside={this.onCancel}>
        <ModalHeader>
          <div className="card-header-button" />
          <h1>{t('headings.delete-patient-modal')}</h1>
          <button
            id="close-modal"
            className="card-header-button"
            onClick={this.onCancel}
          >
            {String.fromCharCode(215)}
          </button>
        </ModalHeader>
        <hr />
        <ModalBody>
          <Section>
            <p>{t('delete-patient-confirmation')}</p>
          </Section>
          <ErrorRenderer
            actions={actions}
            fields={['non_field_errors', 'detail']}
            showStatusErrors
          />
        </ModalBody>
        {isLoading && <LoadingRenderer />}
        <ModalFooter className="buttons">
          <Button
            className="pill hollow"
            id="modal-cancel"
            onClick={this.onCancel}
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            className="error pill"
            id="modal-delete"
            onClick={this.onConfirm}
            disabled={!patientId}
          >
            {t('buttons.delete-patient')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  public onCancel = () => {
    this.props.hideModal(ModalType.ConfirmDeletePatient);
  };

  public onConfirm = () => {
    if (this.props.patientId) {
      this.props.deletePatient(this.props.patientId);
    }
  };
}

export { ConfirmDeletePatientModal as TestableConfirmDeletePatientModal };

export const mapStateToProps = ({
  responses,
  sharedModals: modals,
}: StoreState): ConnectedProps => {
  return {
    isLoading: anyPending(responses, actions),
    patientId: modals[ModalType.ConfirmDeletePatient].patientId,
  };
};

export default compose<ConfirmDeletePatientModalProps, {}>(
  connect(mapStateToProps, {
    deletePatient,
    hideModal,
  }),
  withTranslation('patients')
)(ConfirmDeletePatientModal);
