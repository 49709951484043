import { SimpleRecord } from '@dabapps/simple-records';

import { Patient } from '^/patients/patient-types';

export enum ModalType {
  AddSiteToTrial = 'AddSiteToTrial',
  UpdatePatientStatus = 'UpdatePatientStatus',
  SwitchTrial = 'SwitchTrial',
  AddCRAUserToTrialsite = 'AddUserToTrialsite',
  AddSiteUserToTrialsite = 'AddSiteUserToTrialsite',
  DeletePICInformation = 'DeletePICInformation',
  ConfirmDeletePatient = 'ConfirmDeletePatient',
}

export interface ModalsState {
  [ModalType.UpdatePatientStatus]: {
    show: boolean;
    patient?: Patient;
  };
  [ModalType.AddCRAUserToTrialsite]: {
    show: boolean;
    trialsiteId?: string;
    siteName?: string;
  };
  [ModalType.AddSiteUserToTrialsite]: {
    show: boolean;
    trialsiteId?: string;
    siteName?: string;
  };
  [ModalType.DeletePICInformation]: {
    show: boolean;
    picId?: string;
  };
  [ModalType.AddSiteToTrial]: {
    show: boolean;
    trialId?: string;
  };
  [ModalType.SwitchTrial]: {
    show: boolean;
  };
  [ModalType.ConfirmDeletePatient]: {
    show: boolean;
    patientId?: string;
  };
}

export const ModalsStateRecord = SimpleRecord<ModalsState>({
  [ModalType.UpdatePatientStatus]: { show: false },
  [ModalType.AddCRAUserToTrialsite]: { show: false },
  [ModalType.AddSiteUserToTrialsite]: { show: false },
  [ModalType.DeletePICInformation]: { show: false },
  [ModalType.AddSiteToTrial]: { show: false },
  [ModalType.SwitchTrial]: { show: false },
  [ModalType.ConfirmDeletePatient]: { show: false },
});
