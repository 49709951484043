import React from 'react';

import PageContainer from '^/common/components/containers/page-container';
import DownloadCsvBar from '^/common/components/navigation/download-csv-bar';

class DownloadCsvPage extends React.PureComponent {
  public render() {
    return (
      <PageContainer page="home" admin>
        <DownloadCsvBar />
        <img src="/static/dashboard.svg" />
      </PageContainer>
    );
  }
}

export default DownloadCsvPage;
