export default {
  InProgress: [
    'Considering information',
    'On hold',
    'Screening to book',
    'Pre-screening visit',
  ],
  Declined: [
    'Not interested in research',
    'Too many study visits',
    'Doesn’t want to take study drug',
    'Worried about side effects',
    'No reason given',
    'Ineligible',
    'Ineligible - hs-CRP',
    'Ineligible - eGFR',
    'Ineligible - HbA1c',
    'COVID-19',
    'Other',
  ],
};
