import { anyPending } from '@dabapps/redux-requests';
import {
  Section,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { faUserCheck, faUserClock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import MDSpinner from 'react-md-spinner';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { compose } from 'recompose';
import { formValueSelector } from 'redux-form';

import LoadingRenderer from '^/common/components/form/loading-renderer';
import { StoreState } from '^/common/types';
import PatientRow from '^/patients/components/patient-row';
import {
  GET_PATIENTS_BY_TRIALSITE,
  getPatientsByTrialsite,
  UPDATE_PATIENT,
} from '^/patients/patient-actions';
import { Patient, PatientStatus } from '^/patients/patient-types';
import PatientSearchForm from './patient-search-form';

interface OwnProps {
  showStatuses?: ReadonlyArray<PatientStatus>;
  trialsiteId: string;
  requireReviewAfter?: number;
}

interface ConnectedProps {
  isLoading: boolean;
  patientList?: ReadonlyArray<Patient>;
  filter?: string;
  sort?: '' | 'reference' | 'status' | 'modified' | 'source';
  descending?: boolean;
}

interface DispatchProps {
  getPatientsByTrialsite: typeof getPatientsByTrialsite;
}

export type PatientTableProps = OwnProps &
  ConnectedProps &
  DispatchProps &
  WithTranslation;

export class PatientTable extends React.PureComponent<PatientTableProps> {
  public componentDidMount() {
    if (this.props.trialsiteId) {
      this.props.getPatientsByTrialsite(this.props.trialsiteId);
    }
  }

  public render() {
    const {
      t,
      isLoading,
      patientList = [],
      showStatuses = [],
      filter,
      sort = 'created',
      requireReviewAfter,
      descending,
    } = this.props;

    const patients = patientList.filter(this.shouldShowPatient);

    if (patients.length <= 0) {
      return isLoading ? (
        <LoadingRenderer />
      ) : (
        <Section>
          <p>{t('empty-state')}</p>
        </Section>
      );
    }

    const filteredPatients = patients.filter(p => {
      if (!filter) {
        return true;
      }

      const reference = p.legacy_reference || p.display_reference || '';
      return reference.toLowerCase().includes(filter.toLowerCase());
    });

    if (sort && sort === 'reference') {
      filteredPatients.sort((a, b) => {
        const aVal = a.legacy_reference || a.display_reference;
        const bVal = b.legacy_reference || b.display_reference;

        return aVal > bVal ? 1 : aVal < bVal ? -1 : 0;
      });
    } else if (sort) {
      filteredPatients.sort((a, b) =>
        a[sort] > b[sort] ? 1 : a[sort] < b[sort] ? -1 : 0
      );
    }

    if (descending) {
      filteredPatients.reverse();
    }

    const patientsNeedingReview = requireReviewAfter
      ? patients.filter(p =>
          moment(p.modified).isBefore(
            moment().subtract(requireReviewAfter, 'days')
          )
        )
      : [];

    return (
      <>
        <div className="table-search-bar">
          <div className="content">
            {isLoading ? (
              <div className="review-count loading">
                <MDSpinner
                  className="spinner"
                  size={16}
                  singleColor="#00a4e4"
                />
                <span>{t('translation:loading')}</span>
              </div>
            ) : patientsNeedingReview.length ? (
              <div className="review-count reviews-needed">
                <FontAwesomeIcon icon={faUserClock} />{' '}
                {t('patients-to-review', {
                  needReview: patientsNeedingReview.length,
                })}
              </div>
            ) : (
              <div className="review-count">
                <FontAwesomeIcon icon={faUserCheck} />{' '}
                {t('patients-up-to-date')}
              </div>
            )}
          </div>
          <PatientSearchForm
            form={`patientSearchForm${showStatuses.join()}`}
            initialValues={{ sort: '' }}
            sortType={this.getSortType(sort)}
          />
        </div>
        <Table className={classNames({ loading: isLoading })}>
          <TableHead>
            <TableRow>
              <TableHeader>{t('fields.reference')}</TableHeader>
              <TableHeader>{t('fields.status')}</TableHeader>
              <TableHeader>{t('fields.status_detail')}</TableHeader>
              <TableHeader>{t('fields.modified')}</TableHeader>
              <TableHeader>{t('fields.source')}</TableHeader>
              <TableHeader />
            </TableRow>
          </TableHead>
          <TransitionGroup component={TableBody}>
            {filteredPatients.map(patient => (
              <CSSTransition
                key={patient.id}
                classNames="table-row-animation"
                timeout={{ enter: 1000, exit: 200 }}
              >
                <PatientRow
                  patient={patient}
                  highlightAfterDays={requireReviewAfter}
                />
              </CSSTransition>
            ))}
          </TransitionGroup>
        </Table>
      </>
    );
  }

  private shouldShowPatient = (patient: Patient) => {
    const matchesStatus = this.props.showStatuses
      ? this.props.showStatuses.some(status =>
          patient.status.toLowerCase().includes(status.toLowerCase())
        )
      : true;

    const matchesTrialsite = this.props.trialsiteId
      ? patient.trialsite === this.props.trialsiteId
      : true;

    return matchesStatus && matchesTrialsite;
  };

  private getSortType(sort?: string) {
    switch (sort) {
      case 'reference':
      case 'status':
      case 'status_detail':
      case 'source':
        return 'alpha';
      default:
        return undefined;
    }
  }
}

export { PatientTable as TestablePatientTable };

export const mapStateToProps = (
  state: StoreState,
  { showStatuses = [], trialsiteId }: OwnProps
): ConnectedProps => {
  const selector = formValueSelector(`patientSearchForm${showStatuses.join()}`);

  return {
    isLoading: anyPending(state.responses, [
      UPDATE_PATIENT,
      GET_PATIENTS_BY_TRIALSITE,
    ]),
    patientList: state.patientsByTrialsite[trialsiteId],
    filter: selector(state, 'reference'),
    sort: selector(state, 'sort'),
    descending: selector(state, 'descending'),
  };
};

export default compose<PatientTableProps, OwnProps>(
  connect(mapStateToProps, { getPatientsByTrialsite }),
  withTranslation(['patients', 'translation'])
)(PatientTable);
