import React, { FunctionComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Toggle from '^/common/components/toggle';
import { StoreState } from '^/common/types';
import { InjectedWidgetHeaderProps } from '^/reports/components/hoc/create-widget';
import { SiteActivityData } from '^/reports/components/widgets/site-activity/types';
import { toggleFilterSitesActive } from '^/reports/reports-actions';

interface DispatchProps {
  toggleFilterSitesActive: typeof toggleFilterSitesActive;
}

type StateProps = Pick<StoreState, 'filterSitesActive'>;

type InjectedProps = InjectedWidgetHeaderProps<SiteActivityData>;

type Props = StateProps & DispatchProps & InjectedProps & WithTranslation;

const ExtraHeaderContent: FunctionComponent<Props> = props => {
  const { t, data: sites, filterSitesActive } = props;

  if (!sites) {
    return null;
  }

  const activeSites = sites.filter(site => site.is_active);
  const leftContent = `${t('site-activity.active')} (${activeSites.length})`;
  const rightContent = `${t('site-activity.inactive')} (${sites.length -
    activeSites.length})`;

  return (
    <Toggle
      leftActive={filterSitesActive}
      onChange={props.toggleFilterSitesActive}
      leftContent={leftContent}
      rightContent={rightContent}
    />
  );
};

export { ExtraHeaderContent as TestableExtraHeaderContent };

export const mapStateToProps = ({
  filterSitesActive,
}: StoreState): StateProps => ({
  filterSitesActive,
});

export default compose<Props, InjectedProps>(
  connect(mapStateToProps, { toggleFilterSitesActive }),
  withTranslation('reports')
)(ExtraHeaderContent);
