import {
  Section,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { StoreState } from '^/common/types';
import TrialRow from '^/trials/components/trial-row';
import { getAllTrials } from '^/trials/trial-actions';
import { Trial } from '^/trials/trial-types';

interface ConnectedProps {
  isAdmin: boolean;
  trialList: ReadonlyArray<Trial>;
  trialSearchTerm: string | null;
}

interface DispatchProps {
  getAllTrials: typeof getAllTrials;
}

export type Props = ConnectedProps & DispatchProps & WithTranslation;

export class TrialTable extends React.PureComponent<Props> {
  public componentDidMount() {
    this.props.getAllTrials();
  }

  public render() {
    const { t, isAdmin = false, trialList = [], trialSearchTerm } = this.props;

    const filteredTrials = trialList.filter(trial => {
      if (trialSearchTerm) {
        return trial.global_trial_id
          .toUpperCase()
          .includes(trialSearchTerm.toUpperCase());
      } else {
        return true;
      }
    });

    if (filteredTrials.length <= 0) {
      return (
        <Section>
          <p>{t('empty-state')}</p>
        </Section>
      );
    }

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>{t('fields.global_trial_id')}</TableHeader>
            <TableHeader>{t('fields.fpfv_planned')}</TableHeader>
            <TableHeader>{t('fields.lpfv_planned')}</TableHeader>
            <TableHeader>{t('fields.patients_identified')}</TableHeader>
            {isAdmin && <TableHeader />}
          </TableRow>
        </TableHead>
        <TransitionGroup component={TableBody}>
          {filteredTrials.map(trial => (
            <CSSTransition
              key={trial.id}
              classNames="table-row-animation"
              timeout={{ enter: 1000, exit: 200 }}
            >
              <TrialRow trial={trial} allowEdit={isAdmin} />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </Table>
    );
  }
}

export const mapStateToProps = ({
  isAdmin,
  trialList,
  trialSearchTerm,
}: StoreState): ConnectedProps => {
  return {
    isAdmin,
    trialList,
    trialSearchTerm,
  };
};

export default withTranslation('trials')(
  connect(mapStateToProps, { getAllTrials })(TrialTable)
);
