import { Button, FormGroup } from '@dabapps/roe';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import ErrorRenderer from '^/common/components/form/error-renderer';
import RenderField from '^/common/components/form/render-field';
import { RESET_PASSWORD_CONFIRM } from '^/password-reset/password-reset-actions';

const actions = [RESET_PASSWORD_CONFIRM];

export interface FormValues {
  password: string;
  password2: string;
}

export type FormErrors = Partial<FormValues>;

export const validate = (values: Partial<FormValues>): Readonly<FormErrors> => {
  const errors: FormErrors = {};

  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 8) {
    errors.password = 'Password must be at least 8 characters long';
  } else if (
    !values.password.match(/(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]*/g)
  ) {
    errors.password =
      'Password must contain numbers, uppercase and lowercase characters';
  }

  if (!values.password2) {
    errors.password2 = 'Required';
  }

  if (values.password !== values.password2) {
    errors.password2 = 'Passwords must match';
  }

  return errors;
};

interface OwnProps {
  onSubmit: (data: FormValues) => void;
}

type Props = OwnProps &
  InjectedFormProps<FormValues, OwnProps> &
  WithTranslation;

export class PasswordResetFormConfirm extends React.Component<Props> {
  public render() {
    const { t, handleSubmit, submitting, pristine } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <hr />
        <div>
          <FormGroup>
            <Field
              label={t('fields.new_password')}
              placeholder={t('fields.password')}
              name="password"
              type="password"
              component={RenderField}
            />
            <Field
              label={t('fields.password2')}
              placeholder={t('fields.password2')}
              name="password2"
              type="password"
              component={RenderField}
            />
          </FormGroup>
        </div>
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors', 'uid', 'token']}
        />
        <hr />
        <div className="form-buttons">
          <Button
            className="primary pill"
            type="submit"
            disabled={submitting || pristine}
          >
            {t('buttons.change-password')}
          </Button>
        </div>
      </form>
    );
  }
}

export default reduxForm<FormValues, OwnProps>({
  form: 'passwordResetFormConfirm',
  validate,
})(withTranslation('login')(PasswordResetFormConfirm));
