import moment from 'moment';

import { AnyAction, PaginatedList } from '^/common/types';
import { LOGOUT } from '^/login/login-actions';
import { GET_ALL_PATIENTS } from '^/patients/patient-actions';
import { Patient, PatientRecord } from '^/patients/patient-types';
import { GET_ALL_PIC_INFORMATION } from '^/pic-information/pic-information-actions';
import {
  PICInformation,
  PICInformationRecord,
} from '^/pic-information/pic-information-types';
import { GET_ALL_TRIALS } from '^/trials/trial-actions';
import { Trial } from '^/trials/trial-types';
import {
  ADD_PENDING_TRIALSITE_USER,
  GET_ALL_TRIALSITES,
  GET_TRIALSITE_BY_TRIAL,
  GET_TRIALSITE_USERS_BY_TRIALSITE,
  REMOVE_ALL_PENDING_TRIALSITE_USERS,
  REMOVE_PENDING_TRIALSITE_USER,
  SET_SELECTED_TRIALSITE,
  SET_SITE_SEARCH_TERM,
  SET_TRIALSITE_CRA_SEARCH_TERM,
  SET_TRIALSITE_SITE_SEARCH_TERM,
} from '^/trialsites/trialsite-actions';
import {
  PendingTrialsiteUser,
  SelectedTrialsite,
  Trialsite,
  TrialsiteRecord,
  TrialsiteUser,
} from '^/trialsites/trialsite-types';

export function trialsitesList(
  state: ReadonlyArray<Trialsite> = [],
  action: AnyAction
) {
  switch (action.type) {
    case GET_ALL_TRIALS.SUCCESS:
      if (!Array.isArray(action.payload.data)) {
        return state;
      }

      return state.map(trialsite => {
        return {
          ...trialsite,
          trial_detail: action.payload.data.find(
            (trial: Trial) => trial.id === trialsite.trial
          ),
        };
      });
    case GET_ALL_TRIALSITES.SUCCESS:
      if (!Array.isArray(action.payload.data)) {
        return state;
      }

      return action.payload.data.map(TrialsiteRecord);
    case LOGOUT.SUCCESS:
      return [];
    default:
      return state;
  }
}

export function trialsiteUserList(
  state: { [trialsiteId: string]: ReadonlyArray<TrialsiteUser> } = {},
  action: AnyAction
) {
  switch (action.type) {
    case GET_TRIALSITE_USERS_BY_TRIALSITE.SUCCESS:
      return {
        ...state,
        [action.meta.trialsite_id]: action.payload.data,
      };
    case LOGOUT.SUCCESS:
      return {};
    default:
      return state;
  }
}

export function selectedTrialsite(
  state: SelectedTrialsite | null = null,
  action: AnyAction
): SelectedTrialsite | null {
  switch (action.type) {
    case SET_SELECTED_TRIALSITE:
      return { trialsite: action.payload };
    case GET_ALL_PATIENTS.SUCCESS:
      if (!state) {
        return state;
      }

      if (!Array.isArray(action.payload.data)) {
        return state;
      }

      const patientList: ReadonlyArray<Patient> = action.payload.data.map(
        PatientRecord
      );

      const matchingPatients = patientList.filter(
        patient => patient.trialsite === state.trialsite.id
      );

      if (matchingPatients.length <= 0) {
        return { ...state, lastUpdatedPatient: null };
      }

      const lastUpdatedPatient = matchingPatients.reduce((prev, current) =>
        prev.modified > current.modified ? prev : current
      );

      return {
        ...state,
        lastUpdatedPatient: moment(lastUpdatedPatient.modified),
      };
    case GET_ALL_PIC_INFORMATION.SUCCESS:
      if (!state) {
        return state;
      }

      if (!Array.isArray(action.payload.data)) {
        return state;
      }

      const picList: ReadonlyArray<PICInformation> = action.payload.data.map(
        PICInformationRecord
      );

      const matchingPICInformation = picList.filter(
        pic => pic.trialsite === state.trialsite.id
      );

      if (matchingPICInformation.length <= 0) {
        return { ...state, lastUpdatedPICInformation: null };
      }

      const lastUpdatedPICInformation = matchingPICInformation.reduce(
        (prev, current) => (prev.modified > current.modified ? prev : current)
      );

      return {
        ...state,
        lastUpdatedPICInformation: moment(lastUpdatedPICInformation.modified),
      };
    case GET_TRIALSITE_BY_TRIAL.SUCCESS:
    case GET_ALL_TRIALSITES.SUCCESS:
      if (action.payload.data.length <= 0) {
        return null;
      }

      if (state) {
        return state;
      }
      return {
        trialsite: TrialsiteRecord(action.payload.data[0]),
        lastUpdatedPatient: undefined,
      };
    case LOGOUT.SUCCESS:
      return null;
    default:
      return state;
  }
}

export function trialsitesByTrialList(
  state: PaginatedList<Trialsite> = { count: 0, results: [] },
  action: AnyAction
) {
  switch (action.type) {
    case GET_TRIALSITE_BY_TRIAL.SUCCESS:
      if (!Array.isArray(action.payload.data?.results)) {
        return state;
      }
      return action.payload.data;
    case LOGOUT.SUCCESS:
      return [];
    default:
      return state;
  }
}

export function trialsiteSiteSearchTerm(state: string = '', action: AnyAction) {
  switch (action.type) {
    case SET_TRIALSITE_SITE_SEARCH_TERM:
      return action.payload;
    case LOGOUT.SUCCESS:
      return '';
    default:
      return state;
  }
}

export function siteSearchTerm(state: string = '', action: AnyAction) {
  switch (action.type) {
    case SET_SITE_SEARCH_TERM:
      return action.payload;
    case LOGOUT.SUCCESS:
      return '';
    default:
      return state;
  }
}

export function trialsiteCRASearchTerm(state: string = '', action: AnyAction) {
  switch (action.type) {
    case SET_TRIALSITE_CRA_SEARCH_TERM:
      return action.payload;
    case LOGOUT.SUCCESS:
      return '';
    default:
      return state;
  }
}

export function pendingTrialsiteUsers(
  state: ReadonlyArray<PendingTrialsiteUser> = [],
  action: AnyAction
) {
  switch (action.type) {
    case ADD_PENDING_TRIALSITE_USER:
      return [...state, action.payload];
    case REMOVE_PENDING_TRIALSITE_USER:
      return state.filter(pendingTrialsiteUser => {
        return !(
          pendingTrialsiteUser.userId === action.payload.userId &&
          pendingTrialsiteUser.trialsiteId === action.payload.trialsiteId
        );
      });
    case REMOVE_ALL_PENDING_TRIALSITE_USERS:
      return [];
    case LOGOUT.SUCCESS:
      return [];
    default:
      return state;
  }
}
