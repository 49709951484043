import { faCalendarAlt, faChartBar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';

import Toggle from '^/common/components/toggle';
import { StoreState } from '^/common/types';
import { InjectedWidgetHeaderProps } from '^/reports/components/hoc/create-widget';
import { toggleScreeningsBookedViewMode } from '^/reports/reports-actions';
import { ScreeningsBookedData } from './index';

interface DispatchProps {
  toggleScreeningsBookedViewMode: typeof toggleScreeningsBookedViewMode;
}

type StateProps = Pick<StoreState, 'screeningsBookedViewGraph'>;

type InjectedProps = InjectedWidgetHeaderProps<ScreeningsBookedData>;

type Props = StateProps & DispatchProps & InjectedProps;

const ExtraHeaderContent: FunctionComponent<Props> = props => {
  const { screeningsBookedViewGraph } = props;

  const leftContent = <FontAwesomeIcon icon={faCalendarAlt} className="icon" />;
  const rightContent = <FontAwesomeIcon icon={faChartBar} className="icon" />;

  return (
    <Toggle
      leftActive={!screeningsBookedViewGraph}
      onChange={props.toggleScreeningsBookedViewMode}
      leftContent={leftContent}
      rightContent={rightContent}
    />
  );
};

export { ExtraHeaderContent };

export const mapStateToProps = ({
  screeningsBookedViewGraph,
}: StoreState): StateProps => ({
  screeningsBookedViewGraph,
});

export default connect(mapStateToProps, { toggleScreeningsBookedViewMode })(
  ExtraHeaderContent
);
