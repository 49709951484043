import { Button, InputGroup } from '@dabapps/roe';
import classNames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';

interface Props {
  leftContent: ReactNode;
  rightContent: ReactNode;
  leftActive: boolean;
  onChange: () => void;
}

const Toggle: FunctionComponent<Props> = ({
  onChange,
  leftActive,
  leftContent,
  rightContent,
}) => (
  <InputGroup onClick={onChange} className="toggle">
    <Button className={classNames('small secondary', { hollow: !leftActive })}>
      {leftContent}
    </Button>
    <Button className={classNames('small secondary', { hollow: leftActive })}>
      {rightContent}
    </Button>
  </InputGroup>
);

export default Toggle;
