import { SimpleRecord } from '@dabapps/simple-records';

import { UserRoles } from '^/roles/role-types';

export type User = Readonly<{
  id: string;
  full_name: string;
  email: string;
  sso_id: string;
  impact_id: string;
  active: boolean;
  roles?: UserRoles;
}>;

export const UserRecord = SimpleRecord<User>({
  id: '',
  full_name: '',
  email: '',
  sso_id: '',
  impact_id: '',
  active: false,
});
