import { LOCATION_CHANGE } from 'connected-react-router';
import { AnyAction } from 'redux';

import { TOGGLE_VIDEO_EXPANDED } from '^/common/components/containers/footer/training-page/training-actions';
import { LOGOUT } from '^/login/login-actions';

export function videoExpanded(
  state: ReadonlyArray<boolean> = [false, false, false, false],
  action: AnyAction
): ReadonlyArray<boolean> {
  switch (action.type) {
    case TOGGLE_VIDEO_EXPANDED:
      return state.map((expanded, index) => {
        if (index === action.payload.videoNumber) {
          return action.payload.expanded !== undefined
            ? action.payload.expanded
            : !expanded;
        }
        return expanded;
      });

    case LOCATION_CHANGE:
      return [false, false, false, false];
    case LOGOUT.SUCCESS:
      return [false, false, false, false];
    default:
      return state;
  }
}
