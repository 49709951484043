import { faSearch, faSortAlt } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import RenderDropdown from '^/common/components/form/render-dropdown';
import RenderField from '^/common/components/form/render-field';
import RenderSortDirection from '^/common/components/form/render-sort-direction';

interface SearchFields {
  reference: string;
  sort: string;
}

interface OwnProps {
  sortType?: 'numeric' | 'alpha';
}

export type PatientSearchFormProps = OwnProps &
  InjectedFormProps<SearchFields> &
  WithTranslation;

const PatientSearchForm: React.FC<PatientSearchFormProps> = ({
  t,
  handleSubmit,
  sortType,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        placeholder={t('search.search-by')}
        name="reference"
        component={RenderField}
        type="text"
        icon={faSearch}
        fieldSize="sm"
        clearable
      />
      <Field
        name="sort"
        component={RenderDropdown}
        placeholder={t('search.sort-by')}
        options={[
          { label: t('fields.created'), value: 'created' },
          { label: t('fields.reference'), value: 'reference' },
          { label: t('fields.status'), value: 'status' },
          {
            label: t('fields.status_detail'),
            value: 'status_detail',
          },
          { label: t('fields.modified'), value: 'modified' },
          { label: t('fields.source'), value: 'source' },
        ]}
        icon={faSortAlt}
        size="sm"
      />
      <Field
        name="descending"
        placeholder={t('search.sort-by-order')}
        component={RenderSortDirection}
        sortType={sortType}
      />
    </Form>
  );
};

export { PatientSearchForm as TestablePatientSearchForm };

export default reduxForm<SearchFields, OwnProps>({
  form: 'patientSearchForm',
})(withTranslation('patients')(PatientSearchForm));
