import { LOCATION_CHANGE } from 'connected-react-router';
import { AnyAction } from 'redux';

import { LOGOUT } from '^/login/login-actions';
import {
  CREATE_PIC_INFORMATION,
  GET_ALL_PIC_INFORMATION,
  GET_PIC_INFORMATION_BY_TRIALSITE,
  TOGGLE_ADD_PIC_INFORMATION,
} from '^/pic-information/pic-information-actions';
import {
  PICInformation,
  PICInformationRecord,
} from '^/pic-information/pic-information-types';

export function addPICInformationExpanded(
  state: boolean = false,
  action: AnyAction
): boolean {
  switch (action.type) {
    case TOGGLE_ADD_PIC_INFORMATION:
      if (typeof action.payload === 'boolean') {
        return action.payload;
      }

      return !state;
    case CREATE_PIC_INFORMATION.SUCCESS:
    case LOCATION_CHANGE:
      return false;
    case LOGOUT.SUCCESS:
      return false;
    default:
      return state;
  }
}

export function displayedPICInformation(
  state: ReadonlyArray<PICInformation> = [],
  action: AnyAction
): ReadonlyArray<PICInformation> {
  switch (action.type) {
    case GET_PIC_INFORMATION_BY_TRIALSITE.SUCCESS:
      return action.payload.data.map(PICInformationRecord);
    default:
      return state;
  }
}

export function allPICInformation(
  state: ReadonlyArray<PICInformation> = [],
  action: AnyAction
): ReadonlyArray<PICInformation> {
  switch (action.type) {
    case GET_ALL_PIC_INFORMATION.SUCCESS:
      return action.payload.data.map(PICInformationRecord);
    default:
      return state;
  }
}
