import { makeAsyncActionSet } from '@dabapps/redux-requests';
import { faBan } from '@fortawesome/pro-light-svg-icons';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import React from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import {
  createWidget,
  InjectedWidgetProps,
} from '^/reports/components/hoc/create-widget';

export interface ReportDeclinedWidgetData {
  total: number;
  status: Array<{
    status: string;
    count: number;
  }>;
}

type Props = InjectedWidgetProps<ReportDeclinedWidgetData> & WithTranslation;

export const ReportDeclinedWidget = (props: Props) => {
  const { t } = props;

  if (!props.data.status || !props.data.status.length) {
    return <p>{t('empty-state')}</p>;
  }

  const sum = props.data.status.reduce(
    (memo, status) => memo + status.count,
    0
  );

  const barDatumLabel = (data: BarDatum) =>
    `${data.value} (${Math.floor((parseFloat(`${data.value}`) / sum) * 100)}%)`;

  const translateDataPoint = (x: { status: string; count: number }) => ({
    ...x,
    status: t(`patients:details.${x.status || 'unknown'}`),
  });

  return (
    <div className="declined-reports">
      <ResponsiveBar
        data={props.data.status.map(translateDataPoint)}
        keys={['count']}
        label={barDatumLabel}
        indexBy="status"
        margin={{ top: 40, right: 40, bottom: 60, left: 200 }}
        padding={0.3}
        layout="horizontal"
        colors={['#00a4e4']}
        enableGridX
        enableGridY={false}
        labelTextColor={'#FFF'}
        labelSkipWidth={12}
        isInteractive={false}
        labelSkipHeight={12}
      />
    </div>
  );
};

const DECLINED_REPORTS_DATA_SET = makeAsyncActionSet('DECLINED_REPORTS');

export default compose<Props, {}>(
  createWidget<ReportDeclinedWidgetData>({
    icon: faBan,
    title: <Trans i18nKey="reports:declined.heading">Declined</Trans>,
    url: '/api/reports/declined-patients/',
    actionSet: DECLINED_REPORTS_DATA_SET,
    halfWidth: true,
  }),
  withTranslation(['reports', 'patients'])
)(ReportDeclinedWidget);
