import { RecordWithConstructor } from '@dabapps/simple-records';
import moment from 'moment';

export type PICInformationInput = Readonly<{
  id?: string;
  trialsite: string;
  name: string;
  date: string | Date | moment.Moment | null;
  patients_identified: number;
  patients_approached: number;
  modified: string;
}>;

export type PICInformation = Readonly<{
  id?: string;
  trialsite: string;
  name: string;
  date: moment.Moment | null;
  patients_identified: number;
  patients_approached: number;
  modified: string;
}>;

export const PICInformationDefaults = {
  trialsite: '',
  name: '',
  date: null,
  patients_identified: 0,
  patients_approached: 0,
  id: '',
  modified: '',
};

export const PICInformationRecord = RecordWithConstructor<
  PICInformationInput,
  PICInformation
>(PICInformationDefaults, input => {
  return {
    ...input,
    date: input.date ? moment(input.date) : null,
  };
});

export const PICInformationRecordForApi = RecordWithConstructor<
  PICInformation,
  PICInformationInput
>(PICInformationDefaults, input => {
  return {
    ...input,
    date: input.date ? input.date.format('YYYY-MM-DD') : null,
  };
});
