import { anyPending } from '@dabapps/redux-requests';
import { Section } from '@dabapps/roe';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { SubmissionError } from 'redux-form';

import DownloadCard from '^/common/components/containers/button-card';
import CollapsableCard from '^/common/components/containers/collapsable-card';
import PageContainer from '^/common/components/containers/page-container';
import ErrorRenderer from '^/common/components/form/error-renderer';
import LoadingRenderer from '^/common/components/form/loading-renderer';
import { StoreState } from '^/common/types';
import PatientFormCreate from '^/patients/components/patient-form-create';
import PatientTable from '^/patients/components/patient-table';
import {
  createPatient,
  GET_ALL_PATIENTS,
  toggleAddPatient,
  togglePatientsDeclined,
  togglePatientsPossibleRecruits,
  togglePatientsScreeningComplete,
} from '^/patients/patient-actions';
import {
  Patient,
  PatientRecord,
  PatientStatus,
  reminderInterval,
} from '^/patients/patient-types';
import { SelectedTrialsite } from '^/trialsites/trialsite-types';
import { User } from '^/users/user-types';

const actions = [GET_ALL_PATIENTS];

interface ConnectedProps {
  addPatientExpanded: boolean;
  patientsPossibleRecruitsExpanded: boolean;
  patientsScreeningsCompleteExpanded: boolean;
  patientsDeclinedExpanded: boolean;
  isLoading: boolean;
  loggedInUser: User;
  selectedTrialsite: SelectedTrialsite | null;
}

interface DispatchProps {
  createPatient: typeof createPatient;
  toggleAddPatient: typeof toggleAddPatient;
  togglePatientsPossibleRecruits: typeof togglePatientsPossibleRecruits;
  togglePatientsScreeningComplete: typeof togglePatientsScreeningComplete;
  togglePatientsDeclined: typeof togglePatientsDeclined;
}

export type Props = ConnectedProps & DispatchProps & WithTranslation;

export class PatientPageList extends React.PureComponent<Props> {
  public render() {
    const {
      t,
      loggedInUser,
      addPatientExpanded,
      patientsPossibleRecruitsExpanded,
      patientsScreeningsCompleteExpanded,
      patientsDeclinedExpanded,
    } = this.props;

    const selectedTrialsite = this.props.selectedTrialsite
      ? this.props.selectedTrialsite.trialsite
      : null;

    return (
      <PageContainer>
        <CollapsableCard
          header={t('cards.add-new-patient')}
          icon="add"
          expanded={addPatientExpanded}
          onClick={this.props.toggleAddPatient}
        >
          {selectedTrialsite && loggedInUser && loggedInUser.id.length > 0 ? (
            <PatientFormCreate
              isLoading={this.props.isLoading}
              onSubmit={this.onCreatePatientSubmit}
            />
          ) : (
            <Section>
              <p>{t('errors:forbidden')}</p>
            </Section>
          )}
        </CollapsableCard>
        {selectedTrialsite && selectedTrialsite.id && (
          <>
            <CollapsableCard
              style="card-recruits"
              header={t('cards.possible-recruits')}
              icon="dropdown"
              expanded={patientsPossibleRecruitsExpanded}
              onClick={this.props.togglePatientsPossibleRecruits}
            >
              <PatientTable
                showStatuses={[
                  PatientStatus.New,
                  PatientStatus.InProgress,
                  PatientStatus.ScreeningBooked,
                ]}
                requireReviewAfter={reminderInterval}
                trialsiteId={selectedTrialsite.id}
              />
              <LoadingRenderer actions={actions} />
              <ErrorRenderer
                actions={actions}
                fields={['non_field_errors']}
                showStatusErrors
              />
            </CollapsableCard>
            <CollapsableCard
              style="card-screenings"
              header={t('cards.screenings-completed')}
              icon="dropdown"
              expanded={patientsScreeningsCompleteExpanded}
              onClick={this.props.togglePatientsScreeningComplete}
            >
              <PatientTable
                showStatuses={[PatientStatus.ScreeningComplete]}
                trialsiteId={selectedTrialsite.id}
              />
              <LoadingRenderer actions={actions} />
              <ErrorRenderer
                actions={actions}
                fields={['non_field_errors']}
                showStatusErrors
              />
            </CollapsableCard>
            <CollapsableCard
              style="card-declined"
              header={t('cards.declined')}
              icon="dropdown"
              expanded={patientsDeclinedExpanded}
              onClick={this.props.togglePatientsDeclined}
            >
              <PatientTable
                showStatuses={[PatientStatus.Declined]}
                trialsiteId={selectedTrialsite.id}
              />
              <LoadingRenderer actions={actions} />
              <ErrorRenderer
                actions={actions}
                fields={['non_field_errors']}
                showStatusErrors
              />
            </CollapsableCard>
          </>
        )}
        <DownloadCard
          style="card-csv-download"
          header={t('cards.download-all-patients')}
          link="/api/patients/csv/"
        />
      </PageContainer>
    );
  }

  public onCreatePatientSubmit = async (values: Partial<Patient>) => {
    const selectedTrialsite = this.props.selectedTrialsite
      ? this.props.selectedTrialsite.trialsite
      : null;

    if (!values.status || !values.status_detail || !values.source) {
      throw new SubmissionError({
        status: !values.status ? 'Required' : undefined,
        status_detail: !values.status_detail ? 'Required' : undefined,
        source: !values.source ? 'Required' : undefined,
      });
    }

    return this.props.createPatient(
      PatientRecord({
        ...values,
        trialsite: selectedTrialsite ? selectedTrialsite.id : '',
        referring_user: this.props.loggedInUser.id,
      })
    );
  };
}

export const mapStateToProps = ({
  addPatientExpanded,
  patientsPossibleRecruitsExpanded,
  patientsScreeningsCompleteExpanded,
  patientsDeclinedExpanded,
  loggedInUser,
  responses,
  selectedTrialsite,
}: StoreState): ConnectedProps => {
  return {
    addPatientExpanded,
    patientsPossibleRecruitsExpanded,
    patientsScreeningsCompleteExpanded,
    patientsDeclinedExpanded,
    isLoading: anyPending(responses, actions),
    loggedInUser,
    selectedTrialsite,
  };
};

export default compose<Props, {}>(
  connect(mapStateToProps, {
    createPatient,
    toggleAddPatient,
    togglePatientsPossibleRecruits,
    togglePatientsScreeningComplete,
    togglePatientsDeclined,
  }),
  withTranslation(['patients', 'errors'])
)(PatientPageList);
