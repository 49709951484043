import { anyPending } from '@dabapps/redux-requests';
import { Section } from '@dabapps/roe';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import Card from '^/common/components/containers/card';
import CollapsableCard from '^/common/components/containers/collapsable-card';
import PageContainer from '^/common/components/containers/page-container';
import DropdownFilter from '^/common/components/form/dropdown-filter';
import ErrorRenderer from '^/common/components/form/error-renderer';
import InputFilter from '^/common/components/form/input-filter';
import LoadingRenderer from '^/common/components/form/loading-renderer';
import { StoreState } from '^/common/types';
import { UserRoles } from '^/roles/role-types';
import CreateUserForm from '^/users/components/user-form-create';
import UserTable from '^/users/components/user-table';
import { createUser, GET_ALL_USERS, toggleAddUser } from '^/users/user-actions';
import { User } from '^/users/user-types';

const actions = [GET_ALL_USERS];

interface ConnectedProps {
  addUserExpanded: boolean;
  isAdmin: boolean;
  isLoading: boolean;
}

interface DispatchProps {
  createUser: typeof createUser;
  toggleAddUser: typeof toggleAddUser;
}

type Props = ConnectedProps & DispatchProps & WithTranslation;

const SEARCH_TERM = 'User';
const headerComponents = [
  <InputFilter type={SEARCH_TERM} key={`${SEARCH_TERM}InputFilter`} />,
  <DropdownFilter key={`${SEARCH_TERM}DropdownFilter`} />,
];

export class UsersPage extends React.PureComponent<Props> {
  public render() {
    const { t, isAdmin, isLoading, addUserExpanded = false } = this.props;

    if (!isAdmin) {
      return (
        <PageContainer admin>
          <Section>
            <p>{t('errors:forbidden')}</p>
          </Section>
        </PageContainer>
      );
    }

    return (
      <PageContainer admin>
        <CollapsableCard
          header={t('add-new-user')}
          icon="add"
          expanded={addUserExpanded}
          onClick={this.toggleAddUser}
        >
          <CreateUserForm
            onSubmit={this.onCreateUserSubmit}
            initialValues={{ roles: UserRoles.SITE, active: true }}
          />
        </CollapsableCard>

        <Card
          type="User"
          header={t('users-heading')}
          headerComponents={headerComponents}
        >
          <UserTable />
          {isLoading && <LoadingRenderer />}
          <ErrorRenderer
            actions={actions}
            fields={['non_field_errors']}
            showStatusErrors
          />
        </Card>
      </PageContainer>
    );
  }

  private onCreateUserSubmit = (values: User) => {
    this.props.createUser(values);
  };

  private toggleAddUser = () => {
    this.props.toggleAddUser();
  };
}

export const mapStateToProps = ({
  addUserExpanded,
  isAdmin,
  responses,
}: StoreState): ConnectedProps => {
  return {
    addUserExpanded,
    isAdmin,
    isLoading: anyPending(responses, actions),
  };
};

export default compose<Props, {}>(
  connect(mapStateToProps, {
    createUser,
    toggleAddUser,
  }),
  withTranslation(['users', 'errors'])
)(UsersPage);
