import { FormGroup } from '@dabapps/roe';
import { push } from 'connected-react-router';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { change, Field, formValueSelector } from 'redux-form';

import RenderDropdown from '^/common/components/form/render-dropdown';
import { StoreState } from '^/common/types';
import { getCountries } from '^/countries/country-actions';
import ReportDatePicker from '^/reports/components/form/report-date-picker';
import {
  getAllComparisonSiteOptions,
  getAllSiteOptions,
  getAllTrials,
} from '^/reports/reports-actions';
import { FieldNames, TrialDropdownValue } from '^/reports/reports-types';
import {
  formatSiteFieldValues,
  formatTrialFieldValues,
} from '^/reports/reports-utils';
import { Trial } from '^/trials/trial-types';
import { REPORT_FORM_NAME } from '../report-constants';

interface ConnectedProps {
  countries: StoreState['countries'];
  trialList: ReadonlyArray<TrialDropdownValue>;
  siteOptionsList: StoreState['siteOptionsList'];
  comparisonSiteOptionsList: StoreState['comparisonSiteOptionsList'];
  selectedTrial?: Trial;
}

interface DispatchProps {
  getCountries: typeof getCountries;
  getAllTrials: typeof getAllTrials;
  getAllSiteOptions: typeof getAllSiteOptions;
  getAllComparisonSiteOptions: typeof getAllComparisonSiteOptions;
  push: typeof push;
  change: typeof change;
}

interface OwnProps {
  fieldNames: FieldNames;
  isComparison?: boolean;
}

export type Props = ConnectedProps & DispatchProps & OwnProps & WithTranslation;

export class ReportFormFields extends React.PureComponent<Props> {
  public componentDidMount() {
    this.props.getCountries();
    this.props.getAllTrials();
    this.props.isComparison
      ? this.props.getAllComparisonSiteOptions()
      : this.props.getAllSiteOptions();
  }

  public render() {
    const {
      t,
      countries,
      trialList,
      siteOptionsList,
      comparisonSiteOptionsList,
      fieldNames,
      isComparison,
      selectedTrial,
    } = this.props;

    return (
      <>
        <FormGroup>
          <FormGroup>
            <Field
              label={t('fields.filter-by-trial')}
              name={fieldNames.TRIAL}
              component={RenderDropdown}
              options={formatTrialFieldValues(trialList, t('all-trials'))}
              onChange={this.handleChangeTrial}
            />
            <Field
              label={t('fields.filter-by-country')}
              name={fieldNames.COUNTRY}
              component={RenderDropdown}
              options={[
                { label: t('all-countries'), value: '' },
                ...countries.map(c => ({
                  label: c.name,
                  value: c.code,
                })),
              ]}
              onChange={this.handleChangeCountry}
            />
            <Field
              label={t('fields.filter-by-site')}
              name={fieldNames.SITE}
              component={RenderDropdown}
              options={formatSiteFieldValues(
                isComparison ? comparisonSiteOptionsList : siteOptionsList,
                t('all-sites')
              )}
            />
          </FormGroup>
        </FormGroup>
        <FormGroup>
          <ReportDatePicker
            startFieldName={fieldNames.START}
            endFieldName={fieldNames.END}
            filterDateName={fieldNames.DATE}
            selectedTrial={selectedTrial}
          />
        </FormGroup>
      </>
    );
  }

  public clearField = (fieldName: string) => {
    this.props.change(REPORT_FORM_NAME, fieldName, null);
  };

  public handleChangeTrial = (
    _: React.ChangeEvent<HTMLInputElement> | undefined,
    value?: string
  ) => {
    this.props.isComparison
      ? this.props.getAllComparisonSiteOptions(value)
      : this.props.getAllSiteOptions(value);

    this.clearField(this.props.fieldNames.START);
    this.clearField(this.props.fieldNames.COUNTRY);
    this.clearField(this.props.fieldNames.END);
    this.clearField(this.props.fieldNames.DATE);
  };

  public handleChangeCountry = () => {
    this.clearField(this.props.fieldNames.SITE);
  };
}

const formSelector = formValueSelector(REPORT_FORM_NAME);

export const mapStateToProps = (
  state: StoreState,
  ownProps: OwnProps
): ConnectedProps => {
  const selectedTrialId = formSelector(state, ownProps.fieldNames.TRIAL);
  const selectedCountryId = formSelector(state, ownProps.fieldNames.COUNTRY);
  const applicableCountries = ownProps.isComparison
    ? state.comparisonSiteOptionsCountries
    : state.siteOptionsCountries;

  return {
    countries: state.countries.filter(c =>
      applicableCountries.includes(c.code)
    ),
    trialList: state.trialList,
    siteOptionsList: selectedCountryId
      ? state.siteOptionsList.filter(s => s.country === selectedCountryId)
      : state.siteOptionsList,
    comparisonSiteOptionsList: selectedCountryId
      ? state.comparisonSiteOptionsList.filter(
          s => s.country === selectedCountryId
        )
      : state.comparisonSiteOptionsList,
    selectedTrial: state.trialList.find(trial => trial.id === selectedTrialId),
  };
};

export default compose<Props, OwnProps>(
  connect(mapStateToProps, {
    getCountries,
    getAllTrials,
    getAllSiteOptions,
    getAllComparisonSiteOptions,
    push,
    change,
  }),
  withTranslation('reports')
)(ReportFormFields);
