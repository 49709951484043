import { anyPending } from '@dabapps/redux-requests';
import { Button, Modal, ModalBody, ModalHeader, Section } from '@dabapps/roe';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ErrorRenderer from '^/common/components/form/error-renderer';
import InputFilter from '^/common/components/form/input-filter';
import LoadingRenderer from '^/common/components/form/loading-renderer';
import { StoreState } from '^/common/types';
import { hideModal } from '^/modals/modal-actions';
import { ModalType } from '^/modals/modal-types';
import { UserRoles } from '^/roles/role-types';
import AddUserCard from '^/trialsites/components/trialsite-add-user-card';
import {
  createTrialsiteUsers,
  GET_TRIALSITE_BY_TRIAL,
  removeAllPendingTrialsiteUsers,
} from '^/trialsites/trialsite-actions';
import {
  PendingTrialsiteUser,
  TrialsiteUser,
} from '^/trialsites/trialsite-types';
import { getAllUsers } from '^/users/user-actions';
import { User } from '^/users/user-types';

const actions = [GET_TRIALSITE_BY_TRIAL];

interface ConnectedProps {
  isLoading: boolean;
  trialsiteId: string | undefined;
  siteName: string | undefined;
  userList: ReadonlyArray<User>;
  pendingTrialsiteUsers: ReadonlyArray<PendingTrialsiteUser>;
  trialsiteUserList: { [trialsiteId: string]: ReadonlyArray<TrialsiteUser> };
  trialsiteCRASearchTerm: string;
}

interface DispatchProps {
  createTrialsiteUsers: typeof createTrialsiteUsers;
  getAllUsers: typeof getAllUsers;
  hideModal: typeof hideModal;
  removeAllPendingTrialsiteUsers: typeof removeAllPendingTrialsiteUsers;
}

export type Props = ConnectedProps & DispatchProps & WithTranslation;

export class TrialsiteAddCRAModal extends React.PureComponent<Props> {
  public render() {
    const { t, isLoading, trialsiteId, siteName, userList } = this.props;

    if (!userList || userList.length <= 0) {
      this.props.getAllUsers();
    }

    if (!(trialsiteId && siteName)) {
      return (
        <Modal onClickOutside={this.onCancel}>
          <LoadingRenderer />
        </Modal>
      );
    }

    const filteredUserList = userList.filter(user =>
      this.shouldShowUser(user, trialsiteId)
    );

    return (
      <Modal onClickOutside={this.onCancel} className="add-cra-trialsite-modal">
        <ModalHeader>
          <div className="header-info">
            <h1>{t('add-cra-users-to-trial-site-heading')}</h1>
            <p>{siteName}</p>
          </div>
          <InputFilter type="Trialsite-CRA" />
          <button
            className="card-header-button"
            id="close-modal-cra"
            onClick={this.onCancel}
          >
            <FontAwesomeIcon
              className="add-cra-trialsite-close-button"
              size="1x"
              icon={faTimes}
            />
          </button>
        </ModalHeader>
        <hr />
        <ModalBody className="buttons">
          {filteredUserList.length > 0 ? (
            <Button
              className="primary pill"
              id="modal-done-cra"
              onClick={this.onSaveUsers.bind(this, trialsiteId)}
              disabled={this.props.pendingTrialsiteUsers.length <= 0}
            >
              {t('buttons.add-cra-users-to-trial-site')}
            </Button>
          ) : (
            <Section>
              <p>{t('users:empty-state')}</p>
            </Section>
          )}
        </ModalBody>
        <ModalBody>
          {filteredUserList.map(user => (
            <AddUserCard key={user.id} user={user} trialsiteId={trialsiteId} />
          ))}
        </ModalBody>
        {isLoading && <LoadingRenderer />}
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors', 'detail']}
          showStatusErrors
        />
        <hr />
      </Modal>
    );
  }

  private onCancel = () => {
    this.props.hideModal(ModalType.AddCRAUserToTrialsite);
    this.props.removeAllPendingTrialsiteUsers();
  };

  private onSaveUsers(trialsiteId: string) {
    this.props.createTrialsiteUsers(
      this.props.pendingTrialsiteUsers,
      trialsiteId,
      ModalType.AddCRAUserToTrialsite
    );
  }

  private shouldShowUser = (user: User, trialsiteId: string) => {
    if (user.roles !== UserRoles.CRA) {
      return false;
    }

    if (
      this.props.trialsiteUserList[trialsiteId].some(x => x.user === user.id)
    ) {
      return false;
    }

    if (this.props.pendingTrialsiteUsers.some(x => x.userId === user.id)) {
      return true;
    }

    return (
      user.full_name
        .toLowerCase()
        .includes(this.props.trialsiteCRASearchTerm.toLowerCase()) ||
      user.email
        .toLowerCase()
        .includes(this.props.trialsiteCRASearchTerm.toLowerCase())
    );
  };
}

export const mapStateToProps = ({
  responses,
  sharedModals: modals,
  userList,
  pendingTrialsiteUsers,
  trialsiteUserList,
  trialsiteCRASearchTerm,
}: StoreState): ConnectedProps => {
  const trialsiteId = modals[ModalType.AddCRAUserToTrialsite]
    ? modals[ModalType.AddCRAUserToTrialsite].trialsiteId
    : undefined;
  const siteName = modals[ModalType.AddCRAUserToTrialsite]
    ? modals[ModalType.AddCRAUserToTrialsite].siteName
    : undefined;

  return {
    isLoading: anyPending(responses, actions),
    trialsiteId,
    siteName,
    userList,
    pendingTrialsiteUsers,
    trialsiteUserList,
    trialsiteCRASearchTerm,
  };
};

export default withTranslation('trials')(
  connect(mapStateToProps, {
    hideModal,
    getAllUsers,
    createTrialsiteUsers,
    removeAllPendingTrialsiteUsers,
  })(TrialsiteAddCRAModal)
);
