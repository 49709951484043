import React from 'react';
import { Trans } from 'react-i18next';

export const OPENING_PARAGRAPH: React.ReactNode = (
  <p>
    <Trans i18nKey="terms:opening-paragraph">
      <p>Notice of Personal Data Processing – novoRecruit</p>
      Novo Nordisk Limited is required by law to protect your personal data.
      This Notice explains how we process (e.g. collect, use, store, and share)
      your personal data. We will process any personal data about you in
      accordance with this Notice and with applicable law.
    </Trans>
  </p>
);

export const LIST_ITEM_1: React.ReactNode = (
  <div>
    <Trans i18nKey="terms:WHO ARE WE?">
      <strong>
        <li>WHO ARE WE?</li>
      </strong>
      <p>
        <p>The company responsible for processing your personal data is:</p>
        <p>
          Novo Nordisk Limited
          <br />3 City Place
          <br />
          Beehive Ring Road
          <br />
          Gatwick
          <br />
          West Sussex
          <br />
          RH6 0PA
        </p>
        <p>
          You can always contact Novo Nordisk Limited or the Novo Nordisk Data
          Privacy Officer at{' '}
          <a href="mailto: privacyuk@novnonordisk.com">
            privacyuk@novonordisk.com
          </a>{' '}
          with questions or concerns about how we process your personal data.
        </p>
      </p>
    </Trans>
  </div>
);

export const LIST_ITEM_2: React.ReactNode = (
  <div>
    <Trans i18nKey="terms:HOW DO WE COLLECT PERSONAL DATA ABOUT YOU?">
      <strong>
        <li>HOW DO WE COLLECT PERSONAL DATA ABOUT YOU?</li>
      </strong>
      <p>
        We get your personal data from the following sources:
        <ul>
          <li>From you directly.</li>
        </ul>
      </p>
    </Trans>
  </div>
);

export const LIST_ITEM_3: React.ReactNode = (
  <div>
    <Trans i18nKey="terms:WHY DO WE PROCESS YOUR PERSONAL DATA?">
      <strong>
        <li>WHY DO WE PROCESS YOUR PERSONAL DATA?</li>
      </strong>
      <p>
        We process personal data about you for the following purposes:
        <ul>
          <li>To provide access to the novoRecruit web application. </li>
        </ul>
        You are not required to provide us with your personal data. If you do
        not want Novo Nordisk to use your personal data, you will not be able to
        access the novoRecruit application.
      </p>
    </Trans>
  </div>
);

export const LIST_ITEM_4: React.ReactNode = (
  <div>
    <Trans i18nKey="terms:WHAT PERSONAL DATA DO WE PROCESS ABOUT YOU?">
      <li>
        <strong>WHAT PERSONAL DATA DO WE PROCESS ABOUT YOU?</strong>
      </li>
      <p>
        For the purposes described above in Section 2, we may process the
        following types of personal data:
        <ul>
          <li>Contact information (name, workplace address, email address)</li>
        </ul>
      </p>
    </Trans>{' '}
  </div>
);

export const LIST_ITEM_5: React.ReactNode = (
  <div>
    <Trans i18nKey="terms:WHY ARE WE ALLOWED BY LAW TO PROCESS YOUR PERSONAL DATA?">
      {' '}
      <strong>
        <li>WHY ARE WE ALLOWED BY LAW TO PROCESS YOUR PERSONAL DATA?</li>
      </strong>
      <p>
        Our processing of your personal data requires a legal basis. By law, we
        are allowed to process your personal data described above based on the
        following legal bases:
        <ul>
          <li>You gave consent for us to process your personal data</li>
        </ul>
      </p>
    </Trans>
  </div>
);

export const LIST_ITEM_6: React.ReactNode = (
  <div>
    <Trans i18nKey="terms:HOW DO WE SHARE YOUR PERSONAL DATA?">
      <strong>
        <li>HOW DO WE SHARE YOUR PERSONAL DATA?</li>
      </strong>
      <p>
        We may share your personal data with:
        <ul>
          <li>
            Suppliers or vendors that assist our company (e.g. IT service
            providers)
          </li>
          <li>
            Other Novo Nordisk entities (e.g., Novo Nordisk affiliates in other
            countries)
          </li>
        </ul>
      </p>
    </Trans>
  </div>
);

export const LIST_ITEM_7: React.ReactNode = (
  <div>
    <Trans i18nKey="terms:HOW LONG WILL WE KEEP YOUR PERSONAL DATA?">
      <strong>
        <li>HOW LONG WILL WE KEEP YOUR PERSONAL DATA?</li>
      </strong>
      <p>
        We will keep your personal data for the following period of time:
        <ul>
          <li>
            For as long as needed to provide you with the services requested by
            you
          </li>
        </ul>
      </p>
    </Trans>
  </div>
);

export const LIST_ITEM_8: React.ReactNode = (
  <div>
    <Trans i18nKey="terms:WHAT ARE YOUR RIGHTS?">
      <strong>
        <li>WHAT ARE YOUR RIGHTS?</li>
      </strong>
      <p>
        In general, you have the following rights:
        <ul>
          <li>
            You can get an overview of what personal data we have about you
          </li>
          <li>
            You can get a copy of your personal data in a structured, commonly
            used and machine-readable format
          </li>
          <li>You can get an update or correction to your personal data</li>
          <li>You can have your personal data deleted or destroyed</li>
          <li>
            You can have us stop or limit processing of your personal data
          </li>
          <li>
            If you have given consent for us to process your personal data (see
            Section 5), you can withdraw your consent at any time. Your
            withdrawal will not affect the lawfulness of the processing carried
            out before you withdrew your consent
          </li>
          <li>
            You can submit a complaint about how we process your personal data
            to a Data Protection Authority. Under applicable law, there may be
            limits on these rights depending on the specific circumstances of
            the processing activity. Contact us as described in Section 1 with
            questions or requests relating to these rights.
          </li>
        </ul>
      </p>
    </Trans>
  </div>
);
