import { AsyncActionSet } from '@dabapps/redux-requests';
import { Button, FormGroup } from '@dabapps/roe';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import ErrorRenderer from '^/common/components/form/error-renderer';
import RenderDropdown from '^/common/components/form/render-dropdown';
import RenderField from '^/common/components/form/render-field';
import { StoreState } from '^/common/types';
import { getCountries } from '^/countries/country-actions';
import { Trialsite } from '../trialsite-types';

interface OwnProps {
  /** Actions that this form is going to hit, in the event of a failure, generalErrorFields will be extracted and shown at the bottom of the form. */
  actions?: ReadonlyArray<AsyncActionSet>;
  /** Fields that the API will return to give us general errors. Defaults to DRF's standard 'non_field_errors'. */
  errorFields?: ReadonlyArray<string>;
  /** Function to run on clicking cancel button */
  onCancel?(): void;
}

interface ConnectedProps {
  countries: StoreState['countries'];
}

interface DispatchProps {
  getCountries: typeof getCountries;
}

export type TrialsiteFormProps = ConnectedProps &
  DispatchProps &
  OwnProps &
  InjectedFormProps<Trialsite, OwnProps> &
  WithTranslation;

class TrialsiteForm extends React.Component<TrialsiteFormProps> {
  public componentDidMount() {
    this.props.getCountries();
  }

  public render() {
    const {
      t,
      actions = [],
      errorFields = [],
      handleSubmit,
      onCancel,
      submitting,
      countries = [],
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Field
            label={t('fields.site_reference')}
            name="site_reference"
            component={RenderField}
            type="number"
          />
          <Field
            label={t('fields.country')}
            name="country"
            component={RenderDropdown}
            options={countries.map(c => ({ label: c.name, value: c.code }))}
            className="siteDropdown"
          />
        </FormGroup>
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors', ...errorFields]}
          showStatusErrors
        />
        <div className="form-buttons">
          {onCancel && (
            <Button
              className="pill"
              disabled={submitting}
              onClick={onCancel}
              type="button"
            >
              {t('buttons.cancel')}
            </Button>
          )}
          <Button className="primary pill" disabled={submitting} type="submit">
            {t('buttons.add-site-to-trial')}
          </Button>
        </div>
      </Form>
    );
  }
}

export { TrialsiteForm as TestableTrialsiteForm };

export const mapStateToProps = (state: StoreState): ConnectedProps => ({
  countries: state.countries,
});

export default reduxForm<Trialsite, OwnProps>({
  form: 'TrialsiteForm',
  initialValues: { country: 'GB' },
})(
  compose<
    TrialsiteFormProps,
    OwnProps & InjectedFormProps<Trialsite, OwnProps>
  >(
    connect(mapStateToProps, { getCountries }),
    withTranslation('trialsites')
  )(TrialsiteForm)
);
