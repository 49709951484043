import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import ErrorRenderer from '^/common/components/form/error-renderer';

interface OwnProps {
  label?: string;
  placeholder?: string;
  sideLabel?: string;
  icon?: IconProp;
  fieldSize?: 'sm';
  clearable?: boolean;
}

export type RenderFieldProps = OwnProps &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps;

export class RenderField extends React.PureComponent<RenderFieldProps> {
  public render() {
    const {
      input,
      label,
      type,
      meta: { touched, error, warning, dirty },
      placeholder,
      sideLabel,
      icon,
      fieldSize: size,
      clearable,
    } = this.props;

    return (
      <div
        className={classNames('form-field', {
          sm: size === 'sm',
          dirty: clearable && dirty,
        })}
      >
        {label && <label>{label}</label>}
        <div
          className={classNames(
            'form-field-input',
            { error: touched && error },
            { warning: touched && warning }
          )}
        >
          {clearable && input.value && (
            <button className="clear" type="button" onClick={this.clear}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}
          {icon && <FontAwesomeIcon className="field-icon" icon={icon} />}
          <input {...input} placeholder={placeholder} type={type} />
          {sideLabel && <p> {sideLabel} </p>}
        </div>
        {touched && <ErrorRenderer formErrors={[error, warning]} />}
      </div>
    );
  }

  public clear = () => this.props.input.onChange('');
}

export { RenderField as TestableRenderField };

export default RenderField;
