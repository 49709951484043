import { Button } from '@dabapps/roe';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { InjectedFormProps, reduxForm } from 'redux-form';

import ErrorRenderer from '^/common/components/form/error-renderer';
import TrialFormGroups, {
  validate,
} from '^/trials/components/trial-form-group';
import { CREATE_TRIAL } from '^/trials/trial-actions';
import { TrialInput } from '^/trials/trial-types';

const actions = [CREATE_TRIAL];

interface OwnProps {
  onSubmit?: (values: TrialInput) => void;
}

type Props = OwnProps &
  InjectedFormProps<TrialInput, OwnProps> &
  WithTranslation;

export class CreateTrialForm extends React.Component<Props> {
  public render() {
    const { t, handleSubmit, submitting, pristine } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <hr />
        <TrialFormGroups formName={this.props.form} />
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors']}
          showStatusErrors
        />
        <hr />
        <div className="form-buttons">
          <Button
            type="submit"
            className="primary pill"
            disabled={submitting || pristine}
          >
            {t('buttons.add-new-trial')}
          </Button>
        </div>
      </form>
    );
  }
}

export default reduxForm<TrialInput, OwnProps>({
  form: 'createTrialForm',
  destroyOnUnmount: true,
  initialValues: {
    active: true,
  },
  validate,
})(withTranslation('trials')(CreateTrialForm));
