import { ContentBox, ContentBoxHeader } from '@dabapps/roe';
import classNames from 'classnames';
import React from 'react';

interface Props {
  type: 'Trial' | 'User' | 'Site' | 'PICInformation';
  header?: string;
  headerComponents?: React.ReactChild[];
}

class Card extends React.PureComponent<Props> {
  public render() {
    const { type, header, headerComponents } = this.props;

    return (
      <ContentBox className={classNames('card', type)}>
        <ContentBoxHeader className="card-header">
          <h2>{header}</h2>
          <div className="card-header-components">{headerComponents}</div>
        </ContentBoxHeader>
        {this.props.children}
      </ContentBox>
    );
  }
}

export default Card;
