import { anyPending } from '@dabapps/redux-requests';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import Card from '^/common/components/containers/card';
import CollapsableCard from '^/common/components/containers/collapsable-card';
import PageContainer from '^/common/components/containers/page-container';
import ErrorRenderer from '^/common/components/form/error-renderer';
import InputFilter from '^/common/components/form/input-filter';
import LoadingRenderer from '^/common/components/form/loading-renderer';
import { StoreState } from '^/common/types';
import CreateTrialForm from '^/trials/components/trial-form-create';
import TrialTable from '^/trials/components/trial-table';
import {
  createTrial,
  GET_ALL_TRIALS,
  toggleAddTrial,
} from '^/trials/trial-actions';
import { TrialInput, TrialRecord } from '^/trials/trial-types';

const actions = [GET_ALL_TRIALS];

interface ConnectedProps {
  addTrialExpanded: boolean;
  isAdmin: boolean;
  isLoading: boolean;
}

interface DispatchProps {
  createTrial: typeof createTrial;
  toggleAddTrial: typeof toggleAddTrial;
}

type Props = DispatchProps &
  ConnectedProps &
  RouteComponentProps<{}> &
  WithTranslation;

const SEARCH_TERM = 'Trial';
const headerComponents = [
  <InputFilter type={SEARCH_TERM} key={`${SEARCH_TERM}InputFilter`} />,
];

export class TrialsPage extends React.PureComponent<Props> {
  public render() {
    const { t, isAdmin = false, isLoading, addTrialExpanded } = this.props;

    return (
      <PageContainer admin>
        {isAdmin && (
          <CollapsableCard
            header={t('add-new-trial-heading')}
            icon="add"
            expanded={addTrialExpanded}
            onClick={this.props.toggleAddTrial}
          >
            <CreateTrialForm onSubmit={this.onCreateTrialSubmit} />
          </CollapsableCard>
        )}
        <Card
          type="Trial"
          header={t('trials-you-manage')}
          headerComponents={headerComponents}
        >
          <TrialTable />
          {isLoading && <LoadingRenderer />}
          <ErrorRenderer
            actions={actions}
            fields={['non_field_errors']}
            showStatusErrors
          />
        </Card>
      </PageContainer>
    );
  }

  private onCreateTrialSubmit = (values: TrialInput) => {
    this.props.createTrial(TrialRecord({ ...values }));
  };
}

const mapStateToProps = ({
  addTrialExpanded,
  isAdmin,
  responses,
}: StoreState): ConnectedProps => {
  return {
    addTrialExpanded,
    isAdmin,
    isLoading: anyPending(responses, actions),
  };
};

export default withTranslation('trials')(
  connect(mapStateToProps, {
    createTrial,
    toggleAddTrial,
  })(TrialsPage)
);
