import { makeAsyncActionSet } from '@dabapps/redux-requests';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
import { Moment } from 'moment';
import moment from 'moment';
import Calendar from 'rc-calendar';
import React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { StoreState } from '^/common/types';
import {
  createWidget,
  InjectedWidgetHeaderProps,
  InjectedWidgetProps,
} from '^/reports/components/hoc/create-widget';
import CalendarCell from '^/reports/components/widgets/screenings-booked/calendar-cell';
import { changeCalendarMonth } from '^/reports/components/widgets/screenings-booked/screenings-booked-actions';
import BarChart from './bar-chart';
import ExtraHeaderContent from './extra-header-content';

export interface CalendarDatum {
  date: string;
  count: number;
}

export interface ScreeningsBookedData {
  total: number;
  screenings: ReadonlyArray<CalendarDatum>;
  monthly_booking_counts: ReadonlyArray<CalendarDatum>;
}

export interface DispatchProps {
  changeCalendarMonth: typeof changeCalendarMonth;
}

type StateProps = Pick<
  StoreState,
  'screeningsBookedSelectedMonth' | 'screeningsBookedViewGraph'
>;

type OwnProps = InjectedWidgetProps<ScreeningsBookedData>;

type Props = OwnProps & StateProps & DispatchProps & WithTranslation;

export const ScreeningsBookedTitle = (
  props: InjectedWidgetHeaderProps<ScreeningsBookedData>
) => {
  if (props.isComparison) {
    return (
      <h2>
        <Trans i18nKey="reports:screenings-booked.heading-comparison">
          Screenings Booked
        </Trans>
      </h2>
    );
  }

  const screeningsBooked = props.data ? props.data.total : 0;
  return (
    <h2>
      <Trans i18nKey="reports:screenings-booked.heading">
        Screenings Booked
      </Trans>{' '}
      ({screeningsBooked})
    </h2>
  );
};

const getFirstDate = (screenings: ReadonlyArray<CalendarDatum>): Moment => {
  if (!screenings.length) {
    return moment.utc();
  }

  return moment.utc(screenings[0].date);
};

export class ScreeningsBooked extends React.PureComponent<Props> {
  public render() {
    const { t } = this.props;

    if (!this.props.data.screenings || !this.props.data.screenings.length) {
      return <p>{t('empty-state')}</p>;
    }

    if (this.props.screeningsBookedViewGraph) {
      return <BarChart {...this.props} />;
    }

    const { screeningsBookedSelectedMonth } = this.props;
    const firstDate = getFirstDate(this.props.data.screenings);

    return (
      <Calendar
        className="screenings-booked"
        showWeekNumber={false}
        showOk={false}
        showDateInput={false}
        showToday={false}
        defaultValue={moment.utc(firstDate)}
        value={screeningsBookedSelectedMonth || undefined}
        dateRender={this.renderDateCell}
        onChange={this.onChangeMonth}
      />
    );
  }

  public renderDateCell = (date: Moment) => {
    const firstDate = getFirstDate(this.props.data.screenings);
    const data = this.props.data.screenings.find(event =>
      moment.utc(event.date).isSame(date)
    );
    const eventCount = data ? data.count : 0;
    const selectedMonth = this.props.screeningsBookedSelectedMonth;

    return (
      <CalendarCell
        eventCount={eventCount}
        date={date}
        isInMonth={
          selectedMonth
            ? Number(selectedMonth.format('M')) - 1 === date.month()
            : firstDate.month() === date.month()
        }
      />
    );
  };

  private onChangeMonth = (value: Moment | null) => {
    if (value) {
      this.props.changeCalendarMonth(value);
    }
  };
}

export const mapStateToProps = ({
  screeningsBookedSelectedMonth,
  screeningsBookedViewGraph,
}: StoreState): StateProps => {
  return {
    screeningsBookedSelectedMonth,
    screeningsBookedViewGraph,
  };
};

const GET_SCREENINGS_BOOKED = makeAsyncActionSet('GET_SCREENINGS_BOOKED');

export default compose<Props, {}>(
  createWidget<ScreeningsBookedData>({
    title: ScreeningsBookedTitle,
    icon: faCalendarAlt,
    url: '/api/reports/screenings-booked/',
    actionSet: GET_SCREENINGS_BOOKED,
    extraHeaderContent: ExtraHeaderContent,
    halfWidth: true,
  }),
  connect(mapStateToProps, {
    changeCalendarMonth,
  }),
  withTranslation('reports')
)(ScreeningsBooked);
