import { Container } from '@dabapps/roe';
import React from 'react';
import MDSpinner from 'react-md-spinner';

class LoadingPage extends React.PureComponent {
  public render() {
    return (
      <Container
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <MDSpinner singleColor="#00a4e4" />
      </Container>
    );
  }
}

export { LoadingPage as TestableLoadingPage };

export default LoadingPage;
