import { LOCATION_CHANGE } from 'connected-react-router';

import { AnyAction } from '^/common/types';
import { LOGOUT } from '^/login/login-actions';
import {
  CREATE_USER,
  GET_ALL_USERS,
  SET_USER_FILTER_TERM,
  SET_USER_SEARCH_TERM,
  TOGGLE_ADD_USER,
} from '^/users/user-actions';
import { User, UserRecord } from '^/users/user-types';

export function userList(state: ReadonlyArray<User> = [], action: AnyAction) {
  switch (action.type) {
    case GET_ALL_USERS.SUCCESS:
      if (!Array.isArray(action.payload.data)) {
        return state;
      }

      return action.payload.data.map(UserRecord);
    case LOGOUT.SUCCESS:
      return [];
    default:
      return state;
  }
}

export function userSearchTerm(state: string | null = null, action: AnyAction) {
  switch (action.type) {
    case SET_USER_SEARCH_TERM:
      return action.payload;
    case LOGOUT.SUCCESS:
      return '';
    default:
      return state;
  }
}

export function userFilterTerm(state: string | null = null, action: AnyAction) {
  switch (action.type) {
    case SET_USER_FILTER_TERM:
      return action.payload;
    case LOGOUT.SUCCESS:
      return '';
    default:
      return state;
  }
}

export function addUserExpanded(
  state: boolean = false,
  action: AnyAction
): boolean {
  switch (action.type) {
    case TOGGLE_ADD_USER:
      if (typeof action.payload === 'boolean') {
        return action.payload;
      }

      return !state;
    case CREATE_USER.SUCCESS:
    case LOCATION_CHANGE:
      return false;
    case LOGOUT.SUCCESS:
      return false;
    default:
      return state;
  }
}
