import { Container, ModalRenderer } from '@dabapps/roe';
import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import Footer from '^/common/components/containers/footer/footer';
import ConfirmDeletePatientModal from '^/common/components/modals/confirm-delete-patient-modal';
import UpdateStatusModal from '^/common/components/modals/update-status-modal';
import AdminTabs from '^/common/components/navigation/admin-tabs';
import NovoNavigation from '^/common/components/navigation/novo-navigation';
import TrialSitePicker from '^/common/components/navigation/trial-site-picker';
import { StoreState } from '^/common/types';
import GlobalModalRender from '^/modals/global-modal-render';
import { ModalsState, ModalType } from '^/modals/modal-types';
import DeletePICInformationModal from '^/pic-information/components/pic-information-modal-delete';
import TrialsiteAddCRAModal from '^/trialsites/components/trialsite-add-cra-user-modal';
import TrialsiteAddSiteModal from '^/trialsites/components/trialsite-add-site-user-modal';
import TrialsiteSelectionModal from '^/trialsites/components/trialsite-selection-modal';

interface OwnProps {
  page?: string;
  admin?: boolean;
}

interface ConnectedProps {
  modalState: ModalsState;
}

export type Props = OwnProps & ConnectedProps;

export class PageContainer extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    page: 'other',
    admin: false,
  };

  public render() {
    const { page, admin, modalState } = this.props;

    const modals = this.renderModals(modalState);

    return (
      <>
        <GlobalModalRender />
        <Container className={classnames({ admin }, 'page-container', page)}>
          <NovoNavigation>
            {admin ? <AdminTabs /> : <TrialSitePicker />}
          </NovoNavigation>
          <div className={classnames({ admin }, 'page-content', page)}>
            {this.props.children}
          </div>
          {modals && <ModalRenderer modals={modals} />}

          <Footer />
        </Container>
      </>
    );
  }

  private renderModals = (modals: ModalsState) => {
    if (modals[ModalType.UpdatePatientStatus].show) {
      return [<UpdateStatusModal key={1} />];
    }

    if (modals[ModalType.SwitchTrial].show) {
      return [<TrialsiteSelectionModal key={1} />];
    }

    if (modals[ModalType.AddCRAUserToTrialsite].show) {
      return [<TrialsiteAddCRAModal key={1} />];
    }

    if (modals[ModalType.AddSiteUserToTrialsite].show) {
      return [<TrialsiteAddSiteModal key={1} />];
    }

    if (modals[ModalType.DeletePICInformation].show) {
      return [<DeletePICInformationModal key={1} />];
    }

    if (modals[ModalType.ConfirmDeletePatient].show) {
      return [<ConfirmDeletePatientModal key={1} />];
    }

    return null;
  };
}

export const mapStateToProps = ({
  sharedModals: modals,
}: StoreState): ConnectedProps => {
  return {
    modalState: modals,
  };
};

export default connect(mapStateToProps, {})(PageContainer);
