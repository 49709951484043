import { LOCATION_CHANGE } from 'connected-react-router';

import { AnyAction, StoreState } from '^/common/types';
import {
  CREATE_PATIENT,
  DELETE_PATIENT,
  GET_ALL_PATIENTS,
  GET_PATIENT_SOURCES,
  GET_PATIENTS_BY_TRIALSITE,
  TOGGLE_ADD_PATIENT,
  TOGGLE_PATIENTS_DECLINED,
  TOGGLE_PATIENTS_POSSIBLE_RECRUITS,
  TOGGLE_PATIENTS_SCREENING_COMPLETE,
  UPDATE_PATIENT,
} from '^/patients/patient-actions';
import {
  Patient,
  PatientRecord,
  PatientStatus,
  Source,
} from '^/patients/patient-types';
import { SET_SELECTED_TRIALSITE } from '^/trialsites/trialsite-actions';

export function patientList(
  state: ReadonlyArray<Patient> = [],
  action: AnyAction
) {
  switch (action.type) {
    case GET_ALL_PATIENTS.SUCCESS:
      if (!Array.isArray(action.payload.data)) {
        return state;
      }

      return action.payload.data.map(PatientRecord);
    case DELETE_PATIENT.SUCCESS:
      return state.filter(p => p.id !== action.meta.id);
    default:
      return state;
  }
}

export function patientsByTrialsite(
  state: StoreState['patientsByTrialsite'] = {},
  action: AnyAction
): StoreState['patientsByTrialsite'] {
  switch (action.type) {
    case GET_PATIENTS_BY_TRIALSITE.SUCCESS:
      if (!Array.isArray(action.payload.data)) {
        return state;
      }

      return {
        ...state,
        [action.meta.trialsite]: action.payload.data.map(PatientRecord),
      };
    case CREATE_PATIENT.SUCCESS:
      return {
        ...state,
        [action.payload.data.trialsite]: [
          PatientRecord(action.payload.data),
          ...(state[action.payload.data.trialsite] || []),
        ],
      };
    case UPDATE_PATIENT.SUCCESS:
      const trialsite = action.payload.data.trialsite;
      const trialsiteState = state[trialsite] || [];
      const currentData = trialsiteState.slice();
      const currentIndex = currentData.findIndex(
        patient => patient.id === action.payload.data.id
      );

      if (currentIndex >= 0) {
        currentData[currentIndex] = PatientRecord(action.payload.data);
      }

      return {
        ...state,
        [trialsite]: currentData,
      };
    case DELETE_PATIENT.SUCCESS:
      return Object.keys(state).reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [currentValue]: state[currentValue].filter(
            p => p.id !== action.meta.id
          ),
        }),
        {}
      );
    default:
      return state;
  }
}

export function patientSources(
  state: ReadonlyArray<Source> = [],
  action: AnyAction
) {
  switch (action.type) {
    case GET_PATIENT_SOURCES.SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
}

export function addPatientExpanded(
  state: boolean = false,
  action: AnyAction
): boolean {
  switch (action.type) {
    case TOGGLE_ADD_PATIENT:
      if (typeof action.payload === 'boolean') {
        return action.payload;
      }

      return !state;
    case CREATE_PATIENT.SUCCESS:
    case SET_SELECTED_TRIALSITE:
    case LOCATION_CHANGE:
      return false;
    default:
      return state;
  }
}

export function patientsPossibleRecruitsExpanded(
  state: boolean = true,
  action: AnyAction
): boolean {
  switch (action.type) {
    case TOGGLE_PATIENTS_POSSIBLE_RECRUITS:
      if (typeof action.payload === 'boolean') {
        return action.payload;
      }

      return !state;
    case CREATE_PATIENT.SUCCESS:
    case UPDATE_PATIENT.SUCCESS:
      const patient = PatientRecord(action.payload.data);
      return (
        patient.status !== PatientStatus.ScreeningComplete &&
        patient.status !== PatientStatus.Declined
      );
    case TOGGLE_PATIENTS_SCREENING_COMPLETE:
    case TOGGLE_PATIENTS_DECLINED:
      return false;
    case SET_SELECTED_TRIALSITE:
    case LOCATION_CHANGE:
      return true;
    default:
      return state;
  }
}

export function patientsScreeningsCompleteExpanded(
  state: boolean = false,
  action: AnyAction
): boolean {
  switch (action.type) {
    case TOGGLE_PATIENTS_SCREENING_COMPLETE:
      if (typeof action.payload === 'boolean') {
        return action.payload;
      }

      return !state;
    case CREATE_PATIENT.SUCCESS:
    case UPDATE_PATIENT.SUCCESS:
      const patient = PatientRecord(action.payload.data);
      return patient.status === PatientStatus.ScreeningComplete;
    case TOGGLE_PATIENTS_DECLINED:
    case TOGGLE_PATIENTS_POSSIBLE_RECRUITS:
    case SET_SELECTED_TRIALSITE:
    case LOCATION_CHANGE:
      return false;
    default:
      return state;
  }
}

export function patientsDeclinedExpanded(
  state: boolean = false,
  action: AnyAction
): boolean {
  switch (action.type) {
    case TOGGLE_PATIENTS_DECLINED:
      if (typeof action.payload === 'boolean') {
        return action.payload;
      }

      return !state;
    case CREATE_PATIENT.SUCCESS:
    case UPDATE_PATIENT.SUCCESS:
      const patient = PatientRecord(action.payload.data);
      return patient.status === PatientStatus.Declined;
    case TOGGLE_PATIENTS_SCREENING_COMPLETE:
    case TOGGLE_PATIENTS_POSSIBLE_RECRUITS:
    case LOCATION_CHANGE:
    case SET_SELECTED_TRIALSITE:
      return false;
    default:
      return state;
  }
}
