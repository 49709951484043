import {
  dispatchGenericRequest,
  makeAsyncActionSet,
} from '@dabapps/redux-requests';
import { Dispatch } from 'redux';

export const GET_COUNTRIES = makeAsyncActionSet('GET_COUNTRIES');
export function getCountries() {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      GET_COUNTRIES,
      '/api/countries/',
      'GET'
    )(dispatch);
  };
}
