import { TableCell, TableHeader, TableRow } from '@dabapps/roe';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { User } from '^/users/user-types';

interface Props extends WithTranslation {
  allowEdit?: boolean;
  user: User;
}

export class UserRow extends React.PureComponent<Props> {
  public render() {
    const { t, allowEdit = false, user } = this.props;

    return (
      <TableRow>
        <TableHeader title={user.full_name}>{user.full_name}</TableHeader>
        <TableCell className="email" title={user.email}>
          {user.email}
        </TableCell>
        <TableCell title={user.sso_id}>{user.sso_id}</TableCell>
        <TableCell title={user.impact_id}>{user.impact_id}</TableCell>
        <TableCell>{t(`roles.${user.roles}`)}</TableCell>
        <TableCell>{t(`active.${user.active}`)}</TableCell>
        {allowEdit && (
          <TableCell className="edit">
            <Link to={'/users/' + user.id}>
              <FontAwesomeIcon size="2x" icon={faEdit} />
            </Link>
          </TableCell>
        )}
      </TableRow>
    );
  }
}

export default withTranslation('users')(UserRow);
