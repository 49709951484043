import { anyPending } from '@dabapps/redux-requests';
import {
  Column,
  ContentBox,
  ContentBoxHeader,
  Row,
  Section,
} from '@dabapps/roe';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import compose from 'recompose/compose';

import PageContainer from '^/common/components/containers/page-container';
import ErrorRenderer from '^/common/components/form/error-renderer';
import LoadingRenderer from '^/common/components/form/loading-renderer';
import { StoreState } from '^/common/types';
import EditUserForm from '^/users/components/user-form-edit';
import {
  GET_ALL_USERS,
  getAllUsers,
  inviteUserEmail,
  updateUser,
} from '^/users/user-actions';
import { User } from '^/users/user-types';

const actions = [GET_ALL_USERS];

interface DispatchProps {
  updateUser: typeof updateUser;
  getAllUsers: typeof getAllUsers;
  inviteUserEmail: typeof inviteUserEmail;
}

interface ConnectedProps {
  isLoading: boolean;
  userList: ReadonlyArray<User>;
  isAdmin: boolean;
}

export type Props = DispatchProps &
  ConnectedProps &
  RouteComponentProps<{ id: string }> &
  WithTranslation;

export class UserPageEdit extends React.PureComponent<Props> {
  public componentDidMount() {
    if (!this.props.userList || this.props.userList.length <= 0) {
      this.props.getAllUsers();
    }
  }

  public render() {
    const { t, userList, match, isLoading, isAdmin } = this.props;

    const user = userList.find(x => x.id === match.params.id);

    const editUserForm = user ? (
      <EditUserForm
        initialValues={user}
        handleResendInvite={this.handleResendInvite}
        onSubmit={this.onUpdateUserSubmit}
      />
    ) : (
      <Section>
        <p>{t('empty-state')}</p>
      </Section>
    );

    return (
      <PageContainer admin>
        {isAdmin ? (
          <ContentBox>
            <ContentBoxHeader>
              <Row>
                <Column className="back-to" xs={4}>
                  <Link to="/users">
                    <FontAwesomeIcon icon={faArrowLeft} /> {t('back-to-users')}
                  </Link>
                </Column>
                <Column xsFill={4} xs={4} className="text-align-center">
                  <h2>{t('edit-user-heading')}</h2>
                </Column>
              </Row>
            </ContentBoxHeader>
            {isLoading ? <LoadingRenderer /> : editUserForm}
            <ErrorRenderer
              actions={actions}
              fields={['non_field_errors']}
              showStatusErrors
            />
          </ContentBox>
        ) : (
          <Section>
            <p>{t('errors:forbidden')}</p>
          </Section>
        )}
      </PageContainer>
    );
  }

  private handleResendInvite = (email: string) => {
    this.props.inviteUserEmail(email);
  };

  private onUpdateUserSubmit = (values: User) => {
    this.props.updateUser(values.id, values);
  };
}

export const mapStateToProps = ({
  responses,
  userList,
  isAdmin,
}: StoreState): ConnectedProps => {
  return {
    isLoading: anyPending(responses, actions),
    userList,
    isAdmin,
  };
};

export default compose<Props, {}>(
  connect(mapStateToProps, {
    updateUser,
    getAllUsers,
    inviteUserEmail,
  }),
  withTranslation(['users', 'errors'])
)(UserPageEdit);
