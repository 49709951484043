import {
  dispatchGenericRequest,
  makeAsyncActionSet,
} from '@dabapps/redux-requests';
import { Dispatch } from 'redux';

const ACCOUNTS_ENDPOINT = '/api/accounts';

export const GET_USER_ROLES = makeAsyncActionSet('GET_USER_ROLES');
export function getUserRoles() {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      GET_USER_ROLES,
      `${ACCOUNTS_ENDPOINT}/roles/`,
      'GET'
    )(dispatch).catch(() => null);
  };
}
