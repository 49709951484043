import React from 'react';

import { errorToString } from '^/reports/components/hoc/create-widget/utils';
import { Props as WidgetProps } from '.';

type ErrorNoDataOrComponentKeys =
  | 'error'
  | 'data'
  | 'isComparison'
  | 'component';

export type Props<ReportData> = Pick<
  WidgetProps<ReportData>,
  ErrorNoDataOrComponentKeys
>;

const noDataMessage = <p className="info">No data</p>;

// tslint:disable-next-line:no-any
const Visualization = <ReportData extends any>({
  error,
  data,
  isComparison,
  component: Component,
}: Props<ReportData>) => {
  if (error) {
    return <p className="error">{errorToString(error)}</p>;
  }

  if (!data) {
    return noDataMessage;
  }

  return <Component data={data} isComparison={isComparison} />;
};

export default Visualization;
