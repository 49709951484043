import {
  Section,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { compose } from 'recompose';

import LoadingRenderer from '^/common/components/form/loading-renderer';
import { StoreState } from '^/common/types';
import PICInformationRow from '^/pic-information/components/pic-information-row';
import { getPICInformationByTrialsite } from '^/pic-information/pic-information-actions';
import { PICInformation } from '^/pic-information/pic-information-types';
import { SelectedTrialsite } from '^/trialsites/trialsite-types';

interface ConnectedProps {
  selectedTrialsite: SelectedTrialsite | null;
  displayedPICInformation: ReadonlyArray<PICInformation>;
}

interface DispatchProps {
  getPICInformationByTrialsite: typeof getPICInformationByTrialsite;
}

export type Props = ConnectedProps & DispatchProps & WithTranslation;

export class PICInformationTable extends React.PureComponent<Props> {
  public componentWillMount() {
    if (
      this.props.selectedTrialsite &&
      this.props.selectedTrialsite.trialsite.id
    ) {
      this.props.getPICInformationByTrialsite(
        this.props.selectedTrialsite.trialsite.id
      );
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      this.props.selectedTrialsite !== prevProps.selectedTrialsite &&
      this.props.selectedTrialsite &&
      this.props.selectedTrialsite.trialsite.id
    ) {
      this.props.getPICInformationByTrialsite(
        this.props.selectedTrialsite.trialsite.id
      );
    }
  }

  public render() {
    const { t, displayedPICInformation } = this.props;
    if (!displayedPICInformation) {
      return <LoadingRenderer />;
    }

    if (displayedPICInformation.length <= 0) {
      return (
        <Section>
          <p>{t('empty-state')}</p>
        </Section>
      );
    }

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>{t('fields.name')}</TableHeader>
            <TableHeader>{t('fields.date')}</TableHeader>
            <TableHeader>{t('fields.patients_identified')}</TableHeader>
            <TableHeader>{t('fields.patients_approached')}</TableHeader>
            <TableHeader />
          </TableRow>
        </TableHead>
        <TransitionGroup component={TableBody}>
          {displayedPICInformation.map(pic => (
            <CSSTransition
              key={pic.id}
              classNames="table-row-animation"
              timeout={{ enter: 1000, exit: 200 }}
              unmountOnExit
            >
              <PICInformationRow pic={pic} />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </Table>
    );
  }
}

export const mapStateToProps = ({
  selectedTrialsite,
  displayedPICInformation,
}: StoreState): ConnectedProps => {
  return {
    selectedTrialsite,
    displayedPICInformation,
  };
};

export default compose<Props, {}>(
  connect(mapStateToProps, { getPICInformationByTrialsite }),
  withTranslation('pic')
)(PICInformationTable);
