import { AnyAction, StoreState } from '^/common/types';
import { GET_COUNTRIES } from '^/countries/country-actions';

export function countries(
  state: StoreState['countries'] = [],
  action: AnyAction
): StoreState['countries'] {
  switch (action.type) {
    case GET_COUNTRIES.SUCCESS:
      if (!Array.isArray(action.payload.data)) {
        return state;
      }

      return action.payload.data;
    default:
      return state;
  }
}
