import { REQUEST_STATE } from '@dabapps/redux-requests';
import { SetRequestStatePayload } from '@dabapps/redux-requests/dist/js/types';
import { Action } from 'redux';

import { AnyAction, StoreState } from '^/common/types';
import {
  GET_ALL_COMPARISON_SITE_OPTIONS,
  GET_ALL_SITE_OPTIONS,
  TOGGLE_FILTER_SITES_ACTIVE,
  TOGGLE_SCREENINGS_BOOKED_VIEW_MODE,
  TOGGLE_SITES_COLLAPSED,
} from '^/reports/reports-actions';
import { ReportsMap, SiteDropdownValueRecord } from '^/reports/reports-types';

interface SetRequestStateAction extends Action<string> {
  payload: SetRequestStatePayload;
}

export const reportsMap = <T>(state: ReportsMap<T> = {}, action: AnyAction) => {
  switch (action.type) {
    case REQUEST_STATE:
      if (action.payload) {
        switch ((action as SetRequestStateAction).payload.requestState) {
          case 'SUCCESS':
            const {
              actionSet: { REQUEST: actionKey },
              tag,
              data,
            } = (action as SetRequestStateAction).payload;

            return {
              ...state,
              [actionKey]: {
                ...state[actionKey],
                [tag || '']: data.data,
              },
            };
          case 'FAILURE':
          case 'REQUEST':
          default:
            return state;
        }
      }

      return state;
    default:
      return state;
  }
};

export const screeningsBookedViewGraph = (state = false, action: AnyAction) => {
  switch (action.type) {
    case TOGGLE_SCREENINGS_BOOKED_VIEW_MODE:
      return !state;
    default:
      return state;
  }
};

export const filterSitesActive = (state = true, action: AnyAction) => {
  switch (action.type) {
    case TOGGLE_FILTER_SITES_ACTIVE:
      return !state;
    default:
      return state;
  }
};

export const sitesCollapsed = (state = true, action: AnyAction) => {
  switch (action.type) {
    case TOGGLE_SITES_COLLAPSED:
      return !state;
    default:
      return state;
  }
};

export function siteOptionsList(
  state: StoreState['siteOptionsList'] = [],
  action: AnyAction
): StoreState['siteOptionsList'] {
  switch (action.type) {
    case GET_ALL_SITE_OPTIONS.SUCCESS:
      if (!Array.isArray(action.payload.data)) {
        return state;
      }

      return action.payload.data.map(SiteDropdownValueRecord);
    default:
      return state;
  }
}

export function siteOptionsCountries(
  state: StoreState['siteOptionsCountries'] = [],
  action: AnyAction
): StoreState['siteOptionsCountries'] {
  switch (action.type) {
    case GET_ALL_SITE_OPTIONS.SUCCESS:
      if (!Array.isArray(action.payload.data)) {
        return state;
      }

      const allCountries: string[] = action.payload.data.map(
        (s: { country: string }) => s.country
      );

      return [...new Set(allCountries)];
    default:
      return state;
  }
}

export function comparisonSiteOptionsList(
  state: StoreState['comparisonSiteOptionsList'] = [],
  action: AnyAction
) {
  switch (action.type) {
    case GET_ALL_COMPARISON_SITE_OPTIONS.SUCCESS:
      if (!Array.isArray(action.payload.data)) {
        return state;
      }

      return action.payload.data.map(SiteDropdownValueRecord);
    default:
      return state;
  }
}

export function comparisonSiteOptionsCountries(
  state: StoreState['comparisonSiteOptionsCountries'] = [],
  action: AnyAction
): StoreState['comparisonSiteOptionsCountries'] {
  switch (action.type) {
    case GET_ALL_COMPARISON_SITE_OPTIONS.SUCCESS:
      if (!Array.isArray(action.payload.data)) {
        return state;
      }

      const allCountries: string[] = action.payload.data.map(
        (s: { country: string }) => s.country
      );

      return [...new Set(allCountries)];
    default:
      return state;
  }
}
