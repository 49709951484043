import {
  dispatchGenericRequest,
  makeAsyncActionSet,
} from '@dabapps/redux-requests';
import { Dispatch } from 'redux';

import { hideModal } from '^/modals/modal-actions';
import { ModalType } from '^/modals/modal-types';
import { Patient } from '^/patients/patient-types';
const PATIENTS_ENDPOINT = '/api/patients/';

export const GET_ALL_PATIENTS = makeAsyncActionSet('GET_ALL_PATIENTS');
export function getAllPatients() {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      GET_ALL_PATIENTS,
      PATIENTS_ENDPOINT,
      'GET'
    )(dispatch).catch(() => null);
  };
}

export const GET_PATIENTS_BY_TRIALSITE = makeAsyncActionSet(
  'GET_PATIENTS_BY_TRIALSITE'
);
export function getPatientsByTrialsite(trialsite: string) {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      GET_PATIENTS_BY_TRIALSITE,
      `${PATIENTS_ENDPOINT}?trialsite=${trialsite}`,
      'GET',
      undefined,
      undefined,
      { trialsite }
    )(dispatch);
  };
}

export const GET_PATIENT_SOURCES = makeAsyncActionSet('GET_PATIENT_SOURCES');
export function getPatientSources() {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      GET_PATIENT_SOURCES,
      `${PATIENTS_ENDPOINT}sources/`,
      'GET'
    )(dispatch).catch(() => null);
  };
}

export const CREATE_PATIENT = makeAsyncActionSet('CREATE_PATIENT');
export function createPatient(patient: Patient) {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      CREATE_PATIENT,
      PATIENTS_ENDPOINT,
      'POST',
      patient
    )(dispatch)
      .then(response => {
        if (response.data.trialsite) {
          getPatientsByTrialsite(response.data.trialsite)(dispatch);
        }
      })
      .catch(() => null);
  };
}

export const UPDATE_PATIENT = makeAsyncActionSet('UPDATE_PATIENT');
export function updatePatient(id: string, patient: Partial<Patient>) {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      UPDATE_PATIENT,
      `${PATIENTS_ENDPOINT}${id}/`,
      'POST',
      patient,
      patient.id
    )(dispatch)
      .then(response => {
        if (response.data.trialsite) {
          getPatientsByTrialsite(response.data.trialsite)(dispatch);
        }
      })
      .then(() => {
        dispatch(hideModal(ModalType.UpdatePatientStatus));
      })
      .catch(() => null);
  };
}

export const DELETE_PATIENT = makeAsyncActionSet('DELETE_PATIENT');
export function deletePatient(id: string) {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      DELETE_PATIENT,
      `${PATIENTS_ENDPOINT}${id}/`,
      'DELETE',
      undefined,
      undefined,
      { id }
    )(dispatch)
      .then(() => {
        dispatch(hideModal(ModalType.ConfirmDeletePatient));
        return getAllPatients()(dispatch);
      })
      .catch(() => null);
  };
}

export const TOGGLE_ADD_PATIENT = 'TOGGLE_ADD_PATIENT';
export const toggleAddPatient = (expanded?: boolean) => ({
  type: TOGGLE_ADD_PATIENT,
  payload: expanded,
});

export const TOGGLE_PATIENTS_POSSIBLE_RECRUITS =
  'TOGGLE_PATIENT_POSSIBLE_RECRUITS';
export const togglePatientsPossibleRecruits = (expanded?: boolean) => ({
  type: TOGGLE_PATIENTS_POSSIBLE_RECRUITS,
  payload: expanded,
});

export const TOGGLE_PATIENTS_SCREENING_COMPLETE =
  'TOGGLE_PATIENTS_SCREENING_COMPLETE';
export const togglePatientsScreeningComplete = (expanded?: boolean) => ({
  type: TOGGLE_PATIENTS_SCREENING_COMPLETE,
  payload: expanded,
});

export const TOGGLE_PATIENTS_DECLINED = 'TOGGLE_PATIENTS_DECLINED';
export const togglePatientsDeclined = (expanded?: boolean) => ({
  type: TOGGLE_PATIENTS_DECLINED,
  payload: expanded,
});
