import React from 'react';

import CollapsableCard from '^/common/components/containers/collapsable-card';

interface OwnProps {
  videoExpanded: boolean;
  src: string;
  title: string;
  subtitle: string;
  toggleVideoExpanded(): void;
}

export type Props = OwnProps;

export class VideoCard extends React.PureComponent<Props> {
  public render() {
    const { videoExpanded, src, title, subtitle } = this.props;

    return (
      <CollapsableCard
        header={title}
        icon="add"
        expanded={videoExpanded}
        onClick={this.props.toggleVideoExpanded}
      >
        <div className="video-card">
          <iframe src={src} frameBorder="0" scrolling="no" allowFullScreen />

          <p>{subtitle}</p>
        </div>
      </CollapsableCard>
    );
  }
}

export default VideoCard;
