import { AnyAction, StoreState } from '^/common/types';
import {
  CLOSE_ALL_MODALS,
  CLOSE_MODAL,
  HIDE_MODAL,
  OPEN_MODAL,
  SHOW_MODAL,
} from '^/modals/modal-actions';
import { ModalsState, ModalsStateRecord } from '^/modals/modal-types';

export function sharedModals(
  state: Partial<ModalsState> = ModalsStateRecord(),
  action: AnyAction
): Partial<ModalsState> {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        ...action.payload,
      };
    case HIDE_MODAL:
      return {
        ...state,
        [action.payload]: { show: false },
      };
    default:
      return state;
  }
}

export const modals = (state: StoreState['modals'] = [], action: AnyAction) => {
  switch (action.type) {
    case OPEN_MODAL:
      return [...state, action.payload];
    case CLOSE_MODAL:
      return state.slice(0, -1);
    case CLOSE_ALL_MODALS:
      return [];
    default:
      return state;
  }
};
