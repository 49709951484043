import { SimpleRecord } from '@dabapps/simple-records';

export type FieldNames = Readonly<{
  TRIAL: string;
  COUNTRY: string;
  SITE: string;
  START: string;
  END: string;
  DATE: string;
}>;

export type TrialDropdownValue = Readonly<{
  id: string;
  global_trial_id: string;
}>;

export type SiteDropdownValue = Readonly<{
  id: string;
  site_reference: string;
  country: string;
}>;

export enum DateOption {
  Custom = 'CUSTOM',
  PastWeek = 'PAST_WEEK',
  PastMonth = 'PAST_MONTH',
  TrialStartToEnd = 'TRIAL_START_TO_END',
  TrialStartToToday = 'TRIAL_START_TO_TODAY',
}

export interface ReportsMap<T> {
  [i: string]:
    | {
        [i: string]: T;
      }
    | undefined;
}

export const DropdownValueRecord = SimpleRecord<TrialDropdownValue>({
  id: '',
  global_trial_id: '',
});

export const SiteDropdownValueRecord = SimpleRecord<SiteDropdownValue>({
  id: '',
  site_reference: '',
  country: '',
});
