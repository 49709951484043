import { makeAsyncActionSet } from '@dabapps/redux-requests';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import {
  faAngleDown,
  faAngleUp,
  faHospital,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { StoreState } from '^/common/types';
import {
  createWidget,
  InjectedWidgetProps,
} from '^/reports/components/hoc/create-widget';
import ExtraHeaderContent from '^/reports/components/widgets/site-activity/extra-header-content';
import {
  toggleFilterSitesActive,
  toggleSitesCollapsed,
} from '^/reports/reports-actions';
import { COMPARISON_COLUMNS, SINGLE_COLUMNS } from './constants';
import { SiteActivityData } from './types';

interface DispatchProps {
  toggleFilterSitesActive: typeof toggleFilterSitesActive;
  toggleSitesCollapsed: typeof toggleSitesCollapsed;
}

type StateProps = Pick<StoreState, 'filterSitesActive' | 'sitesCollapsed'>;

type InjectedProps = InjectedWidgetProps<SiteActivityData>;

type Props = StateProps & DispatchProps & InjectedProps & WithTranslation;

const SiteActivity: FunctionComponent<Props> = props => {
  const {
    t,
    data: sites,
    isComparison,
    filterSitesActive,
    sitesCollapsed,
  } = props;
  if (!sites || !sites.length) {
    return <p>{t('empty-state')}</p>;
  }

  const filteredSites = sites.filter(
    site => site.is_active === filterSitesActive
  );
  const filteredSitesTotal = filteredSites.slice(0);
  const columns = isComparison ? COMPARISON_COLUMNS : SINGLE_COLUMNS;
  let splicedSites;

  if (sitesCollapsed) {
    splicedSites = filteredSites.splice(4);
  } else if (splicedSites) {
    filteredSites.push(splicedSites);
  }

  return (
    <div className="site-activity-content">
      <Table fixed className="site-activity-table">
        <TableHead>
          <TableRow>
            {columns.map(({ key, headerComponent: HeaderComponent }) => (
              <TableHeader key={key}>
                <HeaderComponent
                  sites={sites}
                  filteredSites={filteredSitesTotal}
                  filterSitesActive={filterSitesActive}
                />
              </TableHeader>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredSites.map(site => (
            <TableRow key={site.name}>
              {columns.map(({ key, rowComponent: RowComponent }) => (
                <TableCell key={key}>
                  <RowComponent datum={site} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {filteredSitesTotal.length > 4 && (
        <div
          onClick={props.toggleSitesCollapsed}
          className={'table-collapse-footer'}
        >
          {sitesCollapsed ? (
            <>
              {t('site-activity.view-all-active-sites')} (
              {filteredSitesTotal.length}){' '}
              <FontAwesomeIcon icon={faAngleDown} />
            </>
          ) : (
            <>
              {t('site-activity.collapse')} <FontAwesomeIcon icon={faAngleUp} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export { SiteActivity };

const GET_SITE_ACTIVITY = makeAsyncActionSet('GET_SITE_ACTIVITY');

export const mapStateToProps = ({
  filterSitesActive,
  sitesCollapsed,
}: StoreState): StateProps => ({
  filterSitesActive,
  sitesCollapsed,
});

export default compose<Props, {}>(
  createWidget<SiteActivityData>({
    icon: faHospital,
    title: (
      <Trans i18nKey="reports:site-activity.heading">Live Site Activity</Trans>
    ),
    url: '/api/reports/site-activity/',
    actionSet: GET_SITE_ACTIVITY,
    extraHeaderContent: ExtraHeaderContent,
    halfWidth: true,
  }),
  connect(mapStateToProps, { toggleFilterSitesActive, toggleSitesCollapsed }),
  withTranslation('reports')
)(SiteActivity);
