import { anyPending } from '@dabapps/redux-requests';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import LoadingRenderer from '^/common/components/form/loading-renderer';
import { StoreState } from '^/common/types';
import { showModal } from '^/modals/modal-actions';
import { ModalType } from '^/modals/modal-types';
import { GET_ALL_TRIALS, getAllTrials } from '^/trials/trial-actions';
import {
  GET_ALL_TRIALSITES,
  getAllTrialsites,
} from '^/trialsites/trialsite-actions';
import { SelectedTrialsite } from '^/trialsites/trialsite-types';

const actions = [GET_ALL_TRIALSITES, GET_ALL_TRIALS];

interface ConnectedProps {
  isLoading: boolean;
  selectedTrialsite: SelectedTrialsite | null;
}

interface DispatchProps {
  getAllTrials: typeof getAllTrials;
  getAllTrialsites: typeof getAllTrialsites;
  showModal: typeof showModal;
}

export type Props = ConnectedProps & DispatchProps & WithTranslation;

export class TrialSitePicker extends React.PureComponent<Props> {
  public componentWillMount() {
    if (!this.props.selectedTrialsite) {
      this.props.getAllTrialsites();
    }
  }

  public render() {
    const { t, isLoading } = this.props;
    const selectedTrialsite = this.props.selectedTrialsite
      ? this.props.selectedTrialsite.trialsite
      : null;

    if (!selectedTrialsite) {
      if (isLoading) {
        return <LoadingRenderer />;
      }

      return <div>{t('empty-state')}</div>;
    }

    if (!selectedTrialsite.trial_detail) {
      this.props.getAllTrials();
    }

    return (
      <button
        className="trial-site-picker"
        onClick={this.props.showModal.bind(this, {
          [ModalType.SwitchTrial]: {
            show: true,
          },
        })}
      >
        <p className="trial-site-picker-site-name">
          {selectedTrialsite.site_reference}
        </p>
        <div className="trial-site-picker-trial">
          <p className="trial-site-picker-trial-name">
            {selectedTrialsite.trial_detail
              ? selectedTrialsite.trial_detail.global_trial_id
              : selectedTrialsite.trial}
          </p>
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      </button>
    );
  }
}

export const mapStateToProps = ({
  responses,
  selectedTrialsite,
}: StoreState): ConnectedProps => {
  return {
    isLoading: anyPending(responses, actions),
    selectedTrialsite,
  };
};

export default compose<Props, {}>(
  connect(mapStateToProps, {
    getAllTrialsites,
    getAllTrials,
    showModal,
  }),
  withTranslation('trials')
)(TrialSitePicker);
