import { anyPending } from '@dabapps/redux-requests';
import {
  Column,
  ContentBox,
  ContentBoxHeader,
  Row,
  Section,
} from '@dabapps/roe';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';

import PageContainer from '^/common/components/containers/page-container';
import ErrorRenderer from '^/common/components/form/error-renderer';
import LoadingRenderer from '^/common/components/form/loading-renderer';
import { StoreState } from '^/common/types';
import { showModal } from '^/modals/modal-actions';
import { ModalType } from '^/modals/modal-types';
import EditPICInformationForm from '^/pic-information/components/pic-information-form-edit';
import {
  GET_ALL_PIC_INFORMATION,
  getAllPICInformation,
  updatePICInformation,
} from '^/pic-information/pic-information-actions';
import { PICInformation } from '^/pic-information/pic-information-types';

const actions = [GET_ALL_PIC_INFORMATION];

interface DispatchProps {
  updatePICInformation: typeof updatePICInformation;
  getAllPICInformation: typeof getAllPICInformation;
  showModal: typeof showModal;
}

interface ConnectedProps {
  isLoading: boolean;
  allPICInformation: ReadonlyArray<PICInformation>;
}

export type Props = DispatchProps &
  ConnectedProps &
  RouteComponentProps<{ id: string }> &
  WithTranslation;

export class PICInformationPageEdit extends React.PureComponent<Props> {
  public componentDidMount() {
    if (
      !this.props.allPICInformation ||
      this.props.allPICInformation.length <= 0
    ) {
      this.props.getAllPICInformation();
    }
  }

  public render() {
    const { t, allPICInformation, match, isLoading } = this.props;

    const pic = allPICInformation.find(x => x.id === match.params.id);

    const editPICInformationForm = pic ? (
      <EditPICInformationForm
        initialValues={pic}
        onSubmit={this.onUpdatePICInformationSubmit}
        onDelete={this.onDeletePICInformation}
      />
    ) : (
      <Section>
        <p>{t('not-found')}</p>
      </Section>
    );

    return (
      <PageContainer>
        <ContentBox>
          <ContentBoxHeader>
            <Row>
              <Column className="back-to" xs={4}>
                <Link to="/pic">
                  <FontAwesomeIcon icon={faArrowLeft} />{' '}
                  {t('back-to-pic-information')}
                </Link>
              </Column>
              <Column xsFill={4} xs={4} className="text-align-center">
                <h2>{t('cards.edit-pic-information')}</h2>
              </Column>
            </Row>
          </ContentBoxHeader>
          {isLoading ? <LoadingRenderer /> : editPICInformationForm}
          <ErrorRenderer
            actions={actions}
            fields={['non_field_errors', 'detail']}
            showStatusErrors
          />
          <hr />
        </ContentBox>
      </PageContainer>
    );
  }

  private onUpdatePICInformationSubmit = (values: PICInformation) => {
    if (values.id) {
      this.props.updatePICInformation(values.id, values);
    }
  };

  private onDeletePICInformation = (picId: string) => {
    this.props.showModal({
      [ModalType.DeletePICInformation]: {
        show: true,
        picId,
      },
    });
  };
}

export const mapStateToProps = ({
  responses,
  allPICInformation,
}: StoreState): ConnectedProps => {
  return {
    isLoading: anyPending(responses, actions),
    allPICInformation,
  };
};

export default compose<Props, {}>(
  connect(mapStateToProps, {
    updatePICInformation,
    getAllPICInformation,
    showModal,
  }),
  withTranslation('pic')
)(PICInformationPageEdit);
