import { Column } from '@dabapps/roe';
import React from 'react';

import LoadingSpinner from '^/common/components/loading-spinner';
import { Props as WidgetProps } from '.';
import Visualization from './visualization';

type ErrorNoDataOrComponentKeys =
  | 'loading'
  | 'error'
  | 'data'
  | 'comparisonError'
  | 'comparisonData'
  | 'isComparison'
  | 'component';

export type Props<ReportData> = Pick<
  WidgetProps<ReportData>,
  ErrorNoDataOrComponentKeys
>;

// tslint:disable-next-line:no-any
const VisualizationsWithLoading = <ReportData extends any>({
  loading,
  error,
  data,
  comparisonError,
  comparisonData,
  isComparison,
  component,
}: Props<ReportData>) => {
  if (loading) {
    return (
      <Column>
        <LoadingSpinner />
      </Column>
    );
  }

  if (isComparison) {
    return (
      <>
        <Column sm={6} className="comparison-left border-right">
          <Visualization
            error={error}
            data={data}
            isComparison={isComparison}
            component={component}
          />
        </Column>
        <Column sm={6} className="comparison-right">
          <Visualization
            error={comparisonError}
            data={comparisonData}
            isComparison={isComparison}
            component={component}
          />
        </Column>
      </>
    );
  }

  return (
    <Column>
      <Visualization
        error={error}
        data={data}
        isComparison={isComparison}
        component={component}
      />
    </Column>
  );
};

export default VisualizationsWithLoading;
