import { makeAsyncActionSet } from '@dabapps/redux-requests/dist/js';
import { Alert } from '@dabapps/roe';
import * as React from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import {
  createWidget,
  InjectedWidgetProps,
} from '^/reports/components/hoc/create-widget';

interface ReportAccuracyWarningData {
  report_accurate: boolean;
  history_start_date: string;
}

type Props = InjectedWidgetProps<ReportAccuracyWarningData> & WithTranslation;

export const MaybeReportAccuracyWarning = ({
  t,
  data: { report_accurate, history_start_date },
}: Props) => {
  if (report_accurate) {
    return (
      <Alert className="success">
        <p>
          {t('reports:data-accuracy.report-only-after', { history_start_date })}
        </p>
      </Alert>
    );
  }

  return (
    <Alert className="warning">
      <p>
        {t('reports:data-accuracy.patient-tracking-from', {
          history_start_date,
        })}
      </p>
    </Alert>
  );
};

const REPORT_ACCURACY = makeAsyncActionSet('REPORT_ACCURACY');

export default compose<Props, {}>(
  createWidget<ReportAccuracyWarningData>({
    icon: null,
    url: '/api/reports/is-report-accurate/',
    actionSet: REPORT_ACCURACY,
    halfWidth: false,
    title: () => (
      <Trans i18nKey="reports:data-accuracy.heading">
        <h2>Data Accuracy</h2>
      </Trans>
    ),
    shouldHide: (data: ReportAccuracyWarningData) => data.report_accurate,
    hideWhileLoading: true,
  }),
  withTranslation('reports')
)(MaybeReportAccuracyWarning);
