import moment from 'moment';
import React from 'react';
import { Trans } from 'react-i18next';

import { DATE_FORMAT_DISPLAY } from '^/common/constants';
import { Column } from './types';

const NBSP = '\u00a0';

export const SINGLE_COLUMNS: ReadonlyArray<Column> = [
  {
    key: 'name',
    headerComponent: ({ sites, filteredSites, filterSitesActive }) => {
      return (
        <>
          <h1>
            {filteredSites.length}
            {Boolean(sites.length) && (
              <sup>
                ({Math.round((filteredSites.length / sites.length) * 100)}
                %)
              </sup>
            )}
          </h1>
          <p>
            <Trans
              i18nKey={`reports:site-activity.fields.${
                filterSitesActive ? 'Active' : 'Inactive'
              }`}
            >
              {filterSitesActive ? 'Active' : 'Inactive'} Sites
            </Trans>
          </p>
        </>
      );
    },
    rowComponent: ({ datum }) => <p>{datum.name}</p>,
  },
  {
    key: 'identified',
    headerComponent: ({ filteredSites }) => {
      const totalIdentified = filteredSites.reduce(
        (memo, site) => memo + site.identified,
        0
      );

      return (
        <>
          <h1>{totalIdentified}</h1>
          <p>
            <Trans i18nKey="reports:site-activity.fields.potentials-identified">
              Potentials Identified
            </Trans>
          </p>
        </>
      );
    },
    rowComponent: ({ datum }) => <p>{datum.identified}</p>,
  },
  {
    key: 'screenings',
    headerComponent: ({ filteredSites }) => {
      const totalScreenings = filteredSites.reduce(
        (memo, site) => memo + site.screenings,
        0
      );

      return (
        <>
          <h1>{totalScreenings}</h1>
          <p>
            <Trans i18nKey="reports:site-activity.fields.screenings-booked">
              Screenings Booked
            </Trans>
          </p>
        </>
      );
    },
    rowComponent: ({ datum }) => <p>{datum.screenings}</p>,
  },
  {
    key: 'updated',
    headerComponent: () => {
      return (
        <>
          <h1>{NBSP}</h1>
          <p>
            <Trans i18nKey="reports:site-activity.fields.last-updated">
              Last Updated
            </Trans>
          </p>
        </>
      );
    },
    rowComponent: ({ datum }) => (
      <p>{moment.utc(datum.updated).format(DATE_FORMAT_DISPLAY)}</p>
    ),
  },
];

export const COMPARISON_COLUMNS: ReadonlyArray<Column> = SINGLE_COLUMNS.filter(
  ({ key }) => key !== 'screenings'
);
