import { anyPending } from '@dabapps/redux-requests';
import {
  Button,
  ContentBoxHeader,
  Modal,
  ModalBody,
  ModalHeader,
} from '@dabapps/roe';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { SubmissionError } from 'redux-form';

import ErrorRenderer from '^/common/components/form/error-renderer';
import InputFilter from '^/common/components/form/input-filter';
import LoadingRenderer from '^/common/components/form/loading-renderer';
import PaginationBar from '^/common/components/pagination-bar';
import { PAGE_SIZE } from '^/common/constants';
import { StoreState } from '^/common/types';
import { closeModal, openModal } from '^/modals/modal-actions';
import { Trial } from '^/trials/trial-types';
import TrialsiteForm from '^/trialsites/components/trialsite-form';
import {
  CREATE_TRIALSITE,
  createTrialsite,
  GET_TRIALSITE_BY_TRIAL,
  getTrialsitesByTrial,
  setSiteSearchTerm,
} from '^/trialsites/trialsite-actions';
import { Trialsite } from '^/trialsites/trialsite-types';
import TrialsiteListRow from './trialsite-list-row';

interface ConnectedProps {
  isLoading: boolean;
  siteSearchTerm: string;
  trialsitesByTrialCount: number;
  trialsitesByTrialResults: ReadonlyArray<Trialsite> | undefined;
}

interface DispatchProps {
  getTrialsitesByTrial: typeof getTrialsitesByTrial;
  openModal: typeof openModal;
  closeModal: typeof closeModal;
  setSiteSearchTerm: typeof setSiteSearchTerm;
}

interface OwnProps {
  trial: Trial;
}

interface State {
  page: number;
}

export type TrialsiteSitesListProps = ConnectedProps &
  DispatchProps &
  OwnProps &
  WithTranslation;

const actions = [GET_TRIALSITE_BY_TRIAL];

export class TrialsitesSitesList extends React.PureComponent<
  TrialsiteSitesListProps,
  State
> {
  constructor(props: TrialsiteSitesListProps) {
    super(props);

    this.state = {
      page: 1,
    };
    this.onChangePage = this.onChangePage.bind(this);
  }

  public componentDidMount() {
    this.props.getTrialsitesByTrial(this.props.trial.id, this.state.page);
  }

  public componentDidUpdate(
    prevProps: TrialsiteSitesListProps,
    prevState: State
  ) {
    const { page } = this.state;
    const { siteSearchTerm } = this.props;

    if (siteSearchTerm !== prevProps.siteSearchTerm && page !== 1) {
      this.setState({ page: 1 });
    } else if (
      page !== prevState.page ||
      siteSearchTerm !== prevProps.siteSearchTerm
    ) {
      this.props.getTrialsitesByTrial(
        this.props.trial.id,
        page,
        siteSearchTerm
      );
    }
  }

  public render() {
    const {
      t,
      isLoading,
      trialsitesByTrialCount,
      trialsitesByTrialResults,
    } = this.props;

    const { page } = this.state;

    return (
      <div className="trialsite-list">
        <ContentBoxHeader>
          <h2>{t('trialsites:headings.sites')}</h2>
          <Button
            className="primary pill"
            id="add-new-trialsite"
            onClick={this.onClickAddNewTrialsite}
            small
          >
            {t('buttons.add-sites-to-trial')}
          </Button>
          <InputFilter type="Site" />
        </ContentBoxHeader>
        <hr />
        <hr />
        <div>
          {trialsitesByTrialResults &&
            trialsitesByTrialResults.map(trialsite => (
              <div>
                <TrialsiteListRow
                  key={trialsite.id ? trialsite.id : trialsite.site_reference}
                  page={page}
                  trialsite={trialsite}
                />
                <hr />
              </div>
            ))}
        </div>
        {isLoading && <LoadingRenderer />}
        <ErrorRenderer
          actions={[GET_TRIALSITE_BY_TRIAL]}
          fields={['non_field_errors', 'detail']}
          showStatusErrors
        />
        <PaginationBar
          changePage={this.onChangePage}
          currentPageNumber={page}
          itemCount={trialsitesByTrialCount}
          pageSize={PAGE_SIZE}
        />
      </div>
    );
  }

  public onChangePage(value: number) {
    this.setState({ page: value });
  }

  public onClickAddNewTrialsite = () => {
    const { t } = this.props;

    this.props.openModal(
      <Modal onClickOutside={this.props.closeModal}>
        <ModalHeader>
          <h1>{t('trialsites:headings.add-site-to-trial')}</h1>
          <button
            className="card-header-button"
            id="close-modal"
            onClick={this.props.closeModal}
          >
            <FontAwesomeIcon
              className="trialsite-close-button"
              size="1x"
              icon={faTimes}
            />
          </button>
        </ModalHeader>
        <ModalBody>
          <TrialsiteForm
            initialValues={{ trial: this.props.trial.id, country: 'GB' }}
            actions={[CREATE_TRIALSITE]}
            onSubmit={this.handleCreateTrialsite}
            onCancel={this.props.closeModal}
          />
        </ModalBody>
      </Modal>
    );
  };

  public handleCreateTrialsite = async (
    trialsite: Trialsite,
    dispatch: Dispatch
  ) => {
    try {
      await createTrialsite(trialsite, this.state.page)(dispatch);
      this.props.closeModal();
      this.props.setSiteSearchTerm('');
    } catch (errors) {
      throw new SubmissionError(
        errors.response ? errors.response.data : undefined
      );
    }
  };
}

export { TrialsitesSitesList as TestableTrialsitesSitesList };

export const mapStateToProps = ({
  responses,
  siteSearchTerm,
  trialsitesByTrialList,
}: StoreState): ConnectedProps => {
  return {
    siteSearchTerm,
    isLoading: anyPending(responses, actions),
    trialsitesByTrialCount: trialsitesByTrialList?.count,
    trialsitesByTrialResults: trialsitesByTrialList?.results,
  };
};

export default withTranslation(['trials', 'trialsites'])(
  connect(mapStateToProps, {
    getTrialsitesByTrial,
    setSiteSearchTerm,
    openModal,
    closeModal,
  })(TrialsitesSitesList)
);
