import { Button } from '@dabapps/roe';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { InjectedFormProps, reduxForm } from 'redux-form';

import ErrorRenderer from '^/common/components/form/error-renderer';
import PICInformationFormGroups, {
  validate,
} from '^/pic-information/components/pic-information-form-groups';
import { UPDATE_PIC_INFORMATION } from '^/pic-information/pic-information-actions';
import { PICInformation } from '^/pic-information/pic-information-types';

const actions = [UPDATE_PIC_INFORMATION];

interface OwnProps {
  onSubmit?: (values: PICInformation) => void;
  onDelete: (id: string) => void;
}

export type Props = OwnProps &
  InjectedFormProps<PICInformation, OwnProps> &
  WithTranslation;

export class EditPICInformationForm extends React.Component<Props> {
  public render() {
    const { t, handleSubmit, submitting, pristine } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <hr />
        <PICInformationFormGroups />
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors']}
          showStatusErrors
        />
        <hr />
        <div className="form-buttons">
          <Button
            type="submit"
            className="primary pill"
            disabled={submitting || pristine}
          >
            {t('buttons.save')}
          </Button>
          <Button
            type="button"
            onClick={this.handleDeleteClick}
            className="error pill hollow"
            disabled={submitting}
          >
            {t('buttons.delete')}
          </Button>
        </div>
      </form>
    );
  }

  public handleDeleteClick = () => {
    if (this.props.initialValues.id) {
      this.props.onDelete(this.props.initialValues.id);
    }
  };
}

export default reduxForm<PICInformation, OwnProps>({
  form: 'editPICInformationForm',
  destroyOnUnmount: true,
  validate,
})(withTranslation('pic')(EditPICInformationForm));
