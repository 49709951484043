import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default class AnimatedAddIcon extends React.PureComponent {
  public render() {
    return (
      <FontAwesomeIcon className="add-animated-icon" size="2x" icon={faPlus} />
    );
  }
}
