import { mockEndpoints } from '^/mock-endpoints';
import axios, { AxiosRequestConfig } from '__REAL_AXIOS__';

const returnMockData = (url: string) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({ data: mockEndpoints[url] });
    }, 200 + Math.random() * 500);
  });
};

const mockAxios = (
  url: string | AxiosRequestConfig,
  config?: AxiosRequestConfig
) => {
  if (typeof url === 'string' && url in mockEndpoints) {
    return returnMockData(url);
  }

  if (
    typeof url === 'object' &&
    typeof url.url === 'string' &&
    url.url in mockEndpoints
  ) {
    return returnMockData(url.url);
  }

  if (typeof url === 'string') {
    return axios(url, config);
  }

  return axios(url);
};

export default mockAxios;
