import { Nav, NavBar } from '@dabapps/roe';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import LoginButton from '^/login/components/login-button';

interface Props {
  showLogin?: boolean;
}

export class NovoNavigation extends React.PureComponent<Props> {
  public static defaultProps: Partial<Props> = {
    showLogin: true,
  };

  public render() {
    return (
      <NavBar>
        <Nav className="logo">
          <Link to="/">
            <img src="/static/logo.svg" />
          </Link>
        </Nav>
        {this.props.children}
        <Nav className={classNames('login', 'float-right')}>
          {this.props.showLogin && <LoginButton />}
        </Nav>
      </NavBar>
    );
  }
}

export default NovoNavigation;
