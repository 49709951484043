import { anyPending } from '@dabapps/redux-requests';
import { Modal, ModalBody, ModalHeader, Section } from '@dabapps/roe';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ErrorRenderer from '^/common/components/form/error-renderer';
import InputFilter from '^/common/components/form/input-filter';
import LoadingRenderer from '^/common/components/form/loading-renderer';
import { StoreState } from '^/common/types';
import { hideModal } from '^/modals/modal-actions';
import { ModalType } from '^/modals/modal-types';
import { UserRoles } from '^/roles/role-types';
import AddUserCard from '^/trialsites/components/trialsite-add-user-card';
import {
  CREATE_SINGLE_TRIALSITE_USER,
  createTrialsiteUsers,
  GET_TRIALSITE_BY_TRIAL,
  inviteAndCreateTrialsiteUser,
  removeAllPendingTrialsiteUsers,
} from '^/trialsites/trialsite-actions';
import {
  PendingTrialsiteUser,
  TrialsiteUser,
} from '^/trialsites/trialsite-types';
import InviteUserForm from '^/users/components/user-form-invite';
import { getAllUsers } from '^/users/user-actions';
import { User } from '^/users/user-types';

const actions = [GET_TRIALSITE_BY_TRIAL, CREATE_SINGLE_TRIALSITE_USER];

interface ConnectedProps {
  isLoading: boolean;
  trialsiteId: string | undefined;
  siteName: string | undefined;
  userList: ReadonlyArray<User>;
  pendingTrialsiteUsers: ReadonlyArray<PendingTrialsiteUser>;
  trialsiteUserList: { [trialsiteId: string]: ReadonlyArray<TrialsiteUser> };
  trialsiteSiteSearchTerm: string;
}

interface DispatchProps {
  createTrialsiteUsers: typeof createTrialsiteUsers;
  inviteAndCreateTrialsiteUser: typeof inviteAndCreateTrialsiteUser;
  getAllUsers: typeof getAllUsers;
  hideModal: typeof hideModal;
  removeAllPendingTrialsiteUsers: typeof removeAllPendingTrialsiteUsers;
}

export type Props = ConnectedProps & DispatchProps & WithTranslation;

export class TrialsiteAddSiteModal extends React.PureComponent<Props> {
  public render() {
    const { t, isLoading, trialsiteId, userList, siteName } = this.props;
    if (!userList || userList.length <= 0) {
      this.props.getAllUsers();
    }

    if (!(trialsiteId && siteName)) {
      return (
        <Modal onClickOutside={this.onCancel}>
          <LoadingRenderer />
        </Modal>
      );
    }

    const filteredUserList = userList.filter(user =>
      this.shouldShowUser(user, trialsiteId)
    );

    return (
      <Modal
        onClickOutside={this.onCancel}
        className="add-site-trialsite-modal"
      >
        <ModalHeader>
          <div className="header-info">
            <h1>{t('add-site-users-to-trial-site-heading')}</h1>
            <p>{siteName}</p>
          </div>
          <InputFilter type="Trialsite-site" />
          <button
            className="card-header-button"
            id="close-modal-site"
            onClick={this.onCancel}
          >
            <FontAwesomeIcon
              className="add-site-trialsite-close-button"
              size="1x"
              icon={faTimes}
            />
          </button>
        </ModalHeader>
        <hr />
        <ModalBody className="buttons">
          {filteredUserList.length > 0 ? (
            <div className="inviteForm">
              <InviteUserForm
                onSubmit={this.onSaveUsers.bind(this, trialsiteId)}
              />
            </div>
          ) : (
            <div className="inviteForm">
              <Section>
                <p>{t('users:empty-state')}</p>
              </Section>
              <InviteUserForm
                onSubmit={this.onSaveUsers.bind(this, trialsiteId)}
              />
            </div>
          )}
          {filteredUserList.map(user => (
            <AddUserCard key={user.id} user={user} trialsiteId={trialsiteId} />
          ))}
        </ModalBody>
        {isLoading && <LoadingRenderer />}
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors', 'detail']}
          showStatusErrors
        />
        <hr />
      </Modal>
    );
  }

  private onCancel = () => {
    this.props.hideModal(ModalType.AddSiteUserToTrialsite);
    this.props.removeAllPendingTrialsiteUsers();
  };

  private onSaveUsers(trialsiteId: string, values: User) {
    if (values.email || values.full_name) {
      const invitedUser = { ...values, roles: UserRoles.SITE };

      this.props.inviteAndCreateTrialsiteUser(
        invitedUser,
        this.props.pendingTrialsiteUsers,
        trialsiteId,
        ModalType.AddSiteUserToTrialsite
      );
    } else {
      this.props.createTrialsiteUsers(
        this.props.pendingTrialsiteUsers,
        trialsiteId,
        ModalType.AddSiteUserToTrialsite
      );
    }
  }

  private shouldShowUser = (user: User, trialsiteId: string) => {
    if (user.roles !== UserRoles.SITE) {
      return false;
    }

    if (
      this.props.trialsiteUserList[trialsiteId].some(x => x.user === user.id)
    ) {
      return false;
    }

    if (this.props.pendingTrialsiteUsers.some(x => x.userId === user.id)) {
      return true;
    }

    return (
      user.full_name
        .toLowerCase()
        .includes(this.props.trialsiteSiteSearchTerm.toLowerCase()) ||
      user.email
        .toLowerCase()
        .includes(this.props.trialsiteSiteSearchTerm.toLowerCase())
    );
  };
}

export const mapStateToProps = ({
  responses,
  sharedModals: modals,
  userList,
  pendingTrialsiteUsers,
  trialsiteUserList,
  trialsiteSiteSearchTerm,
}: StoreState): ConnectedProps => {
  const trialsiteId = modals[ModalType.AddSiteUserToTrialsite]
    ? modals[ModalType.AddSiteUserToTrialsite].trialsiteId
    : undefined;
  const siteName = modals[ModalType.AddSiteUserToTrialsite]
    ? modals[ModalType.AddSiteUserToTrialsite].siteName
    : undefined;
  return {
    isLoading: anyPending(responses, actions),
    trialsiteId,
    siteName,
    userList,
    pendingTrialsiteUsers,
    trialsiteUserList,
    trialsiteSiteSearchTerm,
  };
};

export default withTranslation('trials')(
  connect(mapStateToProps, {
    hideModal,
    getAllUsers,
    inviteAndCreateTrialsiteUser,
    createTrialsiteUsers,
    removeAllPendingTrialsiteUsers,
  })(TrialsiteAddSiteModal)
);
