import { AnyAction } from '^/common/types';
import { GET_LOGGED_IN_USER, LOGOUT } from '^/login/login-actions';
import { UserRoles } from '^/roles/role-types';
import { User, UserRecord } from '^/users/user-types';

export function loggedInUser(
  state: User | null = null,
  action: AnyAction
): User | null {
  switch (action.type) {
    case GET_LOGGED_IN_USER.SUCCESS:
      return UserRecord(action.payload.data);
    case LOGOUT.SUCCESS:
      return null;
    default:
      return state;
  }
}

export function isAdmin(state: boolean = false, action: AnyAction): boolean {
  switch (action.type) {
    case GET_LOGGED_IN_USER.SUCCESS:
      return UserRecord(action.payload.data).roles === UserRoles.OpsAdmin;
    case LOGOUT.SUCCESS:
      return false;
    default:
      return state;
  }
}
