export * from '^/reports/components/hoc/create-widget/types';

import {
  dispatchGenericRequest,
  getErrorData,
  isPending,
} from '@dabapps/redux-requests';
import { Column, ContentBox, ContentBoxHeader, Row } from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import queryString from 'query-string';
import React, { ComponentType, PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import { createSelector } from 'reselect';

import { AnyAction, StoreState } from '^/common/types';
import {
  InjectedWidgetProps,
  WidgetOptions,
} from '^/reports/components/hoc/create-widget/types';
import {
  getTruthyStringParams,
  intersection,
  pick,
  values,
} from '^/reports/components/hoc/create-widget/utils';
import { FIELDS } from '^/reports/report-constants';
import { getReport } from '^/reports/reports-utils';
import VisualizationsWithLoading from './visualizations-with-loading';

export const TAG_REPORT_1 = 'report-1';
export const TAG_REPORT_2 = 'report-2';

interface StateProps<ReportData> extends WidgetOptions<ReportData> {
  data: ReportData | undefined;
  comparisonData: ReportData | undefined;
  loading: boolean;
  component: ComponentType<InjectedWidgetProps<ReportData>>;
  error: AxiosError | null;
  comparisonError: AxiosError | null;
  params: Record<string, string | undefined>;
  isComparison: boolean;
  shouldHide?: (data: ReportData) => boolean;
  hideWhileLoading?: boolean;
}

interface DispatchProps {
  getReport: (data: Record<string, string | undefined>, tag: string) => void;
}

type RouteProps = RouteComponentProps<{}>;

export type Props<ReportData> = StateProps<ReportData> &
  DispatchProps &
  RouteProps;

export class Widget<ReportData> extends PureComponent<Props<ReportData>> {
  public componentDidMount() {
    this.requestReports();
  }

  public componentDidUpdate(prevProps: Props<ReportData>) {
    if (prevProps.location.search !== this.props.location.search) {
      this.requestReports();
    }
  }

  public render() {
    const {
      data,
      error,
      comparisonData,
      comparisonError,
      loading,
      icon,
      component,
      halfWidth,
      extraHeaderContent: ExtraHeaderContent,
      title: Title,
    } = this.props;
    const { isComparison, shouldHide, hideWhileLoading } = this.props;

    if (hideWhileLoading && loading) {
      return null;
    }

    if (shouldHide) {
      if (data && shouldHide(data) && !comparisonData) {
        return null;
      }

      if (
        data &&
        comparisonData &&
        shouldHide(data) &&
        shouldHide(comparisonData)
      ) {
        return null;
      }
    }

    return (
      <Column xs={!isComparison && halfWidth ? 6 : 12} className="display-flex">
        <ContentBox component="section" className="widget">
          <ContentBoxHeader>
            <Row>
              <Column xs={6}>
                {icon && (
                  <FontAwesomeIcon
                    icon={icon}
                    size="2x"
                    className="secondary"
                  />
                )}
                {typeof Title === 'function' ? (
                  <Title
                    data={data}
                    comparisonData={comparisonData}
                    isComparison={isComparison}
                    loading={loading}
                  />
                ) : (
                  <h2>{Title}</h2>
                )}
              </Column>
              {ExtraHeaderContent && (
                <Column xs={6}>
                  <span className="float-right">
                    {typeof ExtraHeaderContent === 'function' ? (
                      <ExtraHeaderContent
                        data={data}
                        comparisonData={comparisonData}
                        isComparison={isComparison}
                        loading={loading}
                      />
                    ) : (
                      ExtraHeaderContent
                    )}
                  </span>
                </Column>
              )}
            </Row>
          </ContentBoxHeader>
          <Row className="content">
            <VisualizationsWithLoading
              loading={loading}
              error={error}
              data={data}
              comparisonError={comparisonError}
              comparisonData={comparisonData}
              isComparison={isComparison}
              component={component}
            />
          </Row>
        </ContentBox>
      </Column>
    );
  }

  private requestReports() {
    this.props.getReport(
      pick(this.props.params, values(FIELDS.SINGLE)),
      TAG_REPORT_1
    );

    if (this.props.isComparison) {
      const {
        comparison_trial,
        comparison_site,
        comparison_start_date,
        comparison_end_date,
      } = pick(this.props.params, values(FIELDS.COMPARISON));

      this.props.getReport(
        {
          trial: comparison_trial,
          site: comparison_site,
          start_date: comparison_start_date,
          end_date: comparison_end_date,
        },
        TAG_REPORT_2
      );
    }
  }
}

export const paramsSelector = createSelector(
  (props: RouteProps) => props.location.search,
  search => getTruthyStringParams(queryString.parse(search))
);

export const isComparisonSelector = createSelector(
  (params: Props<{}>['params']) => params,
  params =>
    intersection(Object.keys(params), values(FIELDS.COMPARISON)).length > 0
);

// tslint:disable-next-line:no-any
const createWidget = <ReportData extends any>(
  options: WidgetOptions<ReportData>
) => (component: ComponentType<InjectedWidgetProps<ReportData>>) => {
  const mapStateToProps = (
    state: StoreState,
    props: RouteProps
  ): StateProps<ReportData> => {
    const params = paramsSelector(props);
    const isComparison = isComparisonSelector(params);

    return {
      ...options,
      params,
      isComparison,
      loading:
        isPending(state.responses, options.actionSet, TAG_REPORT_1) ||
        isPending(state.responses, options.actionSet, TAG_REPORT_2),
      component,
      data: getReport(state, options.actionSet, TAG_REPORT_1),
      comparisonData: getReport(state, options.actionSet, TAG_REPORT_2),
      error: getErrorData(state.responses, options.actionSet, TAG_REPORT_1),
      comparisonError: getErrorData(
        state.responses,
        options.actionSet,
        TAG_REPORT_2
      ),
    };
  };

  const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, undefined, AnyAction>
  ) => ({
    getReport: (data: Record<string, string | undefined>, tag: string) =>
      dispatch(
        dispatchGenericRequest(options.actionSet, options.url, 'GET', data, tag)
      ),
  });

  return withRouter(
    connect<StateProps<ReportData>, DispatchProps, RouteProps, StoreState>(
      mapStateToProps,
      mapDispatchToProps
    )(Widget)
  );
};

export { createWidget };
