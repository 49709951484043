import { LOCATION_CHANGE } from 'connected-react-router';

import { AnyAction } from '^/common/types';
import { LOGOUT } from '^/login/login-actions';
import {
  CREATE_TRIAL,
  GET_ALL_TRIALS,
  SET_TRIAL_SEARCH_TERM,
  TOGGLE_ADD_TRIAL,
} from '^/trials/trial-actions';
import { Trial, TrialRecord } from '^/trials/trial-types';

export function trialList(state: ReadonlyArray<Trial> = [], action: AnyAction) {
  switch (action.type) {
    case GET_ALL_TRIALS.SUCCESS:
      if (!Array.isArray(action.payload.data)) {
        return state;
      }

      return action.payload.data.map(TrialRecord);
    case LOGOUT.SUCCESS:
      return [];
    default:
      return state;
  }
}

export function trialSearchTerm(
  state: string | null = null,
  action: AnyAction
) {
  switch (action.type) {
    case SET_TRIAL_SEARCH_TERM:
      return action.payload;
    case LOGOUT.SUCCESS:
      return '';
    default:
      return state;
  }
}

export function addTrialExpanded(
  state: boolean = false,
  action: AnyAction
): boolean {
  switch (action.type) {
    case TOGGLE_ADD_TRIAL:
      if (typeof action.payload === 'boolean') {
        return action.payload;
      }

      return !state;
    case CREATE_TRIAL.SUCCESS:
    case LOCATION_CHANGE:
      return false;
    case LOGOUT.SUCCESS:
      return false;
    default:
      return state;
  }
}
