import { anyPending } from '@dabapps/redux-requests';
import { Container } from '@dabapps/roe';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import ErrorRenderer from '^/common/components/form/error-renderer';
import LoadingRenderer from '^/common/components/form/loading-renderer';
import NovoNavigation from '^/common/components/navigation/novo-navigation';
import { StoreState } from '^/common/types';
import { GET_LOGGED_IN_USER, getLoggedInUser } from '^/login/login-actions';
import { UserRoles } from '^/roles/role-types';
import { User } from '^/users/user-types';

interface DispatchProps {
  getLoggedInUser: typeof getLoggedInUser;
}

interface ConnectedProps {
  isLoading: boolean;
  loggedInUser: User;
}

export type Props = ConnectedProps & DispatchProps;

export class HomePage extends React.PureComponent<Props> {
  public componentDidMount() {
    if (
      this.props.loggedInUser.email !== 'AnonymousUser' &&
      !this.props.loggedInUser.roles
    ) {
      this.props.getLoggedInUser();
    }
  }

  public render() {
    return (
      <Container>
        <NovoNavigation showLogin={false}>
          {this.props.isLoading ? (
            <LoadingRenderer />
          ) : (
            <ErrorRenderer
              actions={[GET_LOGGED_IN_USER]}
              fields={['non_field_errors', 'detail']}
              showStatusErrors
            />
          )}
        </NovoNavigation>
        {this.getRedirect(this.props.loggedInUser)}
      </Container>
    );
  }

  private getRedirect(user: User): ReactNode | null {
    if (!user || user.email === 'AnonymousUser') {
      return (
        <div>
          Redirecting to login...
          <Redirect to="/login" />
        </div>
      );
    }

    if (user.roles) {
      switch (user.roles) {
        case UserRoles.LTM:
        case UserRoles.CRA:
        case UserRoles.OpsAdmin:
          return (
            <div>
              Redirecting to home...
              <Redirect to="/home" />
            </div>
          );
        default:
          return (
            <div>
              Redirecting to patients...
              <Redirect to="/patients" />
            </div>
          );
      }
    }

    return null;
  }
}

export const mapStateToProps = ({
  loggedInUser,
  responses,
}: StoreState): ConnectedProps => {
  return {
    loggedInUser,
    isLoading: anyPending(responses, [GET_LOGGED_IN_USER]),
  };
};

export default connect(mapStateToProps, { getLoggedInUser })(HomePage);
