import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import MDSpinner from 'react-md-spinner';

const LoadingSpinner = ({ t }: WithTranslation) => (
  <div className="loader">
    <MDSpinner size={16} singleColor="#00a4e4" />
    <span>{t('loading')}</span>
  </div>
);

export { LoadingSpinner };

export default withTranslation()(LoadingSpinner);
