import { Button } from '@dabapps/roe';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { InjectedFormProps, reduxForm } from 'redux-form';

import ErrorRenderer from '^/common/components/form/error-renderer';
import PICInformationFormGroups, {
  validate,
} from '^/pic-information/components/pic-information-form-groups';
import { CREATE_PIC_INFORMATION } from '^/pic-information/pic-information-actions';
import { PICInformationInput } from '^/pic-information/pic-information-types';

const actions = [CREATE_PIC_INFORMATION];

interface OwnProps {
  onSubmit?: (values: PICInformationInput) => void;
  trialsite: string;
}

type Props = OwnProps &
  InjectedFormProps<PICInformationInput, OwnProps> &
  WithTranslation;

export class CreatePICInformationForm extends React.Component<Props> {
  public render() {
    const { t, handleSubmit, submitting, pristine } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <hr />
        <PICInformationFormGroups />
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors']}
          showStatusErrors
        />
        <hr />
        <div className="form-buttons">
          <Button
            type="submit"
            className="primary pill"
            disabled={submitting || pristine}
          >
            {t('buttons.add-new-pic-info')}
          </Button>
        </div>
      </form>
    );
  }
}

export default reduxForm<PICInformationInput, OwnProps>({
  form: 'createPICInformationForm',
  destroyOnUnmount: true,
  validate,
})(withTranslation('pic')(CreatePICInformationForm));
