import { TableCell, TableRow } from '@dabapps/roe';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

import { Trial } from '^/trials/trial-types';

const TRIAL_DATE_FORMAT = 'DD MMM YYYY';

export interface Props {
  allowEdit?: boolean;
  trial: Trial;
}

export class TrialRow extends React.PureComponent<Props> {
  public render() {
    const { allowEdit = false, trial } = this.props;

    return (
      <TableRow>
        <TableCell>{trial.global_trial_id}</TableCell>
        <TableCell>
          {trial.fpfv_planned
            ? trial.fpfv_planned.format(TRIAL_DATE_FORMAT)
            : ''}
        </TableCell>
        <TableCell>
          {trial.lpfv_planned
            ? trial.lpfv_planned.format(TRIAL_DATE_FORMAT)
            : ''}
        </TableCell>
        <TableCell>{trial.patients_recruited}</TableCell>
        {allowEdit && (
          <TableCell className="edit">
            <Link to={'/trials/' + trial.id}>
              <FontAwesomeIcon size="2x" icon={faEdit} />
            </Link>
          </TableCell>
        )}
      </TableRow>
    );
  }
}

export default TrialRow;
