import { Button, Container, ContentBox, ContentBoxHeader } from '@dabapps/roe';
import queryString from 'query-string';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';

import Footer from '^/common/components/containers/footer/footer';
import NovoNavigation from '^/common/components/navigation/novo-navigation';
import LoginForm from '^/login/components/login-form';
import { FormValues } from '^/login/components/login-form';
import { login } from '^/login/login-actions';

interface DispatchProps {
  login: typeof login;
}

export type LoginPageProps = DispatchProps &
  RouteComponentProps<{ next?: string }> &
  WithTranslation;

class LoginPage extends React.PureComponent<LoginPageProps> {
  public handleLogin = (values: FormValues) => {
    this.props.login(values, this.getRedirect());
  };

  public render() {
    const { t } = this.props;

    const params = queryString.parse(this.props.location.search);

    const redirect = this.getRedirect();

    return (
      <Container>
        <NovoNavigation showLogin={false} />
        <ContentBox>
          <ContentBoxHeader>
            <h2>{t('login-heading')}</h2>
          </ContentBoxHeader>
          <div className="login-options">
            <div className="login-option">
              <h6>{t('exostar-login-heading')}</h6>
              <p>{t('exostar-login-description')}</p>
              <div className="button-wrapper">
                <a href={`/login/exostar?next=${redirect}`}>
                  <Button className="primary">
                    {t('buttons.login-with-exostar')}
                  </Button>
                </a>
                {params.fail_reason && (
                  <p className="error">
                    {t('exostar-login-failed')}:{' '}
                    {t(
                      `exostar-fail-reasons.${params.fail_reason}`,
                      params.fail_reason
                    )}
                  </p>
                )}
              </div>
            </div>
            <div className="login-option">
              <h6>{t('legacy-login-heading')}</h6>
              <p>{t('legacy-login-description')}</p>
              <LoginForm onSubmit={this.handleLogin} />
            </div>
          </div>
        </ContentBox>
        <Footer />
      </Container>
    );
  }

  public getRedirect = () => {
    const params = queryString.parse(this.props.location.search);

    if (typeof params.next === 'string') {
      return params.next;
    }

    return '/';
  };
}

export { LoginPage as TestableLoginPage };

export default compose<LoginPageProps, {}>(
  connect(undefined, { login }),
  withTranslation('login')
)(LoginPage);
