import { Button, FormGroup } from '@dabapps/roe';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import ErrorRenderer from '^/common/components/form/error-renderer';
import RenderField from '^/common/components/form/render-field';
import { RESET_PASSWORD_CONFIRM } from '^/password-reset/password-reset-actions';

const actions = [RESET_PASSWORD_CONFIRM];

export interface FormValues {
  email?: string;
}

export type FormErrors = Partial<FormValues>;

interface OwnProps {
  onSubmit: (values: FormValues) => void;
}

type Props = OwnProps &
  InjectedFormProps<FormValues, OwnProps> &
  WithTranslation;

export const validate = (values: FormValues) => {
  const errors: FormErrors = {};

  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  return errors;
};

export class PasswordResetForm extends React.Component<Props> {
  public render() {
    const { t, handleSubmit, submitting, pristine } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <hr />
        <div>
          <FormGroup>
            <Field
              label={t('fields.email')}
              placeholder="email@address.com"
              name="email"
              type="email"
              component={RenderField}
            />
          </FormGroup>
        </div>
        <ErrorRenderer actions={actions} fields={['non_field_error']} />
        <hr />
        <div className="form-buttons">
          <Button
            className="primary pill"
            type="submit"
            disabled={submitting || pristine}
          >
            {t('buttons.request-password-reset')}
          </Button>
        </div>
      </form>
    );
  }
}

export default reduxForm<FormValues, OwnProps>({
  form: 'passwordResetForm',
  validate,
})(withTranslation('login')(PasswordResetForm));
