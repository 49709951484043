import React from 'react';

export type DropdownOptions = ReadonlyArray<{
  label: string;
  value: string;
}>;

interface OwnProps {
  options: DropdownOptions;
  selected: string;
  onSelect: (source: string) => void;
}

export type SourceDropdownProps = OwnProps;

class SourceDropdown extends React.PureComponent<SourceDropdownProps> {
  public render() {
    const { options, selected } = this.props;

    return (
      <div className="source-dropdown">
        <select onChange={this.handleDropdownChange} value={selected}>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  }

  public handleDropdownChange = (event: { target: { value: string } }) => {
    this.props.onSelect(event.target.value);
  };
}

export { SourceDropdown as TestableSourceDropdown };

export default SourceDropdown;
