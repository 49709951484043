const FIELD_TRIAL = 'trial';
const FIELD_COUNTRY = 'country';
const FIELD_SITE = 'site';
const FIELD_START = 'start_date';
const FIELD_END = 'end_date';
const FIELD_DATE = 'date_filter';

const COMPARISON_PREFIX = 'comparison_';

export const FIELDS = {
  IS_COMPARISON: 'isComparison',
  SINGLE: {
    TRIAL: FIELD_TRIAL,
    COUNTRY: FIELD_COUNTRY,
    SITE: FIELD_SITE,
    START: FIELD_START,
    END: FIELD_END,
    DATE: FIELD_DATE,
  },
  COMPARISON: {
    TRIAL: `${COMPARISON_PREFIX}${FIELD_TRIAL}`,
    COUNTRY: `${COMPARISON_PREFIX}${FIELD_COUNTRY}`,
    SITE: `${COMPARISON_PREFIX}${FIELD_SITE}`,
    START: `${COMPARISON_PREFIX}${FIELD_START}`,
    END: `${COMPARISON_PREFIX}${FIELD_END}`,
    DATE: `${COMPARISON_PREFIX}${FIELD_DATE}`,
  },
};

export const REPORT_FORM_NAME = 'generateReportForm';
