export interface OperationalStatusDataNode {
  id: string;
  color: string;
  transitionColor: string | null;
}

export interface OperationalStatusDataLink {
  source: string;
  target: string;
  value: number;
}

export interface OperationalStatusDataLinkWithRealTargetAndSourceNodes {
  source: OperationalStatusDataNode;
  target: OperationalStatusDataNode;
}

export interface OperationalStatusData {
  nodes: OperationalStatusDataNode[];
  links: OperationalStatusDataLink[];
  additional_data: { [key: string]: { [key: string]: number } };
}

export interface NodeTooltip {
  label: string;
  value: string;
}

export interface LinkTooltip {
  source: {
    id: string;
  };
  target: {
    id: string;
  };
  value: string;
}

export interface NodeLabel {
  id: string;
  value: string;
}

export function datumIsOperationalStatusDataLinkWithRealTargetAndSourceNodes(
  datum:
    | OperationalStatusDataNode
    | OperationalStatusDataLinkWithRealTargetAndSourceNodes
): datum is OperationalStatusDataLinkWithRealTargetAndSourceNodes {
  return datum.hasOwnProperty('source') && datum.hasOwnProperty('target');
}
