import { setPropsReducer } from '@dabapps/react-set-props';
import { responsesReducer } from '@dabapps/redux-requests';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import * as trainingReducers from '^/common/components/containers/footer/training-page/training-reducers';
import * as countryReducers from '^/countries/country-reducers';
import { LOGIN } from '^/login/login-actions';
import * as loginReducers from '^/login/login-reducers';
import * as modalReducers from '^/modals/modal-reducers';
import {
  RESET_PASSWORD,
  RESET_PASSWORD_CONFIRM,
} from '^/password-reset/password-reset-actions';
import { CREATE_PATIENT } from '^/patients/patient-actions';
import * as patientReducers from '^/patients/patient-reducers';
import {
  CREATE_PIC_INFORMATION,
  TOGGLE_ADD_PIC_INFORMATION,
  UPDATE_PIC_INFORMATION,
} from '^/pic-information/pic-information-actions';
import * as picInformationReducers from '^/pic-information/pic-information-reducers';
import * as screeningsBookedReducers from '^/reports/components/widgets/screenings-booked/screenings-booked-reducers';
import * as reportsReducers from '^/reports/report-reducers';
import * as roleReducers from '^/roles/role-reducers';
import { UserRoles } from '^/roles/role-types';
import { CREATE_TRIAL, UPDATE_TRIAL } from '^/trials/trial-actions';
import * as trialRequestReducers from '^/trials/trial-reducers';
import * as trialsiteRequestReducers from '^/trialsites/trialsite-reducers';
import { CREATE_USER, UPDATE_USER } from '^/users/user-actions';
import * as userRequestReducers from '^/users/user-reducers';

export default combineReducers({
  ...loginReducers,
  ...modalReducers,
  ...patientReducers,
  ...roleReducers,
  ...countryReducers,
  ...trialRequestReducers,
  ...trialsiteRequestReducers,
  ...userRequestReducers,
  ...picInformationReducers,
  ...trainingReducers,
  ...reportsReducers,
  ...screeningsBookedReducers,
  setPropsReducer,
  form: formReducer.plugin({
    createPatientForm: (state, action) => {
      switch (action.type) {
        case CREATE_PATIENT.SUCCESS:
          return undefined;
        case CREATE_PATIENT.FAILURE:
          return { ...state, submitErrors: action.payload.response.data };
        default:
          return state;
      }
    },
    createUserForm: (state, action) => {
      switch (action.type) {
        case CREATE_USER.SUCCESS:
          return {
            ...state,
            values: { roles: UserRoles.SITE },
            fields: undefined,
          };
        case CREATE_USER.FAILURE:
          return { ...state, submitErrors: action.payload.response.data };
        default:
          return state;
      }
    },
    inviteUserForm: (state, action) => {
      switch (action.type) {
        case CREATE_USER.SUCCESS:
          return {
            values: { roles: UserRoles.SITE },
            fields: undefined,
          };
        case CREATE_USER.FAILURE:
          return { ...state, submitErrors: action.payload.response.data };
        default:
          return state;
      }
    },
    editUserForm: (state, action) => {
      switch (action.type) {
        case UPDATE_USER.SUCCESS:
          return {
            values: { roles: UserRoles.SITE },
            fields: undefined,
          };
        case UPDATE_USER.FAILURE:
          return { ...state, submitErrors: action.payload.response.data };
        default:
          return state;
      }
    },
    createPICInformationForm: (state, action) => {
      switch (action.type) {
        case CREATE_PIC_INFORMATION.SUCCESS:
        case TOGGLE_ADD_PIC_INFORMATION:
          return {
            ...state,
            values: undefined,
            fields: undefined,
          };
        case CREATE_PIC_INFORMATION.FAILURE:
          return { ...state, submitErrors: action.payload.response.data };
        default:
          return state;
      }
    },
    editPICInformationForm: (state, action) => {
      switch (action.type) {
        case UPDATE_PIC_INFORMATION.SUCCESS:
          return {
            ...state,
            values: undefined,
            fields: undefined,
          };
        case UPDATE_PIC_INFORMATION.FAILURE:
          return { ...state, submitErrors: action.payload.response.data };
        default:
          return state;
      }
    },
    createTrialForm: (state, action) => {
      switch (action.type) {
        case CREATE_TRIAL.SUCCESS:
          return {
            ...state,
            values: undefined,
            fields: undefined,
          };
        case CREATE_TRIAL.FAILURE:
          return { ...state, submitErrors: action.payload.response.data };
        default:
          return state;
      }
    },
    editTrialForm: (state, action) => {
      switch (action.type) {
        case UPDATE_TRIAL.SUCCESS:
          return {
            ...state,
            values: undefined,
            fields: undefined,
          };
        case UPDATE_TRIAL.FAILURE:
          return { ...state, submitErrors: action.payload.response.data };
        default:
          return state;
      }
    },
    loginForm: (state, action) => {
      switch (action.type) {
        case LOGIN.SUCCESS:
          return {
            ...state,
            values: undefined,
            submitErrors: undefined,
            fields: undefined,
          };
        case LOGIN.FAILURE:
          return { ...state, submitErrors: action.payload.response.data };
        default:
          return state;
      }
    },
    passwordResetForm: (state, action) => {
      switch (action.type) {
        case RESET_PASSWORD.SUCCESS:
          return {
            ...state,
            values: undefined,
            submitErrors: undefined,
            fields: undefined,
          };
        case RESET_PASSWORD.FAILURE:
          return { ...state, submitErrors: action.payload.response.data };
        default:
          return state;
      }
    },
    passwordResetFormConfirm: (state, action) => {
      switch (action.type) {
        case RESET_PASSWORD_CONFIRM.SUCCESS:
          return {
            ...state,
            values: undefined,
            submitErrors: undefined,
            fields: undefined,
          };
        case RESET_PASSWORD_CONFIRM.FAILURE:
          return { ...state, submitErrors: action.payload.response.data };
        default:
          return state;
      }
    },
  }),
  responses: responsesReducer,
});
