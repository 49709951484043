import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { UserRoles } from '^/roles/role-types';
import { deleteTrialsiteUser } from '^/trialsites/trialsite-actions';
import { TrialsiteUser } from '^/trialsites/trialsite-types';

export interface OwnProps {
  trialsiteUsers: ReadonlyArray<TrialsiteUser>;
  trialsiteId: string;
}

export interface DispatchProps {
  deleteTrialsiteUser: typeof deleteTrialsiteUser;
}

export type Props = OwnProps & DispatchProps & WithTranslation;

export class TrialsiteUsers extends React.PureComponent<Props> {
  public render() {
    const { t, trialsiteUsers } = this.props;

    const CRAUsers = trialsiteUsers.filter(trialsiteUser =>
      this.isUserRole(trialsiteUser, UserRoles.CRA)
    );
    const siteUsers = trialsiteUsers.filter(trialsiteUser =>
      this.isUserRole(trialsiteUser, UserRoles.SITE)
    );

    return (
      <div className="trialsite-users">
        {siteUsers.length > 0 && (
          <p className="trialsite-user-header">{t('site-users')}</p>
        )}
        {siteUsers.map(trialsiteUser => {
          return this.trialsiteUserRow(trialsiteUser);
        })}
        {CRAUsers.length > 0 && (
          <p className="trialsite-user-header">{t('cra-users')}</p>
        )}
        {CRAUsers.map(trialsiteUser => {
          return this.trialsiteUserRow(trialsiteUser);
        })}
      </div>
    );
  }

  private isUserRole = (trialsiteUser: TrialsiteUser, type: string) => {
    if (trialsiteUser.user_detail.roles === type) {
      return true;
    }
    return false;
  };

  private onClickDelete = (trialsiteUserId: string) => {
    this.props.deleteTrialsiteUser(this.props.trialsiteId, trialsiteUserId);
  };

  private trialsiteUserRow = (trialsiteUser: TrialsiteUser) => {
    return (
      <div key={trialsiteUser.id} className="trialsite-user-row">
        <p className="trialsite-user-details">
          <span className="trialsite-user-name">
            {trialsiteUser.user_detail.full_name}
          </span>{' '}
          {trialsiteUser.user_detail.email}
        </p>
        <button
          onClick={this.onClickDelete.bind(this, trialsiteUser.id)}
          className="delete-button"
        >
          <FontAwesomeIcon size="2x" icon={faTrashAlt} />
        </button>
      </div>
    );
  };
}

export default connect(null, {
  deleteTrialsiteUser,
})(withTranslation('users')(TrialsiteUsers));
