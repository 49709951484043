import { Footer as RoeFooter } from '@dabapps/roe';
import * as React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import LanguagePicker from '^/common/components/language-picker';
import { StoreState } from '^/common/types';
import { User } from '^/users/user-types';

const SUPPORTED_LANGUAGES = ['en'];

interface ConnectedProps {
  loggedInUser?: User;
}

export type Props = ConnectedProps & WithTranslation;

export class Footer extends React.PureComponent<Props> {
  public render() {
    return (
      <RoeFooter>
        <div className="footer-links">
          <LanguagePicker languageCodes={SUPPORTED_LANGUAGES} />
          {this.props.loggedInUser &&
            this.props.loggedInUser.email !== 'AnonymousUser' && (
              <>
                {SUPPORTED_LANGUAGES.length > 1 && (
                  <span className="separator"> | </span>
                )}
                <Link to="/training">
                  <Trans i18nKey="footer.training">Training</Trans>
                </Link>
              </>
            )}
        </div>
        <p className="copyright">
          © 2020 Novo Nordisk A/S.{' '}
          <Trans i18nKey="footer.copyright">All Rights Reserved.</Trans>
          {(window as any).HEROKU_SLUG_COMMIT && (
            <>
              {' '}
              <Trans i18nKey="footer.version">Version:</Trans>
              {': '}
              <span>{(window as any).HEROKU_SLUG_COMMIT.slice(0, 8)}</span>
            </>
          )}
        </p>
      </RoeFooter>
    );
  }
}

export const mapStateToProps = ({
  loggedInUser,
}: StoreState): ConnectedProps => {
  return {
    loggedInUser,
  };
};

export default connect(mapStateToProps, {})(withTranslation()(Footer));
