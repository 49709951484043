import { Button } from '@dabapps/roe';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { StoreState } from '^/common/types';
import { showModal } from '^/modals/modal-actions';
import { ModalType } from '^/modals/modal-types';
import TrialsiteUsers from '^/trialsites/components/trialsite-users';
import {
  deleteTrialsite,
  getTrialsiteUsersByTrialsite,
  setSiteSearchTerm,
} from '^/trialsites/trialsite-actions';
import { Trialsite, TrialsiteUser } from '^/trialsites/trialsite-types';

export interface OwnProps {
  page: number;
  trialsite: Trialsite;
}

export interface DispatchProps {
  deleteTrialsite: typeof deleteTrialsite;
  setSiteSearchTerm: typeof setSiteSearchTerm;
  showModal: typeof showModal;
  getTrialsiteUsersByTrialsite: typeof getTrialsiteUsersByTrialsite;
}

export interface ConnectedProps {
  trialsitesByTrialList: ReadonlyArray<Trialsite>;
  trialsiteUserList: { [trialsiteId: string]: ReadonlyArray<TrialsiteUser> };
}

export type TrialsiteListRowProps = OwnProps &
  DispatchProps &
  ConnectedProps &
  WithTranslation;

export class TrialsiteListRow extends React.PureComponent<
  TrialsiteListRowProps
> {
  public componentWillMount() {
    if (this.props.trialsite.id) {
      this.props.getTrialsiteUsersByTrialsite(this.props.trialsite.id);
    }
  }

  public render() {
    const { t, trialsite, trialsiteUserList } = this.props;
    const trialsiteUsers = trialsite.id ? trialsiteUserList[trialsite.id] : [];
    return (
      <div className="trialsite-list-row">
        <div className="trialsite-row-header">
          <p className="trialsite-site-name">{trialsite.site_reference}</p>
          <p className="trialsite-reference">{trialsite.country}</p>
          <Button
            onClick={this.onClickAddCRAUserToTrialsite}
            className="primary pill"
            small
          >
            {t('buttons.add-cra-users')}
          </Button>
          <Button
            onClick={this.onClickAddSiteUserToTrialsite}
            className="primary pill hollow"
            small
          >
            {t('buttons.add-site-users')}
          </Button>
          {trialsiteUsers && trialsiteUsers.length <= 0 && (
            <button onClick={this.onClickDelete} className="delete-button">
              <FontAwesomeIcon size="2x" icon={faTrashAlt} />
            </button>
          )}
        </div>
        {trialsiteUsers && trialsiteUsers.length > 0 && trialsite.id && (
          <TrialsiteUsers
            trialsiteId={trialsite.id}
            trialsiteUsers={trialsiteUsers}
          />
        )}
      </div>
    );
  }

  public onClickDelete = () => {
    if (this.props.trialsite.id) {
      this.props.deleteTrialsite(
        this.props.trialsite.id,
        this.props.trialsite.trial,
        this.props.page
      );
    }
    this.props.setSiteSearchTerm('');
  };

  public onClickAddCRAUserToTrialsite = () => {
    this.props.showModal({
      [ModalType.AddCRAUserToTrialsite]: {
        show: true,
        trialsiteId: this.props.trialsite.id,
        siteName: this.props.trialsite.site_reference,
      },
    });
  };

  public onClickAddSiteUserToTrialsite = () => {
    this.props.showModal({
      [ModalType.AddSiteUserToTrialsite]: {
        show: true,
        trialsiteId: this.props.trialsite.id,
        siteName: this.props.trialsite.site_reference,
      },
    });
  };
}

export { TrialsiteListRow as TestableTrialsiteListRow };

export const mapStateToProps = ({
  trialsitesByTrialList,
  trialsiteUserList,
}: StoreState): ConnectedProps => {
  return {
    trialsitesByTrialList: trialsitesByTrialList?.results,
    trialsiteUserList,
  };
};

export default withTranslation('trials')(
  connect(mapStateToProps, {
    deleteTrialsite,
    setSiteSearchTerm,
    showModal,
    getTrialsiteUsersByTrialsite,
  })(TrialsiteListRow)
);
