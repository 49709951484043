import { SimpleRecord } from '@dabapps/simple-records';

export enum PatientStatus {
  New = 'New',
  InProgress = 'In Progress',
  ScreeningBooked = 'Screening Booked',
  ScreeningComplete = 'Screening Complete',
  Declined = 'Declined',
}

export type Patient = Readonly<{
  id: string;
  created: string;
  modified: string;
  reference?: string;
  legacy_reference?: string;
  referring_user: string;
  source: string;
  status: PatientStatus;
  status_detail?: string;
  trialsite: string;
  display_reference: string;
}>;

export const PatientRecord = SimpleRecord<Patient>({
  id: 'new',
  reference: '',
  referring_user: '',
  source: '',
  status: PatientStatus.New,
  trialsite: '',
  modified: '',
  created: '',
  display_reference: '',
});

export interface PatientStatusModal {
  patient: Patient;
  show: boolean;
}

export const reminderInterval: number = 14;

export type Source = Readonly<{ id: string; name: string }>;
