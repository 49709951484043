import {
  dispatchGenericRequest,
  makeAsyncActionSet,
} from '@dabapps/redux-requests';
import { Dispatch } from 'redux';

import { hideModal } from '^/modals/modal-actions';
import { ModalType } from '^/modals/modal-types';
import { PendingTrialsiteUser, Trialsite } from '^/trialsites/trialsite-types';
import { createUser, inviteUserEmail } from '^/users/user-actions';
import { User } from '^/users/user-types';

const TRIALSITES_ENDPOINT = '/api/trialsites/';

export const SET_TRIALSITE_SITE_SEARCH_TERM = 'SET_TRIALSITE_SITE_SEARCH_TERM';
export const setTrialsiteSiteSearchTerm = (term: string | null) => ({
  type: SET_TRIALSITE_SITE_SEARCH_TERM,
  payload: term,
});

export const SET_SITE_SEARCH_TERM = 'SET_SITE_SEARCH_TERM';
export const setSiteSearchTerm = (term: string | null) => ({
  type: SET_SITE_SEARCH_TERM,
  payload: term,
});

export function inviteUserTrialsite(user: User, trialsiteId: string) {
  return (dispatch: Dispatch) => {
    return createUser(user)(dispatch).then(response => {
      if (response === null) {
        return null;
      }

      createTrialsiteUser(response.data, trialsiteId)(dispatch);
      return inviteUserEmail(user.email, trialsiteId)(dispatch);
    });
  };
}

export const SET_TRIALSITE_CRA_SEARCH_TERM = 'SET_TRIALSITE_CRA_SEARCH_TERM';
export const setTrialsiteCRASearchTerm = (term: string | null) => ({
  type: SET_TRIALSITE_CRA_SEARCH_TERM,
  payload: term,
});

export const GET_TRIALSITE_BY_TRIAL = makeAsyncActionSet(
  'GET_TRIALSITE_BY_TRIAL'
);
export function getTrialsitesByTrial(id: string, page: number, site?: string) {
  let queryParams = `page=${page}&trial=${id}`;
  if (site) {
    queryParams += `&site_reference=${site}`;
  }
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      GET_TRIALSITE_BY_TRIAL,
      `${TRIALSITES_ENDPOINT}?${queryParams}`,
      'GET'
    )(dispatch).catch(() => null);
  };
}

export const GET_TRIALSITE_USERS_BY_TRIALSITE = makeAsyncActionSet(
  'GET_TRIALSITE_USERS_BY_TRIALSITE'
);
export function getTrialsiteUsersByTrialsite(trialsiteId: string) {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      GET_TRIALSITE_USERS_BY_TRIALSITE,
      `${TRIALSITES_ENDPOINT}${trialsiteId}/trialsiteusers/`,
      'GET',
      undefined,
      undefined,
      { trialsite_id: trialsiteId }
    )(dispatch).catch(() => null);
  };
}

export const GET_ALL_TRIALSITES = makeAsyncActionSet('GET_ALL_TRIALSITES');
export function getAllTrialsites() {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      GET_ALL_TRIALSITES,
      `${TRIALSITES_ENDPOINT}?paginated=${false}`,
      'GET'
    )(dispatch).catch(() => null);
  };
}

export const SET_SELECTED_TRIALSITE = 'SET_SELECTED_TRIALSITE';
export const setSelectedTrialsite = (trialsite: Trialsite) => ({
  type: SET_SELECTED_TRIALSITE,
  payload: trialsite,
});

export const DELETE_TRIALSITE = makeAsyncActionSet('DELETE_TRIALSITE');
export function deleteTrialsite(
  trialsiteID: string,
  trialID: string,
  page: number
) {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      DELETE_TRIALSITE,
      `${TRIALSITES_ENDPOINT}${trialsiteID}/`,
      'DELETE'
    )(dispatch)
      .then(() => {
        getTrialsitesByTrial(trialID, page)(dispatch);
      })
      .catch(() => null);
  };
}

export const DELETE_TRIALSITE_USER = makeAsyncActionSet(
  'DELETE_TRIALSITE_USER'
);
export function deleteTrialsiteUser(
  trialsiteId: string,
  trialsiteUserId: string
) {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      DELETE_TRIALSITE_USER,
      `${TRIALSITES_ENDPOINT}${trialsiteId}/trialsiteusers/${trialsiteUserId}/`,
      'DELETE'
    )(dispatch)
      .then(() => {
        getTrialsiteUsersByTrialsite(trialsiteId)(dispatch);
      })
      .catch(() => null);
  };
}

export const ADD_PENDING_TRIALSITE_USER = 'ADD_PENDING_TRIALSITE_USER';
export const addPendingTrialsiteUser = (
  trialsiteId: string,
  userId: string
) => ({
  type: ADD_PENDING_TRIALSITE_USER,
  payload: { trialsiteId, userId },
});

export const REMOVE_PENDING_TRIALSITE_USER = 'REMOVE_PENDING_TRIALSITE_USER';
export const removePendingTrialsiteUser = (
  trialsiteId: string,
  userId: string
) => ({
  type: REMOVE_PENDING_TRIALSITE_USER,
  payload: { trialsiteId, userId },
});

export const REMOVE_ALL_PENDING_TRIALSITE_USERS =
  'REMOVE_ALL_PENDING_TRIALSITE_USERS';
export const removeAllPendingTrialsiteUsers = () => ({
  type: REMOVE_ALL_PENDING_TRIALSITE_USERS,
  payload: null,
});

export const CREATE_TRIALSITE = makeAsyncActionSet('CREATE_TRIALSITE');
export function createTrialsite(trialsite: Trialsite, page: number) {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      CREATE_TRIALSITE,
      TRIALSITES_ENDPOINT,
      'POST',
      trialsite
    )(dispatch).then(() => {
      getTrialsitesByTrial(trialsite.trial, page)(dispatch);
    });
  };
}

export const CREATE_SINGLE_TRIALSITE_USER = makeAsyncActionSet(
  'CREATE_SINGLE_TRIALSITE_USER'
);
export function createTrialsiteUser(user: User, trialsiteId: string) {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      CREATE_SINGLE_TRIALSITE_USER,
      `${TRIALSITES_ENDPOINT}${trialsiteId}/trialsiteusers/`,
      'POST',
      { user: user.id }
    )(dispatch)
      .then(() => {
        getTrialsiteUsersByTrialsite(trialsiteId)(dispatch);
      })
      .catch(() => null);
  };
}

export function inviteAndCreateTrialsiteUser(
  invitedUser: User,
  pendingTrialsiteUsers: ReadonlyArray<PendingTrialsiteUser>,
  trialsiteId: string,
  modalType: ModalType
) {
  return (dispatch: Dispatch) => {
    inviteUserTrialsite(
      invitedUser,
      trialsiteId
    )(dispatch).then(response => {
      if (response === null) {
        return null;
      }

      createTrialsiteUsers(
        pendingTrialsiteUsers,
        trialsiteId,
        modalType
      )(dispatch);
    });
  };
}

export const CREATE_TRIALSITE_USER = makeAsyncActionSet(
  'CREATE_TRIALSITE_USER'
);
export function createTrialsiteUsers(
  pendingTrialsiteUsers: ReadonlyArray<PendingTrialsiteUser>,
  trialsiteId: string,
  modalType: ModalType
) {
  return (dispatch: Dispatch) => {
    const promises = pendingTrialsiteUsers
      .filter(pendingTrialsiteUser => {
        return pendingTrialsiteUser.trialsiteId === trialsiteId;
      })
      .map((pendingTrialsiteUser: PendingTrialsiteUser) => {
        return dispatchGenericRequest(
          CREATE_TRIALSITE_USER,
          `${TRIALSITES_ENDPOINT}${pendingTrialsiteUser.trialsiteId}/trialsiteusers/`,
          'POST',
          { user: pendingTrialsiteUser.userId }
        )(dispatch).catch(() => null);
      });
    return Promise.all(promises)
      .then(() => {
        dispatch(removeAllPendingTrialsiteUsers());
        dispatch(hideModal(modalType));
        getTrialsiteUsersByTrialsite(trialsiteId)(dispatch);
      })
      .catch(() => null);
  };
}
