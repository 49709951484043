import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Field } from 'redux-form';

import RenderDateField from '^/common/components/form/render-date-field';
import RenderField from '^/common/components/form/render-field';
import { SCREENING_BOOKED_DATE_FORMAT } from '^/common/constants';
import { PICInformationInput } from '^/pic-information/pic-information-types';

export const validate = (values: PICInformationInput) => {
  const errors: { [key: string]: string } = {};

  if (!values.name) {
    errors.name = 'Required';
  }

  if (!values.date) {
    errors.date = 'Required';
  }

  if (!values.patients_identified) {
    errors.patients_identified = 'Required';
  }

  if (!values.patients_approached) {
    errors.patients_approached = 'Required';
  }

  return errors;
};

class PICInformationFormGroups extends React.PureComponent<WithTranslation> {
  public render() {
    const { t } = this.props;

    return (
      <FormGroup>
        <Field
          label={t('fields.name')}
          placeholder={t('fields.name')}
          name="name"
          component={RenderField}
          type="text"
        />
        <Field
          label={t('fields.patients_identified')}
          placeholder={t('fields.patients_identified')}
          name="patients_identified"
          component={RenderField}
          type="number"
        />
        <Field
          label={t('fields.date')}
          placeholder={t('fields.date')}
          name="date"
          component={RenderDateField}
          dateFieldFormat={SCREENING_BOOKED_DATE_FORMAT}
        />
        <Field
          label={t('fields.patients_approached')}
          placeholder={t('fields.patients_approached')}
          name="patients_approached"
          component={RenderField}
          type="number"
        />
      </FormGroup>
    );
  }
}

export default withTranslation('pic')(PICInformationFormGroups);
