import { hasSucceeded } from '@dabapps/redux-requests';
import { Container, ContentBox, ContentBoxHeader, Section } from '@dabapps/roe';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Footer from '^/common/components/containers/footer/footer';
import NovoNavigation from '^/common/components/navigation/novo-navigation';
import { StoreState } from '^/common/types';
import PasswordResetForm from '^/password-reset/components/password-reset-form';
import { FormValues } from '^/password-reset/components/password-reset-form';
import {
  RESET_PASSWORD,
  resetPassword,
} from '^/password-reset/password-reset-actions';

interface DispatchProps {
  resetPassword: typeof resetPassword;
}

interface ConnectedProps {
  sent: boolean;
}

type Props = DispatchProps & ConnectedProps & WithTranslation;

export class PasswordResetPage extends React.PureComponent<Props> {
  public onPasswordReset = (values: FormValues) => {
    this.props.resetPassword(values as Readonly<{ email: string }>);
  };

  public render() {
    const { t, sent } = this.props;

    return (
      <Container>
        <NovoNavigation showLogin={false} />
        <ContentBox>
          <ContentBoxHeader>
            <h2>{t('reset-password-heading')}</h2>
          </ContentBoxHeader>
          {sent && (
            <div>
              <hr />
              <Section>
                <p>{t('reset-password-sent')}</p>
              </Section>
            </div>
          )}
          <PasswordResetForm onSubmit={this.onPasswordReset} />
        </ContentBox>
        <Footer />
      </Container>
    );
  }
}

export const mapStateToProps = ({ responses }: StoreState): {} => {
  return {
    sent: hasSucceeded(responses, RESET_PASSWORD),
  };
};

export default compose<Props, {}>(
  connect(mapStateToProps, { resetPassword }),
  withTranslation('login')
)(PasswordResetPage);
