import { Button, FormGroup } from '@dabapps/roe';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import ErrorRenderer from '^/common/components/form/error-renderer';
import RenderField from '^/common/components/form/render-field';
import { StoreState } from '^/common/types';
import { PendingTrialsiteUser } from '^/trialsites/trialsite-types';
import { CREATE_USER } from '^/users/user-actions';
import { User } from '^/users/user-types';

const actions = [CREATE_USER];

interface OwnProps {
  onSubmit?: (values: User) => void;
}

interface ConnectedProps {
  pendingTrialsiteUsers: ReadonlyArray<PendingTrialsiteUser>;
}

type Props = OwnProps &
  ConnectedProps &
  InjectedFormProps<User, OwnProps> &
  WithTranslation;

export const email = (value: string) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const validate = (values: User) => {
  const errors: { [key: string]: string } = {};

  if (values.full_name || values.email) {
    if (!values.full_name) {
      errors.full_name = 'Required';
    }

    if (!values.email) {
      errors.email = 'Required';
    }
  }

  return errors;
};

export class InviteUserForm extends React.Component<Props> {
  public render() {
    const { t, handleSubmit, submitting, pristine } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <p className="invite-header">{t('trials:invite-new-site-user')}</p>
        <FormGroup>
          <FormGroup>
            <Field
              component={RenderField}
              name="full_name"
              placeholder={t('fields.full_name')}
              type="text"
            />
            <Field
              component={RenderField}
              name="email"
              placeholder={t('fields.email')}
              type="text"
              validate={[email]}
            />
          </FormGroup>
        </FormGroup>
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors']}
          showStatusErrors
        />
        <div className="form-buttons">
          <Button
            type="submit"
            className="primary pill"
            disabled={
              submitting ||
              (this.props.pendingTrialsiteUsers &&
                this.props.pendingTrialsiteUsers.length <= 0 &&
                pristine)
            }
            id="modal-done-site"
          >
            {t('trials:buttons.add-site-users-to-trial-site')}
          </Button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = ({
  pendingTrialsiteUsers,
}: StoreState): ConnectedProps => {
  return {
    pendingTrialsiteUsers,
  };
};

export default reduxForm<User, OwnProps>({
  form: 'inviteUserForm',
  validate,
  destroyOnUnmount: true,
})(
  connect(mapStateToProps)(withTranslation(['users', 'trials'])(InviteUserForm))
);
