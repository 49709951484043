import { Row } from '@dabapps/roe';
import { push } from 'connected-react-router';
import moment from 'moment';
import queryString from 'query-string';
import React, { PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import compose from 'recompose/compose';
import { ThunkDispatch } from 'redux-thunk';

import CollapsableCard from '^/common/components/containers/collapsable-card';
import PageContainer from '^/common/components/containers/page-container';
import { DATE_FORMAT_ISO } from '^/common/constants';
import { AnyAction, StoreState } from '^/common/types';
import {
  getTruthyStringParams,
  pick,
  values,
} from '^/reports/components/hoc/create-widget/utils';
import ReportForm from '^/reports/components/report-form';
import ScreeningsWidget from '^/reports/components/widgets/dashboard-report';
import ReportDeclinedWidget from '^/reports/components/widgets/declined-reports';
import OperationalStatus from '^/reports/components/widgets/operational-status';
import RecruitmentSource from '^/reports/components/widgets/recruitment-source';
import MaybeReportAccuracyWarning from '^/reports/components/widgets/report-accuracy';
import ScreeningsBooked from '^/reports/components/widgets/screenings-booked';
import SiteActivity from '^/reports/components/widgets/site-activity';
import { FIELDS } from '^/reports/report-constants';

export interface DispatchProps {
  onSubmit: (data: Record<string, string | undefined>) => void;
}

export type Props = DispatchProps & RouteComponentProps<{}> & WithTranslation;

interface State {
  formOpen: boolean;
  isValidReport: boolean;
}

export class ReportPage extends PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);

    const isValidReport = this.checkValidReport(props);

    this.state = {
      isValidReport,
      formOpen: !isValidReport,
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.location.search !== this.props.location.search) {
      const isValidReport = this.checkValidReport(this.props);

      this.setState({
        isValidReport,
        formOpen: !isValidReport,
      });
    }
  }

  public render() {
    const { t } = this.props;
    const { formOpen } = this.state;

    return (
      <PageContainer page="reports" admin>
        <CollapsableCard
          style="margin-bottom-base margin-horizontal-none"
          onClick={this.toggleForm}
          icon={'add'}
          header={t('generate-new-report')}
          expanded={formOpen}
        >
          <ReportForm onSubmit={this.props.onSubmit} />
        </CollapsableCard>

        {this.state.isValidReport && (
          <Row className="flex-grid">
            <MaybeReportAccuracyWarning />
            <ScreeningsWidget />
            <OperationalStatus />
            <RecruitmentSource />
            <ReportDeclinedWidget />
            <SiteActivity />
            <ScreeningsBooked />
          </Row>
        )}
      </PageContainer>
    );
  }

  private toggleForm = () => {
    this.setState(({ formOpen }) => ({ formOpen: !formOpen }));
  };

  private checkValidReport(props: Props) {
    const params = getTruthyStringParams(
      queryString.parse(props.location.search)
    );

    return Boolean(params[FIELDS.SINGLE.START] && params[FIELDS.SINGLE.END]);
  }
}

const constructQueryParams = (data: Record<string, string | undefined>) => {
  const params = getTruthyStringParams(
    pick(data, [...values(FIELDS.SINGLE), ...values(FIELDS.COMPARISON)])
  );

  [
    FIELDS.SINGLE.START,
    FIELDS.SINGLE.END,
    FIELDS.COMPARISON.START,
    FIELDS.COMPARISON.END,
  ].forEach(key => {
    if (key in params) {
      params[key] = moment.utc(params[key]).format(DATE_FORMAT_ISO);
    }
  });

  return params;
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, undefined, AnyAction>
): DispatchProps => ({
  onSubmit: (data: Record<string, string | undefined>) => {
    dispatch(
      push(`/reports?${queryString.stringify(constructQueryParams(data))}`)
    );
  },
});

export default compose<Props, {}>(
  connect(undefined, mapDispatchToProps),
  withTranslation('reports')
)(ReportPage);
