import { TableCell, TableRow } from '@dabapps/roe';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

import { PICInformation } from '^/pic-information/pic-information-types';

const PIC_INFORMATION_DATE_FORMAT = 'DD MMM YYYY';

export interface Props {
  pic: PICInformation;
}

export class PICInformationRow extends React.PureComponent<Props> {
  public render() {
    const { pic } = this.props;

    return (
      <TableRow>
        <TableCell>{pic.name}</TableCell>
        <TableCell>
          {pic.date ? pic.date.format(PIC_INFORMATION_DATE_FORMAT) : ''}
        </TableCell>
        <TableCell>{pic.patients_identified}</TableCell>
        <TableCell>{pic.patients_approached}</TableCell>
        <TableCell className="edit">
          <Link to={'/pic/' + pic.id}>
            <FontAwesomeIcon size="2x" icon={faEdit} />
          </Link>
        </TableCell>
      </TableRow>
    );
  }
}

export default PICInformationRow;
