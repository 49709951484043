import {
  dispatchGenericRequest,
  makeAsyncActionSet,
} from '@dabapps/redux-requests';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';

import { getLoggedInUser } from '^/login/login-actions';
import { User } from '^/users/user-types';

const USERS_ENDPOINT = '/api/accounts/users/';
const ACCOUNTS_ENDPOINT = '/api/accounts';

export const UPDATE_USER = makeAsyncActionSet('UPDATE_USER');
export function updateUser(id: string, user: Partial<User>) {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      UPDATE_USER,
      `${USERS_ENDPOINT}${id}/`,
      'POST',
      user
    )(dispatch)
      .then(() => {
        getLoggedInUser()(dispatch);
        return dispatch(push('/users'));
      })
      .catch(() => null);
  };
}

export const CREATE_USER = makeAsyncActionSet('CREATE_USER');
export function createUser(user: User) {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      CREATE_USER,
      USERS_ENDPOINT,
      'POST',
      user
    )(dispatch)
      .then(response => {
        inviteUserEmail(user.email)(dispatch);
        getAllUsers()(dispatch);
        return response;
      })
      .catch(() => null);
  };
}

export const INVITE_USER_EMAIL = makeAsyncActionSet('INVITE_USER_EMAIL');
export function inviteUserEmail(email: string, trialsiteId?: string) {
  const data: { email: string; trialsite_id?: string } = { email };
  if (trialsiteId) {
    data.trialsite_id = trialsiteId;
  }

  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      INVITE_USER_EMAIL,
      `${ACCOUNTS_ENDPOINT}/email-invite/`,
      'POST',
      data,
      email
    )(dispatch).catch(() => null);
  };
}
export const GET_ALL_USERS = makeAsyncActionSet('GET_ALL_USERS');
export function getAllUsers() {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      GET_ALL_USERS,
      USERS_ENDPOINT,
      'GET'
    )(dispatch).catch(() => null);
  };
}

export const SET_USER_SEARCH_TERM = 'SET_USER_SEARCH_TERM';
export const setUserSearchTerm = (term: string | null) => ({
  type: SET_USER_SEARCH_TERM,
  payload: term,
});

export const SET_USER_FILTER_TERM = 'SET_USER_FILTER_TERM';
export const setUserFilterTerm = (term: string | null) => ({
  type: SET_USER_FILTER_TERM,
  payload: term,
});

export const TOGGLE_ADD_USER = 'TOGGLE_ADD_USER';
export const toggleAddUser = (expanded?: boolean) => ({
  type: TOGGLE_ADD_USER,
  payload: expanded,
});
