import { anyPending } from '@dabapps/redux-requests';
import {
  Column,
  ContentBox,
  ContentBoxHeader,
  Row,
  Section,
} from '@dabapps/roe';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';

import PageContainer from '^/common/components/containers/page-container';
import ErrorRenderer from '^/common/components/form/error-renderer';
import LoadingRenderer from '^/common/components/form/loading-renderer';
import { StoreState } from '^/common/types';
import EditTrialForm from '^/trials/components/trial-form-edit';
import {
  GET_ALL_TRIALS,
  getAllTrials,
  UPDATE_TRIAL,
  updateTrial,
} from '^/trials/trial-actions';
import { Trial } from '^/trials/trial-types';
import TrialsiteList from '^/trialsites/components/trialsite-sites-list';

const actions = [GET_ALL_TRIALS, UPDATE_TRIAL];

interface DispatchProps {
  updateTrial: typeof updateTrial;
  getAllTrials: typeof getAllTrials;
}

interface ConnectedProps {
  isLoading: boolean;
  trialList: ReadonlyArray<Trial>;
}

export type Props = DispatchProps &
  ConnectedProps &
  RouteComponentProps<{ id: string }> &
  WithTranslation;

export class TrialPageEdit extends React.PureComponent<Props> {
  public componentDidMount() {
    if (!this.props.trialList || this.props.trialList.length <= 0) {
      this.props.getAllTrials();
    }
  }

  public render() {
    const { t, trialList, match, isLoading } = this.props;

    const trial = trialList.find(x => x.id === match.params.id);

    const editTrialForm = trial ? (
      <EditTrialForm
        initialValues={trial}
        onSubmit={this.onUpdateTrialSubmit}
      />
    ) : (
      <Section>
        <p>{t('empty-state')}</p>
      </Section>
    );

    return (
      <PageContainer admin>
        <ContentBox>
          <ContentBoxHeader>
            <Row>
              <Column className="back-to" xs={4}>
                <Link to="/trials">
                  <FontAwesomeIcon icon={faArrowLeft} /> {t('back-to-trials')}
                </Link>
              </Column>
              <Column xsFill={4} xs={4} className="text-align-center">
                <h2>{t('edit-trial')}</h2>
              </Column>
            </Row>
          </ContentBoxHeader>
          {isLoading ? <LoadingRenderer /> : editTrialForm}
          <ErrorRenderer
            actions={actions}
            fields={['non_field_errors', 'detail']}
            showStatusErrors
          />
          <hr />
          {trial && <TrialsiteList trial={trial} />}
        </ContentBox>
      </PageContainer>
    );
  }

  private onUpdateTrialSubmit = (values: Trial) => {
    this.props.updateTrial(values.id, values);
  };
}

export const mapStateToProps = ({
  responses,
  trialList,
}: StoreState): ConnectedProps => {
  return {
    isLoading: anyPending(responses, actions),
    trialList,
  };
};

export default withTranslation('trials')(
  connect(mapStateToProps, {
    updateTrial,
    getAllTrials,
  })(TrialPageEdit)
);
