import { AxiosError } from 'axios';
import { ParsedQuery } from 'query-string';

export const errorToString = (error: AxiosError) => {
  if (error.response && error.response.data) {
    if (error.response.data.message) {
      return error.response.data.message;
    }

    if (error.message) {
      return error.message;
    }
  }

  if (error.message) {
    return error.message;
  }

  return error.toString();
};

export const getTruthyStringParams = (
  params: ParsedQuery
): Record<string, string> =>
  Object.keys(params).reduce(
    (memo, key) =>
      params[key] && typeof params[key] === 'string'
        ? Object.assign(memo, { [key]: params[key] })
        : memo,
    {}
  );

export const pick = <
  // tslint:disable-next-line:no-any
  T extends Record<string, any>,
  Keys extends keyof T,
  Picked extends { [P in Keys]: T[P] }
>(
  obj: T,
  keys: ReadonlyArray<Keys>
): Picked =>
  keys.reduce(
    (memo, key) => Object.assign(memo, { [key]: obj[key] }),
    {} as Picked
  );

export const values = <
  // tslint:disable-next-line:no-any
  T extends Record<string, any>,
  Keys extends keyof T,
  Values extends T[Keys]
>(
  obj: T
): Values[] => Object.keys(obj).map(key => obj[key]);

export const intersection = <T, S>(
  arr1: ReadonlyArray<T>,
  arr2: ReadonlyArray<S>
): Array<T & S> =>
  // tslint:disable-next-line:no-any
  arr1.filter(item => arr2.indexOf(item as any) >= 0) as Array<T & S>;
