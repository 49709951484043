import { Nav, NavItem } from '@dabapps/roe';
import { RouterState } from 'connected-react-router';
import React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { StoreState } from '^/common/types';

interface DispatchProps {
  router?: RouterState;
  isAdmin?: boolean;
}

export type AdminTabsProps = DispatchProps & WithTranslation;

export class AdminTabs extends React.PureComponent<AdminTabsProps> {
  public render() {
    const { router, isAdmin } = this.props;

    const path =
      router && router.location && router.location.pathname
        ? router.location.pathname
        : '';

    return (
      <Nav className="tabs">
        <NavItem active={path.includes('home')}>
          <Link to="/">
            <Trans i18nKey="tabs.home">Home</Trans>
          </Link>
        </NavItem>
        <NavItem active={path.includes('trial')}>
          <Link to="/trials">
            <Trans i18nKey="tabs.trials">Trials</Trans>
          </Link>
        </NavItem>
        <NavItem active={path.includes('reports')}>
          <Link to="/reports">
            <Trans i18nKey="tabs.reports">Reports</Trans>
          </Link>
        </NavItem>
        {isAdmin && (
          <NavItem active={path.includes('user')}>
            <Link to="/users">
              <Trans i18nKey="tabs.users">Users</Trans>
            </Link>
          </NavItem>
        )}
      </Nav>
    );
  }
}

export const mapStateToProps = ({ router, isAdmin }: StoreState): {} => {
  return {
    router,
    isAdmin,
  };
};

export default connect(mapStateToProps, {})(withTranslation()(AdminTabs));
