import { Pagination, PaginationDisplay } from '@dabapps/roe';
import React from 'react';

interface OwnProps {
  changePage: (pageNumber: number) => void;
  currentPageNumber: number;
  itemCount: number;
  pageSize: number;
}

export default class PaginationBar extends React.PureComponent<OwnProps> {
  public render() {
    const { changePage, currentPageNumber, itemCount, pageSize } = this.props;

    return (
      <div className="pagination-bar">
        <PaginationDisplay
          pageSize={pageSize}
          currentPageNumber={currentPageNumber}
          itemCount={itemCount}
        />
        <Pagination
          changePage={changePage}
          pageSize={pageSize}
          currentPageNumber={currentPageNumber}
          itemCount={itemCount}
        />
      </div>
    );
  }
}
