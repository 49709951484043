import { TableCell, TableHeader, TableRow } from '@dabapps/roe';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import LoadingRenderer from '^/common/components/form/loading-renderer';
import { DropdownOptions } from '^/common/components/form/render-dropdown';
import ProgressButton from '^/common/components/progress-button';
import OPTIONS from '^/common/helpers/dropdown-options';
import { StoreState } from '^/common/types';
import { showModal } from '^/modals/modal-actions';
import { ModalType } from '^/modals/modal-types';
import SourceDropdown from '^/patients/components/patient-source-dropdown';
import {
  deletePatient,
  getPatientSources,
  updatePatient,
} from '^/patients/patient-actions';
import { Patient, PatientStatus } from '^/patients/patient-types';

interface OwnProps {
  patient: Patient;
  highlightAfterDays?: number;
}

interface ConnectedProps {
  patientSources: StoreState['patientSources'];
}

interface DispatchProps {
  showModal: typeof showModal;
  updatePatient: typeof updatePatient;
  deletePatient: typeof deletePatient;
  getPatientSources: typeof getPatientSources;
}

export type PatientRowProps = OwnProps &
  DispatchProps &
  ConnectedProps &
  WithTranslation;

class PatientRow extends React.PureComponent<PatientRowProps> {
  public render() {
    const { t, patient, patientSources, highlightAfterDays } = this.props;

    const modified = patient.modified
      ? moment(patient.modified).format('D MMM YYYY')
      : '';

    const detailAsDate = moment(patient.status_detail, [
      'YYYY-MM-DD',
      'DD/MM/YYYY',
      'D MMM YYYY',
      'DD MMM YYYY',
    ]);

    const statusDetail =
      detailAsDate.isValid() &&
      detailAsDate.isAfter(new Date('2000-01-01')) &&
      !OPTIONS.Declined.includes(patient.status_detail || '') &&
      !OPTIONS.InProgress.includes(patient.status_detail || '')
        ? detailAsDate.format('DD MMM YYYY')
        : t(`details.${patient.status_detail}`);

    const sourceOptions: DropdownOptions =
      patientSources &&
      patientSources.map(source => {
        return { label: t(`sources.${source.id}`), value: source.id };
      });

    const overdue =
      patient.status === PatientStatus.ScreeningBooked &&
      moment(patient.status_detail, [
        'YYYY-MM-DD',
        'DD/MM/YYYY',
        'D MMM YYYY',
        'DD MMM YYYY',
      ]).isBefore(moment());

    return (
      <TableRow
        key={patient.id}
        className={classNames({
          highlight:
            highlightAfterDays &&
            moment(patient.modified).isBefore(
              moment().subtract(highlightAfterDays, 'days')
            ),
        })}
      >
        <TableHeader>
          {patient.legacy_reference
            ? patient.legacy_reference
            : patient.display_reference}
        </TableHeader>
        <TableCell>
          <ProgressButton
            onClick={this.onClickProgressButton}
            progress={this.calculateProgress(patient.status)}
          >
            {t(`statuses.${patient.status}`)}
          </ProgressButton>
        </TableCell>
        <TableCell className={classnames({ overdue })}>
          {statusDetail}
        </TableCell>
        <TableCell className="last-updated">{modified}</TableCell>
        <TableCell>
          {patientSources.length >= 1 ? (
            <SourceDropdown
              options={sourceOptions}
              selected={patient.source}
              onSelect={this.handleDropdownSelect}
            />
          ) : (
            <LoadingRenderer />
          )}
        </TableCell>
        <TableCell className="delete-cell">
          <button onClick={this.onPatientDelete}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        </TableCell>
      </TableRow>
    );
  }

  public onPatientDelete = () => {
    this.props.showModal({
      [ModalType.ConfirmDeletePatient]: {
        show: true,
        patientId: this.props.patient.id,
      },
    });
  };

  public handleDropdownSelect = (source: string) => {
    this.props.updatePatient(this.props.patient.id, { source });
  };

  public onClickProgressButton = () => {
    this.props.showModal({
      [ModalType.UpdatePatientStatus]: {
        show: true,
        patient: this.props.patient,
      },
    });
  };

  private calculateProgress(status: string) {
    switch (status) {
      case PatientStatus.InProgress:
        return 33;
      case PatientStatus.ScreeningBooked:
        return 66;
      case PatientStatus.ScreeningComplete:
        return 100;
      case PatientStatus.New:
      default:
        return 0;
    }
  }
}

export { PatientRow as TestablePatientRow };

export const mapStateToProps = ({
  patientSources,
}: StoreState): ConnectedProps => {
  return {
    patientSources,
  };
};

export default compose<PatientRowProps, OwnProps>(
  connect(mapStateToProps, {
    showModal,
    updatePatient,
    deletePatient,
    getPatientSources,
  }),
  withTranslation('patients')
)(PatientRow);
