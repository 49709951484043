import classNames from 'classnames';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { StoreState } from '^/common/types';
import { setTrialSearchTerm } from '^/trials/trial-actions';
import {
  setSiteSearchTerm,
  setTrialsiteCRASearchTerm,
  setTrialsiteSiteSearchTerm,
} from '^/trialsites/trialsite-actions';
import { setUserSearchTerm } from '^/users/user-actions';

interface OwnProps {
  type: 'Trial' | 'User' | 'Site' | 'Trialsite-site' | 'Trialsite-CRA';
}

interface DispatchProps {
  setUserSearchTerm: typeof setUserSearchTerm;
  setTrialSearchTerm: typeof setTrialSearchTerm;
  setTrialsiteSiteSearchTerm: typeof setTrialsiteSiteSearchTerm;
  setTrialsiteCRASearchTerm: typeof setTrialsiteCRASearchTerm;
  setSiteSearchTerm: typeof setSiteSearchTerm;
}

interface ConnectedProps {
  userSearchTerm: string | null;
  trialSearchTerm: string | null;
  trialsiteSiteSearchTerm: string | null;
  trialsiteCRASearchTerm: string | null;
  siteSearchTerm: string | null;
}

export type Props = DispatchProps & OwnProps & ConnectedProps & WithTranslation;

export class InputFilter extends React.PureComponent<Props> {
  public componentWillMount() {
    this.props.setUserSearchTerm('');
    this.props.setTrialSearchTerm('');
    this.props.setTrialsiteSiteSearchTerm('');
    this.props.setTrialsiteCRASearchTerm('');
  }

  public render() {
    const { t, type } = this.props;

    return (
      <div>
        <input
          className={classNames('input-search', type)}
          type="search"
          placeholder={t('search-by-type', { type: t(`types.${type}`) })}
          onChange={this.handleInputChange}
          value={this.searchTerm(type)}
        />
      </div>
    );
  }

  private searchTerm = (type: string) => {
    switch (type) {
      case 'User':
        return this.props.userSearchTerm ? this.props.userSearchTerm : '';
      case 'Trial':
        return this.props.trialSearchTerm ? this.props.trialSearchTerm : '';
      case 'Trialsite-site':
        return this.props.trialsiteSiteSearchTerm
          ? this.props.trialsiteSiteSearchTerm
          : '';
      case 'Trialsite-CRA':
        return this.props.trialsiteCRASearchTerm
          ? this.props.trialsiteCRASearchTerm
          : '';
      case 'Site':
        return this.props.siteSearchTerm ? this.props.siteSearchTerm : '';
      default:
        return '';
    }
  };

  private handleInputChange = (event: React.ChangeEvent | undefined) => {
    const { type } = this.props;
    if (event) {
      switch (type) {
        case 'User':
          this.props.setUserSearchTerm(
            (event.target as HTMLInputElement).value
          );
          return;
        case 'Trial':
          this.props.setTrialSearchTerm(
            (event.target as HTMLInputElement).value
          );
          return;
        case 'Trialsite-site':
          this.props.setTrialsiteSiteSearchTerm(
            (event.target as HTMLInputElement).value
          );
          return;
        case 'Trialsite-CRA':
          this.props.setTrialsiteCRASearchTerm(
            (event.target as HTMLInputElement).value
          );
          return;
        case 'Site':
          this.props.setSiteSearchTerm(
            (event.target as HTMLInputElement).value
          );
          return;
        default:
          return;
      }
    }
  };
}

export const mapStateToProps = ({
  userSearchTerm,
  trialSearchTerm,
  trialsiteSiteSearchTerm,
  trialsiteCRASearchTerm,
  siteSearchTerm,
}: StoreState): ConnectedProps => {
  return {
    userSearchTerm,
    trialSearchTerm,
    trialsiteSiteSearchTerm,
    trialsiteCRASearchTerm,
    siteSearchTerm,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    setUserSearchTerm,
    setTrialSearchTerm,
    setTrialsiteSiteSearchTerm,
    setTrialsiteCRASearchTerm,
    setSiteSearchTerm,
  })(InputFilter)
);
