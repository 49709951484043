import React from 'react';

export interface Props {
  onClick?: () => void;
  subtitle?: string;
  title: string;
}

export class ModalListItem extends React.PureComponent<Props> {
  public render() {
    const { title, subtitle } = this.props;
    return (
      <button className="modal-list-item" onClick={this.props.onClick}>
        <p className="title">{title}</p>
        {subtitle && <p className="subtitle">{subtitle}</p>}
        {this.props.children}
      </button>
    );
  }
}

export default ModalListItem;
