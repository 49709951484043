import { anyPending } from '@dabapps/redux-requests';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Section,
} from '@dabapps/roe';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import ErrorRenderer from '^/common/components/form/error-renderer';
import LoadingRenderer from '^/common/components/form/loading-renderer';
import { StoreState } from '^/common/types';
import { hideModal } from '^/modals/modal-actions';
import { ModalType } from '^/modals/modal-types';
import {
  DELETE_PIC_INFORMATION,
  deletePICInformation,
} from '^/pic-information/pic-information-actions';

const actions = [DELETE_PIC_INFORMATION];

interface ConnectedProps {
  isLoading: boolean;
  picId: string | undefined;
}

interface DispatchProps {
  deletePICInformation: typeof deletePICInformation;
  hideModal: typeof hideModal;
}

export type Props = ConnectedProps & DispatchProps & WithTranslation;

export class DeletePICInformationModal extends React.PureComponent<Props> {
  public render() {
    const { t, isLoading, picId } = this.props;

    if (!picId) {
      return (
        <Modal onClickOutside={this.onCancel}>
          <LoadingRenderer />
        </Modal>
      );
    }

    return (
      <Modal onClickOutside={this.onCancel} className="delete-pic-modal" small>
        <ModalHeader>
          <h1>{t('delete-pic-information')}</h1>
          <button
            className="card-header-button"
            id="close-modal-pic"
            onClick={this.onCancel}
          >
            <FontAwesomeIcon
              className="pic-close-button"
              size="1x"
              icon={faTimes}
            />
          </button>
        </ModalHeader>
        <hr />

        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors', 'detail']}
          showStatusErrors
        />
        <ModalBody>
          <Section>
            <p>{t('are-you-sure-you-want-to-delete')}</p>
          </Section>
        </ModalBody>
        {isLoading && <LoadingRenderer />}
        <ModalFooter className="buttons">
          <Button
            className="pill hollow"
            id="modal-cancel"
            onClick={this.onCancel}
            disabled={!this.props.picId}
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            className="error pill"
            id="modal-delete"
            onClick={this.onDeletePICInformation.bind(this, picId)}
            disabled={!this.props.picId}
          >
            {t('buttons.delete')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  private onCancel = () => {
    this.props.hideModal(ModalType.DeletePICInformation);
  };

  private onDeletePICInformation(picId: string) {
    this.props.deletePICInformation(picId);
  }
}

export const mapStateToProps = ({
  responses,
  sharedModals: modals,
}: StoreState): ConnectedProps => {
  const picId = modals[ModalType.DeletePICInformation]
    ? modals[ModalType.DeletePICInformation].picId
    : undefined;

  return {
    isLoading: anyPending(responses, actions),
    picId,
  };
};

export default compose<Props, {}>(
  connect(mapStateToProps, {
    deletePICInformation,
    hideModal,
  }),
  withTranslation('pic')
)(DeletePICInformationModal);
