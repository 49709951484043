import { Container } from '@dabapps/roe';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Footer from '^/common/components/containers/footer/footer';
import { toggleVideoExpanded } from '^/common/components/containers/footer/training-page/training-actions';
import VideoCard from '^/common/components/containers/footer/training-page/video-card';
import NovoNavigation from '^/common/components/navigation/novo-navigation';
import { StoreState } from '^/common/types';

interface ConnectedProps {
  videoExpanded: ReadonlyArray<boolean>;
}

interface DispatchProps {
  toggleVideoExpanded: typeof toggleVideoExpanded;
}

export type TrainingPageProps = ConnectedProps &
  DispatchProps &
  WithTranslation;

class TrainingPage extends React.PureComponent<TrainingPageProps> {
  public render() {
    const { t, videoExpanded } = this.props;

    return (
      <Container>
        <NovoNavigation />
        <VideoCard
          videoExpanded={videoExpanded[0]}
          toggleVideoExpanded={this.toggleVideoExpanded.bind(this, 0)}
          src={t('videos.navigating-to-patients.src')}
          title={t('videos.navigating-to-patients.title')}
          subtitle={t('videos.navigating-to-patients.subtitle')}
        />
        <VideoCard
          videoExpanded={videoExpanded[1]}
          toggleVideoExpanded={this.toggleVideoExpanded.bind(this, 1)}
          src={t('videos.adding-a-patient.src')}
          title={t('videos.adding-a-patient.title')}
          subtitle={t('videos.adding-a-patient.subtitle')}
        />
        <VideoCard
          videoExpanded={videoExpanded[2]}
          toggleVideoExpanded={this.toggleVideoExpanded.bind(this, 2)}
          src={t('videos.updating-patient-status.src')}
          title={t('videos.updating-patient-status.title')}
          subtitle={t('videos.updating-patient-status.subtitle')}
        />
        <VideoCard
          videoExpanded={videoExpanded[3]}
          toggleVideoExpanded={this.toggleVideoExpanded.bind(this, 3)}
          src={t('videos.adding-pic-info.src')}
          title={t('videos.adding-pic-info.title')}
          subtitle={t('videos.adding-pic-info.subtitle')}
        />
        <Footer />
      </Container>
    );
  }

  public toggleVideoExpanded = (videoNumber: number) => {
    this.props.toggleVideoExpanded(videoNumber);
  };
}

export { TrainingPage as TestableTrainingPage };

export const mapStateToProps = ({
  videoExpanded,
}: StoreState): ConnectedProps => {
  return {
    videoExpanded,
  };
};

export default compose<TrainingPageProps, {}>(
  connect(mapStateToProps, {
    toggleVideoExpanded,
  }),
  withTranslation('training')
)(TrainingPage);
