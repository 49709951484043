import {
  hasFailed,
  hasSucceeded,
  isPending,
  ResponsesReducerState,
} from '@dabapps/redux-requests';
import { Button } from '@dabapps/roe';
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import MDSpinner from 'react-md-spinner';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { InjectedFormProps, reduxForm } from 'redux-form';

import ErrorRenderer from '^/common/components/form/error-renderer';
import { StoreState } from '^/common/types';
import UserFormGroups from '^/users/components/user-form-group';
import { INVITE_USER_EMAIL, UPDATE_USER } from '^/users/user-actions';
import { User } from '^/users/user-types';

const actions = [UPDATE_USER];

interface OwnProps {
  onSubmit?: (values: User) => void;
  handleResendInvite: (email: string) => void;
}

interface ConnectedProps {
  responses: ResponsesReducerState;
}

type Props = OwnProps &
  ConnectedProps &
  InjectedFormProps<User, OwnProps> &
  WithTranslation;

export enum EmailStatus {
  EmailPending = 'EmailPending',
  EmailSent = 'EmailSent',
  EmailFailed = 'EmailFailed',
}

export class EditUserForm extends React.Component<Props> {
  public render() {
    const { t, handleSubmit, submitting, pristine } = this.props;

    const status = this.getEmailStatus();

    const details = this.setButtonDetails(status);

    const inviteButtonLabel = details.label;
    const icon = details.icon;

    return (
      <form onSubmit={handleSubmit}>
        <hr />
        <UserFormGroups />
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors']}
          showStatusErrors
        />
        <hr />
        <div className="form-buttons">
          <Button
            type="submit"
            className="primary pill"
            disabled={submitting || pristine}
          >
            {t('buttons.save-changes')}
          </Button>
          <Button
            className="primary pill hollow"
            type="button"
            onClick={this.onClickResendInvite}
            disabled={submitting}
          >
            {inviteButtonLabel}
            {icon}
          </Button>
        </div>
      </form>
    );
  }

  private setButtonDetails = (status: EmailStatus | undefined) => {
    const { t } = this.props;

    switch (status) {
      case EmailStatus.EmailPending:
        return {
          label: t('buttons.invite-button-inviting'),
          icon: (
            <div className="button-icon">
              <MDSpinner size={16} singleColor="#00a4e4" />
            </div>
          ),
        };
      case EmailStatus.EmailSent:
        return {
          label: t('buttons.invite-button-invited'),
          icon: (
            <div className="button-icon">
              <FontAwesomeIcon size="lg" icon={faCheck} />
            </div>
          ),
        };
      case EmailStatus.EmailFailed:
        return {
          label: t('buttons.invite-button-failed'),
          icon: (
            <div className="button-icon">
              <FontAwesomeIcon size="lg" icon={faTimes} />
            </div>
          ),
        };
      default:
        return { label: t('buttons.invite-button-resend'), icon: null };
    }
  };

  private getEmailStatus = () => {
    const {
      responses,
      initialValues: { email },
    } = this.props;

    if (isPending(responses, INVITE_USER_EMAIL, email)) {
      return EmailStatus.EmailPending;
    }

    if (hasSucceeded(responses, INVITE_USER_EMAIL, email)) {
      return EmailStatus.EmailSent;
    }

    if (hasFailed(responses, INVITE_USER_EMAIL, email)) {
      return EmailStatus.EmailFailed;
    }
  };

  private onClickResendInvite = () => {
    if (this.props.initialValues.email) {
      this.props.handleResendInvite(this.props.initialValues.email);
    }
  };
}

export const mapStateToProps = ({ responses }: StoreState): ConnectedProps => {
  return {
    responses,
  };
};

export default reduxForm<User, OwnProps>({
  form: 'editUserForm',
  destroyOnUnmount: true,
})(
  compose<Props, OwnProps & InjectedFormProps<User, OwnProps>>(
    connect(mapStateToProps),
    withTranslation('users')
  )(EditUserForm)
);
