import { Container, ContentBox, ContentBoxHeader, Section } from '@dabapps/roe';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Footer from '^/common/components/containers/footer/footer';
import NovoNavigation from '^/common/components/navigation/novo-navigation';

export type NotFoundPageProps = WithTranslation;

class NotFoundPage extends React.PureComponent<NotFoundPageProps> {
  public render() {
    const { t } = this.props;

    return (
      <Container>
        <NovoNavigation showLogin={false} />
        <ContentBox className="page-not-found">
          <ContentBoxHeader>
            <h2>{t('headings.404')}</h2>
          </ContentBoxHeader>
          <hr />
          <Section>
            <p>
              <Link to="/">{t('take-me-home')}</Link>
            </p>
          </Section>
        </ContentBox>
        <Footer />
      </Container>
    );
  }
}

export { NotFoundPage as TestableNotFoundPage };

export default withTranslation('errors')(NotFoundPage);
