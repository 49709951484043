import { AsyncActionSet } from '@dabapps/redux-requests';

import { StoreState } from '^/common/types';
import { SiteDropdownValue, TrialDropdownValue } from '^/reports/reports-types';

export const formatTrialFieldValues = (
  input: ReadonlyArray<TrialDropdownValue>,
  allLabel: string
) => {
  const out = [{ value: '', label: allLabel }];
  if (input) {
    input.map(item => {
      out.push({ value: item.id, label: item.global_trial_id });
    });
  }
  return out;
};

export const formatSiteFieldValues = (
  input: ReadonlyArray<SiteDropdownValue>,
  allLabel: string
) => {
  const out = [{ value: '', label: allLabel }];
  if (input) {
    input.map(item => {
      out.push({ value: item.id, label: item.site_reference });
    });
  }
  return out;
};

export const getReport = <T>(
  state: StoreState,
  actionSet: AsyncActionSet,
  tag: string
): T | undefined => {
  const mapping = state.reportsMap[actionSet.REQUEST];

  if (mapping) {
    return mapping[tag];
  }

  return undefined;
};
