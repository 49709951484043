import { RecordWithConstructor } from '@dabapps/simple-records';
import moment from 'moment';

export type TrialInput = Readonly<{
  id: string;
  fpfv_planned: string | Date | moment.Moment | null;
  lpfv_planned: string | Date | moment.Moment | null;
  global_trial_id: string;
  active: boolean;
  patients_recruited: number;
}>;

export type Trial = Readonly<{
  id: string;
  fpfv_planned: moment.Moment | null;
  lpfv_planned: moment.Moment | null;
  global_trial_id: string;
  active: boolean;
  patients_recruited: number;
}>;

const trialDefaults = {
  id: '',
  fpfv_planned: null,
  lpfv_planned: null,
  global_trial_id: '',
  active: true,
  patients_recruited: 0,
};

export const TrialRecord = RecordWithConstructor<TrialInput, Trial>(
  trialDefaults,
  input => {
    return {
      ...input,
      fpfv_planned: input.fpfv_planned ? moment(input.fpfv_planned) : null,
      lpfv_planned: input.lpfv_planned ? moment(input.lpfv_planned) : null,
    };
  }
);

export const TrialRecordForApi = RecordWithConstructor<Trial, TrialInput>(
  trialDefaults,
  input => {
    return {
      ...input,
      fpfv_planned: input.fpfv_planned
        ? moment(input.fpfv_planned, 'YYYY-MM-DD').format('YYYY-MM-DD')
        : null,
      lpfv_planned: input.lpfv_planned
        ? moment(input.lpfv_planned, 'YYYY-MM-DD').format('YYYY-MM-DD')
        : null,
    };
  }
);
