import { anyPending } from '@dabapps/redux-requests';
import { Modal, ModalBody, ModalHeader } from '@dabapps/roe';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import ErrorRenderer from '^/common/components/form/error-renderer';
import LoadingRenderer from '^/common/components/form/loading-renderer';
import ModalListItem from '^/common/components/modals/modal-list-item';
import { StoreState } from '^/common/types';
import { hideModal } from '^/modals/modal-actions';
import { ModalType } from '^/modals/modal-types';
import {
  getAllPatients,
  getPatientsByTrialsite,
} from '^/patients/patient-actions';
import { GET_ALL_TRIALS, getAllTrials } from '^/trials/trial-actions';
import {
  GET_ALL_TRIALSITES,
  getAllTrialsites,
  setSelectedTrialsite,
} from '^/trialsites/trialsite-actions';
import { Trialsite } from '^/trialsites/trialsite-types';

const actions = [GET_ALL_TRIALSITES, GET_ALL_TRIALS];

interface ConnectedProps {
  isLoading: boolean;
  trialsitesList: ReadonlyArray<Trialsite>;
}

interface DispatchProps {
  getAllTrials: typeof getAllTrials;
  getAllTrialsites: typeof getAllTrialsites;
  setSelectedTrialsite: typeof setSelectedTrialsite;
  hideModal: typeof hideModal;
  getAllPatients: typeof getAllPatients;
  getPatientsByTrialsite: typeof getPatientsByTrialsite;
}

export type Props = ConnectedProps & DispatchProps & WithTranslation;

export class TrialsiteSelectionModal extends React.PureComponent<Props> {
  public componentDidMount() {
    this.props.getAllTrialsites();
  }

  public render() {
    const { t, isLoading, trialsitesList = [] } = this.props;

    if (trialsitesList.some(trialsite => !trialsite.trial_detail)) {
      this.props.getAllTrials();
    }

    const body =
      isLoading && trialsitesList.length <= 0 ? (
        <LoadingRenderer />
      ) : (
        <ModalBody>
          <div className="modal-list">
            {trialsitesList.map(trialsite => (
              <ModalListItem
                key={trialsite.site_reference}
                title={
                  trialsite.trial_detail
                    ? trialsite.trial_detail.global_trial_id
                    : trialsite.trial
                }
                subtitle={trialsite.site_reference}
                onClick={this.setSelected.bind(this, trialsite)}
              />
            ))}
          </div>
        </ModalBody>
      );

    return (
      <Modal
        className="trialsite-selection-modal"
        onClickOutside={this.onCancel}
      >
        <ModalHeader>
          <div className="card-header-button" />
          <h1>{t('headings.select-a-trial')}</h1>
          <button
            id="close-modal"
            className="card-header-button"
            onClick={this.onCancel}
          >
            {String.fromCharCode(215)}
          </button>
        </ModalHeader>
        <hr />
        {body}
        <ErrorRenderer
          actions={actions}
          fields={['non-field-errors', 'detail']}
          showStatusErrors
        />
      </Modal>
    );
  }

  private setSelected = (trialsite: Trialsite) => {
    this.props.setSelectedTrialsite(trialsite);
    this.props.hideModal(ModalType.SwitchTrial);

    if (trialsite.id) {
      this.props.getPatientsByTrialsite(trialsite.id);
    }
  };

  private onCancel = () => {
    this.props.hideModal(ModalType.SwitchTrial);
  };
}

export const mapStateToProps = ({
  responses,
  trialsitesList,
}: StoreState): ConnectedProps => {
  return {
    isLoading: anyPending(responses, actions),
    trialsitesList,
  };
};

export default compose<Props, {}>(
  connect(mapStateToProps, {
    getAllTrials,
    getAllTrialsites,
    setSelectedTrialsite,
    hideModal,
    getAllPatients,
    getPatientsByTrialsite,
  }),
  withTranslation('patients')
)(TrialsiteSelectionModal);
