import { AsyncActionSet } from '@dabapps/redux-requests';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { WrappedFieldProps } from 'redux-form';

import ErrorRenderer from '^/common/components/form/error-renderer';
import { DATE_FORMAT_DISPLAY } from '^/common/constants';

interface OwnProps {
  label: string;
  placeholder?: string;
  sideLabel?: string;
  actions?: ReadonlyArray<AsyncActionSet>;
  disabled?: boolean;
  maxDate?: Moment;
  minDate?: Moment;
  dateFieldFormat?: string;
}

export type RenderDateFieldProps = OwnProps &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps;

class RenderDateField extends React.PureComponent<RenderDateFieldProps> {
  public render() {
    const {
      input,
      placeholder,
      label,
      meta: { touched, error, warning },
      disabled,
      minDate,
      maxDate,
      dateFieldFormat = 'YYYY-MM-DD',
    } = this.props;

    const selectedDate = input.value
      ? moment(input.value, dateFieldFormat).toDate()
      : null;

    return (
      <div className="form-field">
        <label>{label}</label>
        <div
          className={classNames(
            'form-field-input',
            { error: touched && error },
            { warning: touched && warning }
          )}
        >
          <ReactDatePicker
            dateFormat="dd MMM yyyy"
            selected={selectedDate}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            name={input.name}
            autoComplete="off"
            placeholderText={placeholder}
            disabled={disabled}
            showMonthDropdown
            showYearDropdown
            minDate={minDate?.toDate()}
            maxDate={maxDate?.toDate()}
          />
        </div>
        {touched && <ErrorRenderer formErrors={[error, warning]} />}
      </div>
    );
  }

  public handleChange = (date: Date | null) =>
    this.props.input.onChange(
      date && moment(date).format(this.props.dateFieldFormat || 'YYYY-MM-DD')
    );

  public handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = moment(event.currentTarget.value, DATE_FORMAT_DISPLAY);

    if (value.isValid()) {
      this.props.input.onBlur(value.toDate());
      this.handleChange(value.toDate());
    }
  };
}

export { RenderDateField as TestableRenderDateField };

export default RenderDateField;
