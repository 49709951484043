import { Collapse, ContentBox, ContentBoxHeader } from '@dabapps/roe';
import classNames from 'classnames';
import React from 'react';

import AnimatedAddIcon from '^/common/components/icons/animated-add-icon';
import AnimatedDropdownIcon from '^/common/components/icons/animated-dropdown-icon';

const MAX_COLLAPSE_HEIGHT: number = 0;

export interface Props {
  header?: string;
  icon: 'add' | 'dropdown';
  style?: string;
  expanded?: boolean;
  onClick?: () => void;
}

export class CollapsableCard extends React.PureComponent<Props> {
  public render() {
    const { header, style, expanded = false } = this.props;

    const icon =
      this.props.icon === 'add' ? (
        <AnimatedAddIcon />
      ) : (
        <AnimatedDropdownIcon />
      );

    return (
      <ContentBox
        className={classNames('collapsable-card', style, {
          expanded,
        })}
      >
        <ContentBoxHeader className={classNames('card-header')}>
          <div className="card-header-button" />
          <h1>{header}</h1>
          <button className="card-header-button" onClick={this.props.onClick}>
            {icon}
          </button>
        </ContentBoxHeader>

        <Collapse
          open={expanded}
          maxCollapsedHeight={MAX_COLLAPSE_HEIGHT}
          animationDuration={200}
          className={classNames('collapse-btn')}
        >
          {this.props.children}
        </Collapse>
      </ContentBox>
    );
  }
}

export default CollapsableCard;
