import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default class AnimatedDropdownIcon extends React.PureComponent {
  public render() {
    return (
      <FontAwesomeIcon
        className="dropdown-animated-icon"
        size="2x"
        icon={faChevronDown}
      />
    );
  }
}
