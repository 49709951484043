import { Button, FormGroup } from '@dabapps/roe';
import moment from 'moment';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import ErrorRenderer from '^/common/components/form/error-renderer';
import LoadingRenderer from '^/common/components/form/loading-renderer';
import RenderDateField from '^/common/components/form/render-date-field';
import RenderDropdown, {
  DropdownOptions,
} from '^/common/components/form/render-dropdown';
import RenderStatusSelector from '^/common/components/form/render-status-selector';
import { SCREENING_BOOKED_DATE_FORMAT } from '^/common/constants';
import DynamicDropdownOptions from '^/common/helpers/dropdown-options';
import { StoreState } from '^/common/types';
import { CREATE_PATIENT, getPatientSources } from '^/patients/patient-actions';
import { Patient, PatientStatus, Source } from '^/patients/patient-types';

const actions = [CREATE_PATIENT];
const selector = formValueSelector('createPatientForm');

interface OwnProps {
  isLoading?: boolean;
}

interface ConnectedProps {
  patientSources: ReadonlyArray<Source>;
  statusDetailValue?: string;
}

interface DispatchProps {
  getPatientSources: typeof getPatientSources;
}

export type Props = OwnProps &
  DispatchProps &
  ConnectedProps &
  InjectedFormProps<Patient, OwnProps> &
  WithTranslation;

export const required = (value: string) => (value ? undefined : 'Required');

export const dateInFuture = (value: string) => {
  const isInPast = moment(value, 'DD/MM/YYYY').isBefore(moment(), 'day');

  return isInPast ? 'Must be in the future' : undefined;
};

export class PatientFormCreate extends React.Component<Props> {
  public componentWillMount() {
    this.props.getPatientSources();
  }

  public componentDidUpdate(prevProps: Props) {
    const { statusDetailValue } = this.props;

    if (statusDetailValue !== prevProps.statusDetailValue) {
      this.props.change('status_detail', '');
    }
  }

  public render() {
    const {
      t,
      handleSubmit,
      submitting,
      pristine,
      isLoading,
      patientSources,
    } = this.props;

    const sourceOptions: DropdownOptions =
      patientSources &&
      patientSources.map(source => {
        return { label: t(`sources.${source.id}`), value: source.id };
      });

    return (
      <form onSubmit={handleSubmit}>
        <hr />
        <FormGroup>
          <Field
            label={t('fields.status')}
            name="status"
            component={RenderStatusSelector}
            actions={actions}
            type="text"
            validate={[required]}
          />
          {this.getDetailComponent()}
          {patientSources ? (
            <Field
              label={t('fields.source')}
              name="source"
              component={RenderDropdown}
              options={[
                { label: 'Pick a source', value: '', disabled: true },
                ...sourceOptions,
              ]}
              validate={[required]}
              type="select"
            />
          ) : (
            <LoadingRenderer />
          )}
        </FormGroup>
        <LoadingRenderer actions={actions} />
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors', 'referring_user', 'trialsite']}
          showStatusErrors
        />
        <hr />
        <div className="form-buttons">
          <Button
            type="submit"
            className="primary pill"
            disabled={submitting || isLoading || pristine}
          >
            {t('buttons.add-patient')}
          </Button>
        </div>
      </form>
    );
  }

  private getDetailComponent = () => {
    const { t, statusDetailValue } = this.props;

    switch (statusDetailValue) {
      case PatientStatus.InProgress:
        const inProgressDetailOptions = [
          {
            label: t(`details.placeholder-in-progress`),
            value: '',
            disabled: true,
          },
          ...DynamicDropdownOptions.InProgress.map(option => ({
            label: t(`details.${option}`),
            value: option,
          })),
        ];

        return (
          <Field
            label={t('fields.status_detail')}
            name="status_detail"
            component={RenderDropdown}
            options={inProgressDetailOptions}
            type="select"
          />
        );
      case PatientStatus.ScreeningBooked:
        return (
          <Field
            label={t('fields.status_detail')}
            placeholder={t('details.placeholder-screening-booked')}
            name="status_detail"
            component={RenderDateField}
            minDate={moment()}
            dateFieldFormat={SCREENING_BOOKED_DATE_FORMAT}
            validate={dateInFuture}
          />
        );
      case PatientStatus.Declined:
        const declinedDetailOptions = [
          {
            label: t(`details.placeholder-declined`),
            value: '',
            disabled: true,
          },
          ...DynamicDropdownOptions.Declined.map(option => ({
            label: t(`details.${option}`),
            value: option,
          })),
        ];

        return (
          <Field
            label={t('fields.status_detail')}
            name="status_detail"
            component={RenderDropdown}
            options={declinedDetailOptions}
            type="select"
          />
        );
      default:
        return null;
    }
  };
}

export const mapStateToProps = (state: StoreState): ConnectedProps => {
  const { patientSources } = state;
  return {
    patientSources,
    statusDetailValue: selector(state, 'status'),
  };
};

export default reduxForm<Patient, OwnProps>({
  form: 'createPatientForm',
  destroyOnUnmount: true,
})(
  compose<Props, OwnProps & InjectedFormProps<Patient, OwnProps>>(
    connect(mapStateToProps, { getPatientSources }),
    withTranslation('patients')
  )(PatientFormCreate)
);
