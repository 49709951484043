import { Button, FormGroup } from '@dabapps/roe';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import ErrorRenderer from '^/common/components/form/error-renderer';
import RenderField from '^/common/components/form/render-field';
import { LOGIN } from '^/login/login-actions';

const actions = [LOGIN];

export interface FormValues {
  username: string;
  password: string;
}

export type LoginFormProps = InjectedFormProps<FormValues, {}> &
  WithTranslation;

class LoginForm extends React.Component<LoginFormProps> {
  public render() {
    const { t, handleSubmit, submitting, pristine } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div>
          <FormGroup>
            <Field
              label={t('fields.email')}
              placeholder="email@address.com"
              name="email"
              type="email"
              component={RenderField}
            />
            <Field
              label={t('fields.password')}
              placeholder={t('fields.password')}
              name="password"
              type="password"
              component={RenderField}
            />
          </FormGroup>
        </div>
        <ErrorRenderer actions={actions} fields={['non_field_errors']} />
        <hr />
        <div className="form-buttons">
          <Button
            type="submit"
            className="primary pill"
            disabled={submitting || pristine}
          >
            {t('buttons.login')}
          </Button>
          <Link to="/password-reset">
            <Button
              className="primary pill hollow"
              type="button"
              disabled={submitting}
            >
              {t('buttons.forgot-password')}
            </Button>
          </Link>
        </div>
      </form>
    );
  }
}

export { LoginForm as TestableLoginForm };

export default reduxForm<FormValues, {}>({ form: 'loginForm' })(
  withTranslation('login')(LoginForm)
);
