import classNames from 'classnames';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Flag from 'react-world-flags';

interface OwnProps {
  languageCodes: ReadonlyArray<string>;
}

export type LanguagePickerProps = OwnProps & WithTranslation;

const LanguagePicker: React.FC<LanguagePickerProps> = ({
  i18n,
  languageCodes,
}) => {
  const selectedLanguage = mapLanguageToCountry(i18n.language);
  const languages = [
    ...new Set([...languageCodes, ...i18n.languages]),
  ].sort((a, b) => (a === i18n.language ? 1 : b === i18n.language ? -1 : 0));

  const handleLanguageSelect = (lng: string) => i18n.changeLanguage(lng);

  if (languageCodes.length <= 1) {
    return null;
  }

  return (
    <span className="language-picker">
      <span className="language">
        {selectedLanguage.name}{' '}
        <Flag code={selectedLanguage.country} height="16" />
      </span>

      <ul className="languages">
        {languages.map(lng => (
          <LanguageItem key={lng} lng={lng} onSelect={handleLanguageSelect} />
        ))}
      </ul>
    </span>
  );
};

export interface LanguageItemProps {
  lng: string;
  onSelect(lng: string): void;
}

const LanguageItem: React.FC<LanguageItemProps> = ({ lng, onSelect }) => {
  const language = mapLanguageToCountry(lng);

  const handleSelect = () => language.available && onSelect(lng);

  return (
    <li
      className={classNames('language', {
        disabled: !language.available,
      })}
      title={language.available ? undefined : 'Language not yet available'}
      onClick={handleSelect}
    >
      {language.name} <Flag code={language.country} height="14" />
    </li>
  );
};

function mapLanguageToCountry(language: string) {
  switch (language) {
    case 'en':
    case 'en-GB':
      return { language, country: 'gb', name: 'English', available: true };
    case 'fr':
      return { language, country: 'fr', name: 'Français', available: false };
    case 'es':
      return { language, country: 'es', name: 'Español', available: false };
    case 'ru':
      return { language, country: 'ru', name: 'Русский', available: false };
    case 'pt':
      return { language, country: 'pt', name: 'Português', available: false };
    case 'de':
      return { language, country: 'de', name: 'Deutsch', available: true };
    default:
      return { language, country: language, name: language, available: false };
  }
}

export {
  LanguagePicker as TestableLanguagePicker,
  LanguageItem as TestableLanguageItem,
};

export default withTranslation()(LanguagePicker);
