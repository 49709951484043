import {
  dispatchGenericRequest,
  makeAsyncActionSet,
} from '@dabapps/redux-requests';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';

import { Trial, TrialRecordForApi } from '^/trials/trial-types';

const TRIALS_ENDPOINT = '/api/trials/';

export const GET_ALL_TRIALS = makeAsyncActionSet('GET_ALL_TRIALS');
export function getAllTrials() {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      GET_ALL_TRIALS,
      TRIALS_ENDPOINT,
      'GET'
    )(dispatch).catch(() => null);
  };
}

export const UPDATE_TRIAL = makeAsyncActionSet('UPDATE_TRIAL');
export function updateTrial(id: string, trial: Trial) {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      UPDATE_TRIAL,
      `${TRIALS_ENDPOINT}${id}/`,
      'POST',
      TrialRecordForApi(trial)
    )(dispatch)
      .then(() => {
        return dispatch(push('/trials'));
      })
      .catch(() => null);
  };
}

export const CREATE_TRIAL = makeAsyncActionSet('CREATE_TRIAL');
export function createTrial(trial: Trial) {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      CREATE_TRIAL,
      TRIALS_ENDPOINT,
      'POST',
      TrialRecordForApi(trial)
    )(dispatch)
      .then(response => {
        dispatch(push(`/trials/${response.data.id}`));
        return getAllTrials()(dispatch);
      })
      .catch(() => null);
  };
}

export const SET_TRIAL_SEARCH_TERM = 'SET_TRIAL_SEARCH_TERM';
export const setTrialSearchTerm = (term: string | null) => ({
  type: SET_TRIAL_SEARCH_TERM,
  payload: term,
});

export const TOGGLE_ADD_TRIAL = 'TOGGLE_ADD_TRIAL';
export const toggleAddTrial = (expanded?: boolean) => ({
  type: TOGGLE_ADD_TRIAL,
  payload: expanded,
});
