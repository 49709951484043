import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface Props {
  icon: IconProp;
  subHeader?: string;
  link: string;
}

export class DownloadTabCard extends React.PureComponent<Props> {
  public render() {
    const { icon, subHeader, link, children } = this.props;

    return (
      <a href={link}>
        <div className="tab-section">
          <FontAwesomeIcon size="2x" icon={icon} />
          <div className="tab-header">{children}</div>
          <div className="tab-last-updated">{subHeader}</div>
        </div>
      </a>
    );
  }
}

export default DownloadTabCard;
