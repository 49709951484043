import { Button } from '@dabapps/roe';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment';
import React, { ChangeEvent } from 'react';
import ReactDatePicker from 'react-datepicker';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  DATE_FORMAT_DISPLAY,
  SCREENING_BOOKED_DATE_FORMAT,
} from '^/common/constants';
import DropdownOptions from '^/common/helpers/dropdown-options';
import { PatientStatus } from '^/patients/patient-types';

interface OwnProps {
  onClick: () => void;
  onStatusDetailChange: (statusDetail: string) => void;
  statusDetail: string;
  selected: boolean;
  status: PatientStatus;
  disabled: boolean;
}

interface State {
  selectedDate: moment.Moment | null;
}

export type PatientButtonStatusProps = OwnProps & WithTranslation;

class PatientButtonStatus extends React.PureComponent<
  PatientButtonStatusProps,
  State
> {
  constructor(props: PatientButtonStatusProps) {
    super(props);

    this.state = {
      selectedDate: null,
    };
  }

  public render() {
    const { t, selected, status, disabled } = this.props;

    const id = this.generateId(status);
    const classes = this.getClasses(status);
    const detailComponent = this.getDetailComponent(status);

    return (
      <div className="status-button-input">
        <Button
          id={id}
          className={classNames(
            classes,
            {
              hollow: !selected,
            },
            { selected },
            { ['has-component']: detailComponent }
          )}
          onClick={this.props.onClick}
          disabled={disabled}
        >
          {selected && <FontAwesomeIcon icon={faCheck} size="2x" />}
          <span>{t(`statuses.${status}`)}</span>
        </Button>
        {selected && detailComponent}
      </div>
    );
  }

  public onStatusDetailChanged = (event: ChangeEvent<HTMLSelectElement>) => {
    this.props.onStatusDetailChange(event.currentTarget.value);
  };

  public onScreeningBookedChanged = (selectedDate: Date | null) => {
    if (!selectedDate) {
      return;
    }

    const momentDate = moment(selectedDate, DATE_FORMAT_DISPLAY);

    this.setState({
      selectedDate: momentDate,
    });
    this.props.onStatusDetailChange(
      momentDate.format(SCREENING_BOOKED_DATE_FORMAT)
    );
  };

  public handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = moment(event.currentTarget.value, DATE_FORMAT_DISPLAY);

    if (value.isValid()) {
      this.onScreeningBookedChanged(value.toDate());
    }
  };

  private generateId(status: PatientStatus) {
    return status.toLowerCase().replace(' ', '-');
  }

  private getClasses(status: PatientStatus) {
    switch (status) {
      case PatientStatus.New:
      case PatientStatus.InProgress:
      case PatientStatus.ScreeningBooked:
        return 'secondary';
      case PatientStatus.ScreeningComplete:
        return 'tertiary';
      case PatientStatus.Declined:
      default:
        return '';
    }
  }

  private getDetailComponent = (status: PatientStatus) => {
    const { t, statusDetail } = this.props;

    switch (status) {
      case PatientStatus.InProgress:
        return (
          <select onChange={this.onStatusDetailChanged} value={statusDetail}>
            <option disabled value="">
              {t('details.placeholder-in-progress')}
            </option>
            {DropdownOptions.InProgress.map((option: string) => (
              <option key={option} value={option}>
                {t(`details.${option}`)}
              </option>
            ))}
          </select>
        );
      case PatientStatus.ScreeningBooked:
        return (
          <ReactDatePicker
            selected={this.state.selectedDate?.toDate()}
            placeholderText={t('details.placeholder-screening-booked')}
            dateFormat="dd MMM yyyy"
            onChange={this.onScreeningBookedChanged}
            autoComplete="off"
            onBlur={this.handleBlur}
            minDate={new Date()}
          />
        );
      case PatientStatus.Declined:
        return (
          <select onChange={this.onStatusDetailChanged} defaultValue="label">
            <option disabled value="label">
              {t('details.placeholder-declined')}
            </option>
            {DropdownOptions.Declined.map((option: string) => (
              <option key={option} value={option}>
                {t(`details.${option}`)}
              </option>
            ))}
          </select>
        );
      default:
        return null;
    }
  };
}

export { PatientButtonStatus as TestableButtonStatus };

export default withTranslation('patients')(PatientButtonStatus);
