import { ResponsiveBar } from '@nivo/bar';
import moment from 'moment';
import React from 'react';

import { InjectedWidgetProps } from '^/reports/components/hoc/create-widget';
import { CalendarDatum, ScreeningsBookedData } from '.';

type Props = InjectedWidgetProps<ScreeningsBookedData>;

const CalendarGraph = (props: Props) => {
  const dataIsLarge = props.data.screenings.length > 8;
  const formattedData: CalendarDatum[] = props.data.monthly_booking_counts.map(
    p => {
      const parsedDate = moment(p.date || 'invalid', 'YYYY-MM-DD');

      return {
        ...p,
        date: parsedDate.isValid ? parsedDate.format('MMM YY') : p.date,
      };
    }
  );

  return (
    <div className="responsive-graph">
      <ResponsiveBar
        data={formattedData}
        keys={['count']}
        indexBy="date"
        margin={{ top: 40, right: 40, bottom: dataIsLarge ? 80 : 60, left: 40 }}
        padding={0.3}
        colors={['#00a4e4']}
        groupMode="grouped"
        labelTextColor={'#FFF'}
        labelSkipWidth={12}
        isInteractive={false}
        labelSkipHeight={12}
        axisBottom={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: dataIsLarge ? -90 : 0,
        }}
      />
    </div>
  );
};

export default CalendarGraph;
