import { Dispatch, Middleware } from 'redux';

const checkForLogoutMiddleware: Middleware = () => (
  next: Dispatch
) => action => {
  if (action.payload && action.payload.response) {
    const response = action.payload.response;
    if (response.status === 401) {
      window.location.replace(`/login?next=${window.location.pathname}`);
    } else if (response.status === 403) {
      window.location.replace(`/`);
    }
  }

  return next(action);
};

export default checkForLogoutMiddleware;
