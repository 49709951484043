import { makeAsyncActionSet } from '@dabapps/redux-requests';
import { faChartBar } from '@fortawesome/pro-light-svg-icons';
import { ResponsiveSankey } from '@nivo/sankey';
import React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import compose from 'recompose/compose';

import {
  createWidget,
  InjectedWidgetProps,
} from '^/reports/components/hoc/create-widget';
import {
  datumIsOperationalStatusDataLinkWithRealTargetAndSourceNodes,
  LinkTooltip,
  NodeLabel,
  NodeTooltip,
  OperationalStatusData,
  OperationalStatusDataLinkWithRealTargetAndSourceNodes,
  OperationalStatusDataNode,
} from '^/reports/components/widgets/operational-status/operational-status-types';

type Props = InjectedWidgetProps<OperationalStatusData> & WithTranslation;

const LINK_OPACITY = 0.8;
const NODE_OPACITY = 0.7;
const NODE_HOVER_OPACITY = 1;
const DEFAULT_BLACK = '#000000';

export class OperationalStatus extends React.PureComponent<Props> {
  public render() {
    const { t, data } = this.props;

    if (!data.links.length || !data.nodes.length) {
      return <p>{t('empty-state')}</p>;
    }

    return (
      <div className="operational-status-container">
        <ResponsiveSankey
          data={data}
          linkOpacity={LINK_OPACITY}
          linkHoverOpacity={LINK_OPACITY}
          linkHoverOthersOpacity={LINK_OPACITY}
          nodeOpacity={NODE_OPACITY}
          nodeHoverOpacity={NODE_HOVER_OPACITY}
          nodeHoverOthersOpacity={NODE_OPACITY}
          nodeBorderWidth={0}
          nodeTooltip={this.nodeTooltip}
          linkTooltip={this.linkTooltip}
          labelOrientation={'horizontal'}
          labelTextColor={'black'}
          label={this.labelFormat}
          colors={this.getColorForNode}
        />
      </div>
    );
  }

  public getAdditionalDataForNodeIfExists(nodeLabel: string) {
    if (this.props.data.additional_data.hasOwnProperty(nodeLabel)) {
      return this.props.data.additional_data[nodeLabel];
    }

    return null;
  }

  public nodeTooltip = (node: NodeTooltip) => {
    const { t } = this.props;

    const nodeLabel = node.label
      .toLowerCase()
      .split('(')[0]
      .trim();

    const additionalDataForNode = this.getAdditionalDataForNodeIfExists(
      nodeLabel
    );

    return (
      <div>
        <strong>
          {t('reports:operational-status.total')} {nodeLabel}: {node.value}
        </strong>
        {additionalDataForNode &&
          Object.keys(additionalDataForNode).map(additionalDataKey => (
            <div>
              {additionalDataKey}: {additionalDataForNode[additionalDataKey]}
            </div>
          ))}
      </div>
    );
  };

  public linkTooltip = (link: LinkTooltip) => {
    const { t } = this.props;

    return (
      <div>
        {t('patients:statuses.' + link.source.id)} >{' '}
        {t('patients:statuses.' + link.target.id)}: {link.value}
      </div>
    );
  };

  public labelFormat = (node: NodeLabel) => {
    const { t } = this.props;

    return `${t('patients:statuses.' + node.id).toUpperCase()} (${node.value})`;
  };

  public getColorForNode(
    datum:
      | OperationalStatusDataNode
      | OperationalStatusDataLinkWithRealTargetAndSourceNodes
  ) {
    if (datumIsOperationalStatusDataLinkWithRealTargetAndSourceNodes(datum)) {
      return datum.source.transitionColor || DEFAULT_BLACK;
    } else {
      return datum.color;
    }
  }
}

const GET_OPERATIONAL_STATUS_DATA = makeAsyncActionSet(
  'GET_OPERATIONAL_STATUS_DATA'
);

export default compose<Props, {}>(
  createWidget<OperationalStatusData>({
    icon: faChartBar,
    title: (
      <Trans i18nKey="reports:operational-status.heading">
        Operational Status
      </Trans>
    ),
    url: '/api/reports/operational-status/',
    actionSet: GET_OPERATIONAL_STATUS_DATA,
    halfWidth: false,
  }),
  withTranslation(['reports', 'patients'])
)(OperationalStatus);
