import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Field } from 'redux-form';

import LoadingRenderer from '^/common/components/form/loading-renderer';
import RenderDropdown, {
  DropdownOptions,
} from '^/common/components/form/render-dropdown';
import RenderField from '^/common/components/form/render-field';
import { StoreState } from '^/common/types';
import { getUserRoles } from '^/roles/role-actions';

interface DispatchProps {
  getUserRoles: typeof getUserRoles;
}

interface ConnectedProps {
  roles?: StoreState['roles'];
}

export type Props = DispatchProps & ConnectedProps & WithTranslation;

const required = (value: string) => (value ? undefined : 'Required');
const email = (value: string) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export class UserFormGroups extends React.PureComponent<Props> {
  public componentDidMount() {
    if (!this.props.roles || this.props.roles.length <= 0) {
      this.props.getUserRoles();
    }
  }

  public render() {
    const { t, roles } = this.props;

    if (!roles) {
      return <LoadingRenderer />;
    }

    const roleOptions: DropdownOptions = roles.map(role => {
      return { label: t(`roles.${role.id}`), value: role.id };
    });

    return (
      <FormGroup>
        <Field
          label={t('fields.full_name')}
          name="full_name"
          placeholder={t('fields.full_name')}
          component={RenderField}
          type="text"
          validate={[required]}
        />
        <Field
          label={t('fields.email')}
          placeholder="email@address.com"
          name="email"
          component={RenderField}
          type="text"
          validate={[email]}
        />
        <Field
          label={t('fields.sso-id')}
          name="sso_id"
          placeholder={t('fields.sso-id')}
          component={RenderField}
          type="text"
        />
        <Field
          label={t('fields.impact-id')}
          name="impact_id"
          placeholder={t('fields.impact-id')}
          component={RenderField}
          type="text"
        />
        <Field
          label={t('fields.roles')}
          name="roles"
          type="select"
          component={RenderDropdown}
          options={roleOptions}
        />
        <Field
          name="active"
          component={RenderField}
          type="checkbox"
          label={t('fields.active')}
          sideLabel={t('fields.active-label')}
        />
      </FormGroup>
    );
  }
}

const mapStateToProps = ({ roles }: StoreState): ConnectedProps => {
  return {
    roles,
  };
};

export default compose<Props, {}>(
  connect(mapStateToProps, { getUserRoles }),
  withTranslation('users')
)(UserFormGroups);
