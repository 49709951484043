import { Container, ContentBox, ContentBoxHeader, Section } from '@dabapps/roe';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import Footer from '^/common/components/containers/footer/footer';
import * as PrivacyCopy from '^/common/components/containers/footer/privacy-copy';
import NovoNavigation from '^/common/components/navigation/novo-navigation';

export class PrivacyPage extends React.PureComponent<WithTranslation> {
  public render() {
    const { t } = this.props;

    return (
      <Container>
        <NovoNavigation showLogin={false} />
        <ContentBox>
          <ContentBoxHeader>
            <h2>{t('heading')}</h2>
          </ContentBoxHeader>
          <Section className="privacy-terms">
            {PrivacyCopy.OPENING_PARAGRAPH}
            <ol>
              {PrivacyCopy.LIST_ITEM_1}
              {PrivacyCopy.LIST_ITEM_2}
              {PrivacyCopy.LIST_ITEM_3}
              {PrivacyCopy.LIST_ITEM_4}
              {PrivacyCopy.LIST_ITEM_5}
              {PrivacyCopy.LIST_ITEM_6}
              {PrivacyCopy.LIST_ITEM_7}
              {PrivacyCopy.LIST_ITEM_8}
              {PrivacyCopy.LIST_ITEM_9}
              {PrivacyCopy.LIST_ITEM_10}
              {PrivacyCopy.LIST_ITEM_11}
            </ol>
          </Section>
        </ContentBox>
        <Footer />
      </Container>
    );
  }
}

export default withTranslation('privacy')(PrivacyPage);
