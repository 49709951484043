import classNames from 'classnames';
import { Moment } from 'moment';
import React from 'react';

interface Props {
  date: Moment;
  eventCount: number;
  isInMonth: boolean;
}

export const CalendarCell = (props: Props) => {
  return (
    <div
      className={classNames('cell-inner', {
        'is-not-in-month': !props.isInMonth,
        'has-events': !!props.eventCount,
      })}
    >
      <div>{props.date.format('D')}</div>
      {Boolean(props.eventCount) && (
        <div className="events">{props.eventCount} &bull;</div>
      )}
    </div>
  );
};

export default CalendarCell;
