import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { Button, Modal, ModalFooter, ModalHeader } from '@dabapps/roe';
import moment from 'moment';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import ErrorRenderer from '^/common/components/form/error-renderer';
import LoadingRenderer from '^/common/components/form/loading-renderer';
import { StoreState } from '^/common/types';
import { hideModal } from '^/modals/modal-actions';
import { ModalType } from '^/modals/modal-types';
import PatientButtonStatus from '^/patients/components/patient-button-status';
import { UPDATE_PATIENT, updatePatient } from '^/patients/patient-actions';
import {
  Patient,
  PatientRecord,
  PatientStatus,
} from '^/patients/patient-types';

const actions = [UPDATE_PATIENT];

const PATIENT_STATUSES = [
  PatientStatus.InProgress,
  PatientStatus.ScreeningBooked,
  PatientStatus.ScreeningComplete,
  PatientStatus.Declined,
];

interface ConnectedProps {
  patient?: Patient;
}

interface DispatchProps {
  updatePatient: typeof updatePatient;
  hideModal: typeof hideModal;
}

export interface State {
  error?: string;
  status: PatientStatus;
  statusDetail: string;
}

export type Props = SetPropsInterface<State> &
  ConnectedProps &
  DispatchProps &
  WithTranslation;

export class UpdateStatusModal extends React.PureComponent<Props> {
  public constructor(props: Props) {
    super(props);
  }

  public getPatientStatusIndex(status: string) {
    return PATIENT_STATUSES.findIndex(
      (potentialStatus: string) => status === potentialStatus
    );
  }

  public getIsStatusDeclinedOrScreeningComplete(status: string) {
    return Boolean(
      [PatientStatus.Declined, PatientStatus.ScreeningComplete].find(
        (potentialStatus: string) => status === potentialStatus
      )
    );
  }

  public getButtonStatusDisabledState(buttonStatus: string) {
    const { patient } = this.props;

    if (!patient) {
      return true;
    }

    const currentStatusIndex = this.getPatientStatusIndex(patient.status);
    const buttonStatusIndex = this.getPatientStatusIndex(buttonStatus);

    const currentStatusIsDeclinedOrScreeningComplete = this.getIsStatusDeclinedOrScreeningComplete(
      patient.status
    );

    return (
      currentStatusIsDeclinedOrScreeningComplete ||
      buttonStatusIndex < currentStatusIndex
    );
  }

  public render() {
    const { t, patient } = this.props;

    if (!patient) {
      return (
        <Modal onClickOutside={this.onCancel}>
          <div>{t('errors.cannot-find-patient')}</div>
        </Modal>
      );
    }

    const dirty =
      patient.status !== this.props.status ||
      patient.status_detail !== this.props.statusDetail;

    return (
      <Modal onClickOutside={this.onCancel}>
        <ModalHeader>
          <div className="card-header-button" />
          <h1>
            {dirty ? t('headings.update-status') : t('headings.confirm-status')}
          </h1>
          <button
            id="close-modal"
            className="card-header-button"
            onClick={this.onCancel}
          >
            {String.fromCharCode(215)}
          </button>
        </ModalHeader>
        <hr />
        <div className="status-button-list">
          {PATIENT_STATUSES.map(status => (
            <PatientButtonStatus
              key={status}
              status={status}
              selected={this.props.status === status}
              onClick={this.setStatus.bind(this, status)}
              statusDetail={this.props.statusDetail}
              onStatusDetailChange={this.setStatusDetail}
              disabled={this.getButtonStatusDisabledState(status)}
            />
          ))}
        </div>
        <LoadingRenderer actions={actions} tag={patient.id} />
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors', 'status', 'status_detail']}
          formErrors={this.props.error ? [this.props.error] : undefined}
          showStatusErrors
          tag={patient.id}
        />
        <ModalFooter className="buttons">
          <Button
            id="update-status"
            className="primary pill"
            onClick={this.onUpdatePatient.bind(this, patient)}
          >
            {dirty ? t('buttons.update-status') : t('buttons.confirm-status')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  public setStatusDetail = (statusDetail: string) => {
    this.props.setProps({
      error: undefined,
      statusDetail,
    });
  };

  public setStatus = (status: PatientStatus) => {
    this.props.setProps({
      error: undefined,
      status,
      statusDetail: undefined,
    });
  };

  public onCancel = () => {
    this.props.hideModal(ModalType.UpdatePatientStatus);
  };

  public onUpdatePatient = (patient: Patient) => {
    if (this.props.status === PatientStatus.ScreeningBooked) {
      const date = moment(this.props.statusDetail, 'DD/MM/YYYY');

      if (!date.isValid() || date.isBefore(moment(), 'day')) {
        this.props.setProps({
          error: 'Screening booked date must be in the future',
        });
        return;
      }
    }

    this.props.updatePatient(
      patient.id,
      PatientRecord({
        ...patient,
        status: this.props.status,
        status_detail: this.props.statusDetail,
      })
    );
  };
}

export const getInitialProps = (props: ConnectedProps) => ({
  status:
    props.patient && props.patient.status
      ? props.patient.status
      : PatientStatus.InProgress,
  statusDetail:
    props.patient && props.patient.status_detail
      ? props.patient.status_detail
      : '',
});

export const mapStateToProps = ({
  sharedModals: modals,
}: StoreState): ConnectedProps => {
  return {
    patient: modals[ModalType.UpdatePatientStatus].patient,
  };
};

export default compose<Props, {}>(
  connect(mapStateToProps, {
    updatePatient,
    hideModal,
  }),
  withSetProps<State, ConnectedProps>(getInitialProps),
  withTranslation('patients')
)(UpdateStatusModal);
