import {
  anyPending,
  AsyncActionSet,
  isPending,
  ResponsesReducerState,
} from '@dabapps/redux-requests';
import React from 'react';
import { connect } from 'react-redux';

import LoadingSpinner from '^/common/components/loading-spinner';
import { StoreState } from '^/common/types';

interface OwnProps {
  actions?: ReadonlyArray<AsyncActionSet>;
  tag?: string;
}

interface ConnectedProps {
  responses: ResponsesReducerState;
}

type Props = OwnProps & ConnectedProps;

export class LoadingRenderer extends React.PureComponent<Props> {
  public render() {
    const { actions, responses, tag } = this.props;

    if (!actions) {
      return <LoadingSpinner />;
    }

    if (actions.length === 1 && isPending(responses, actions[0], tag)) {
      return <LoadingSpinner />;
    }

    if (anyPending(responses, actions)) {
      return <LoadingSpinner />;
    }

    return null;
  }
}

const mapStateToProps = ({ responses }: StoreState): ConnectedProps => {
  return {
    responses,
  };
};

export default connect(mapStateToProps)(LoadingRenderer);
