import { FormGroup } from '@dabapps/roe';
import moment from 'moment';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Field, formValueSelector } from 'redux-form';

import DatePickerField from '^/common/components/form/date-picker-field';
import RenderField from '^/common/components/form/render-field';
import { StoreState } from '^/common/types';
import { TrialInput } from '^/trials/trial-types';

export const validate = (values: TrialInput) => {
  const errors: { [key: string]: string } = {};

  if (!values.fpfv_planned) {
    errors.fpfv_planned = 'Required';
  }

  if (!values.lpfv_planned) {
    errors.lpfv_planned = 'Required';
  }

  if (!values.global_trial_id) {
    errors.global_trial_id = 'Required';
  }

  if (
    values.lpfv_planned &&
    values.fpfv_planned &&
    moment(values.lpfv_planned).isBefore(values.fpfv_planned)
  ) {
    errors.lpfv_planned = 'Must be after FPFV Planned date';
  }

  return errors;
};

interface OwnProps {
  formName: string;
}

interface ConnectedProps {
  startDate?: string;
  endDate?: string;
}

export type TrialFormGroupsProps = OwnProps & ConnectedProps & WithTranslation;

class TrialFormGroups extends React.PureComponent<TrialFormGroupsProps> {
  public render() {
    const { t, startDate, endDate } = this.props;

    return (
      <FormGroup>
        <FormGroup>
          <Field
            label={t('fields.global_trial_id')}
            placeholder="eg. NNXXXX-XXXX"
            name="global_trial_id"
            component={RenderField}
            type="text"
          />
        </FormGroup>
        <DatePickerField
          startFieldName="fpfv_planned"
          endFieldName="lpfv_planned"
          startDate={startDate}
          endDate={endDate}
        />
        <FormGroup>
          <Field
            name="active"
            component={RenderField}
            type="checkbox"
            label={t('fields.active-label')}
            sideLabel={t('fields.active')}
          />
        </FormGroup>
      </FormGroup>
    );
  }
}

const mapStateToProps = (state: StoreState, props: OwnProps) => {
  const selector = formValueSelector(props.formName);

  return {
    startDate: selector(state, 'fpfv_planned'),
    endDate: selector(state, 'lpfv_planned'),
  };
};

export default compose<TrialFormGroupsProps, OwnProps>(
  connect(mapStateToProps),
  withTranslation('trials')
)(TrialFormGroups);
