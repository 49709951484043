import {
  Section,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import compose from 'recompose/compose';

import { StoreState } from '^/common/types';
import { getUserRoles } from '^/roles/role-actions';
import UserRow from '^/users/components/user-row';
import { getAllUsers } from '^/users/user-actions';
import { User } from '^/users/user-types';

interface DispatchProps {
  getAllUsers: typeof getAllUsers;
  getUserRoles: typeof getUserRoles;
}

interface ConnectedProps {
  isAdmin: boolean;
  userFilterTerm: string | null;
  userList: ReadonlyArray<User>;
  userSearchTerm: string | null;
}

export type Props = ConnectedProps & DispatchProps & WithTranslation;

export class UserTable extends React.PureComponent<Props> {
  public componentDidMount() {
    this.props.getAllUsers();
  }

  public render() {
    const { t, isAdmin, userList } = this.props;

    const filteredUsers = userList.filter(user => this.shouldShowUser(user));
    if (filteredUsers.length <= 0) {
      return (
        <Section>
          <p>{t('empty-state')}</p>
        </Section>
      );
    }
    return (
      <Table fixed>
        <TableHead>
          <TableRow>
            <TableHeader>{t('fields.full_name')}</TableHeader>
            <TableHeader>{t('fields.email')}</TableHeader>
            <TableHeader>{t('fields.sso-id')}</TableHeader>
            <TableHeader>{t('fields.impact-id')}</TableHeader>
            <TableHeader style={{ width: '125px' }}>
              {t('fields.roles')}
            </TableHeader>
            <TableHeader style={{ width: '125px' }}>
              {t('fields.active')}
            </TableHeader>
            {isAdmin && <TableHeader style={{ width: '75px' }} />}
          </TableRow>
        </TableHead>
        <TransitionGroup component={TableBody}>
          {filteredUsers.map(user => (
            <CSSTransition
              key={user.id}
              classNames="table-row-animation"
              timeout={{ enter: 1000, exit: 200 }}
            >
              <UserRow user={user} allowEdit={isAdmin} />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </Table>
    );
  }

  private shouldShowUser = (user: User) => {
    const { userSearchTerm, userFilterTerm } = this.props;
    if (!userSearchTerm && !userFilterTerm) {
      return true;
    }

    const nameMatch = this.stringMatches(userSearchTerm, user.full_name);

    const ssoIdMatch = this.stringMatches(userSearchTerm, user.sso_id);

    const impactIdMatch = this.stringMatches(userSearchTerm, user.impact_id);

    const roleMatch = this.stringMatches(userFilterTerm, user.roles);

    return (nameMatch || ssoIdMatch || impactIdMatch) && roleMatch;
  };

  private stringMatches = (
    searchTerm: string | null,
    stringToMatch?: string | null
  ): boolean => {
    if (!searchTerm) {
      return true;
    } else if (!stringToMatch) {
      return false;
    } else {
      return stringToMatch.toUpperCase().includes(searchTerm.toUpperCase());
    }
  };
}

export const mapStateToProps = ({
  isAdmin,
  userFilterTerm,
  userList,
  userSearchTerm,
}: StoreState): ConnectedProps => {
  return {
    isAdmin,
    userFilterTerm,
    userList,
    userSearchTerm,
  };
};

export default compose<Props, {}>(
  connect(mapStateToProps, { getAllUsers, getUserRoles }),
  withTranslation('users')
)(UserTable);
