import { FormGroup } from '@dabapps/roe';
import moment from 'moment';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Field } from 'redux-form';

import RenderDateField from '^/common/components/form/render-date-field';
import { DATE_FORMAT_ISO } from '^/common/constants';

interface OwnProps {
  disabled?: boolean;
  startFieldName: string;
  endFieldName: string;
  startDate?: string;
  endDate?: string;
}

export type DatePickerFieldProps = OwnProps & WithTranslation;

// tslint:disable-next-line:no-any
export const validateDefined = (value: any) => (value ? undefined : 'Required');

class DatePickerField extends React.PureComponent<DatePickerFieldProps> {
  public render() {
    const { t, disabled, startFieldName, endFieldName } = this.props;
    const startDate = this.props.startDate
      ? moment(this.props.startDate, DATE_FORMAT_ISO)
      : undefined;
    const endDate = this.props.endDate
      ? moment(this.props.endDate, DATE_FORMAT_ISO)
      : undefined;

    return (
      <FormGroup>
        <Field
          label={t(`fields.${startFieldName}`)}
          placeholder={t(`fields.${startFieldName}`)}
          name={startFieldName}
          component={RenderDateField}
          disabled={disabled}
          validate={[validateDefined]}
          maxDate={endDate}
        />
        <Field
          label={t(`fields.${endFieldName}`)}
          placeholder={t(`fields.${endFieldName}`)}
          name={endFieldName}
          component={RenderDateField}
          disabled={disabled}
          validate={[validateDefined]}
          minDate={startDate}
        />
      </FormGroup>
    );
  }
}

export { DatePickerField as TestableDatePickerField };

export default withTranslation('trials')(DatePickerField);
