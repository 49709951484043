import { Container, ContentBox, ContentBoxHeader } from '@dabapps/roe';
import queryString from 'query-string';
import React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';

import Footer from '^/common/components/containers/footer/footer';
import NovoNavigation from '^/common/components/navigation/novo-navigation';
import PasswordResetFormConfirm, {
  FormValues,
} from '^/password-reset/components/password-reset-form-confirm';
import { resetPasswordConfirm } from '^/password-reset/password-reset-actions';
import { OwnProps } from '^/trialsites/components/trialsite-add-user-card';

interface DispatchProps {
  resetPasswordConfirm: typeof resetPasswordConfirm;
}

export type Props = DispatchProps & RouteComponentProps<{}> & WithTranslation;

export class PasswordResetPageConfirm extends React.PureComponent<Props> {
  public onPasswordReset = (values: FormValues) => {
    const query = queryString.parse(this.props.location.search);
    this.props.resetPasswordConfirm(
      query.uid as string,
      query.token as string,
      values
    );
  };

  public render() {
    const {
      t,
      location: { search },
    } = this.props;
    const { uid, token } = queryString.parse(search);

    return (
      <Container>
        <NovoNavigation showLogin={false} />
        <ContentBox>
          <ContentBoxHeader>
            <h2>{t('login:change-password-heading')}</h2>
          </ContentBoxHeader>
          {uid && token ? (
            <PasswordResetFormConfirm onSubmit={this.onPasswordReset} />
          ) : (
            <div className="errors">
              {!uid && (
                <p className="error">{t(`errors:others.uid-missing`)}</p>
              )}
              {!token && (
                <p className="error">{t(`errors:others.token-missing`)}</p>
              )}
              <p className="error">
                <Trans i18nKey="errors:others.invalid-link">
                  Sorry! Looks like you've clicked an invalid link. Make sure
                  you've copied the entire link from the email or{' '}
                  <Link to="/password-reset">try again</Link>
                </Trans>
              </p>
            </div>
          )}
        </ContentBox>
        <Footer />
      </Container>
    );
  }
}

export default compose<Props, OwnProps>(
  connect(undefined, { resetPasswordConfirm }),
  withTranslation(['login', 'errors'])
)(PasswordResetPageConfirm);
