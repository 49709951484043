import {
  dispatchGenericRequest,
  makeAsyncActionSet,
} from '@dabapps/redux-requests';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';

import { hideModal } from '^/modals/modal-actions';
import { ModalType } from '^/modals/modal-types';
import {
  PICInformation,
  PICInformationRecordForApi,
} from '^/pic-information/pic-information-types';

const PIC_INFORMATION_ENDPOINT = '/api/pic/';

export const TOGGLE_ADD_PIC_INFORMATION = 'TOGGLE_ADD_PIC_INFORMATION';
export const toggleAddPICInformation = (expanded?: boolean) => ({
  type: TOGGLE_ADD_PIC_INFORMATION,
  payload: expanded,
});

export const GET_ALL_PIC_INFORMATION = makeAsyncActionSet(
  'GET_ALL_PIC_INFORMATION'
);
export function getAllPICInformation() {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      GET_ALL_PIC_INFORMATION,
      `${PIC_INFORMATION_ENDPOINT}`,
      'GET'
    )(dispatch);
  };
}

export const DELETE_PIC_INFORMATION = makeAsyncActionSet(
  'DELETE_PIC_INFORMATION'
);
export function deletePICInformation(picId: string) {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      DELETE_PIC_INFORMATION,
      `${PIC_INFORMATION_ENDPOINT}${picId}/`,
      'DELETE'
    )(dispatch)
      .then(() => {
        dispatch(hideModal(ModalType.DeletePICInformation));
        return dispatch(push('/pic'));
      })
      .catch(() => null);
  };
}

export const GET_PIC_INFORMATION_BY_TRIALSITE = makeAsyncActionSet(
  'GET_PIC_INFORMATION_BY_TRIALSITE'
);
export function getPICInformationByTrialsite(trialsiteId: string) {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      GET_PIC_INFORMATION_BY_TRIALSITE,
      `${PIC_INFORMATION_ENDPOINT}?trialsite=${trialsiteId}`,
      'GET'
    )(dispatch);
  };
}

export const CREATE_PIC_INFORMATION = makeAsyncActionSet(
  'CREATE_PIC_INFORMATION'
);
export function createPICInformation(pic: PICInformation) {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      CREATE_PIC_INFORMATION,
      PIC_INFORMATION_ENDPOINT,
      'POST',
      PICInformationRecordForApi(pic)
    )(dispatch)
      .then(() => {
        getAllPICInformation()(dispatch);
        return getPICInformationByTrialsite(pic.trialsite)(dispatch);
      })
      .catch(() => null);
  };
}

export const UPDATE_PIC_INFORMATION = makeAsyncActionSet(
  'UPDATE_PIC_INFORMATION'
);
export function updatePICInformation(id: string, pic: PICInformation) {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      UPDATE_PIC_INFORMATION,
      `${PIC_INFORMATION_ENDPOINT}${id}/`,
      'POST',
      PICInformationRecordForApi(pic)
    )(dispatch)
      .then(() => {
        getAllPICInformation()(dispatch);
        return dispatch(push('/pic'));
      })
      .catch(() => null);
  };
}
