import moment from 'moment';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { DATE_FORMAT_DISPLAY, DATE_FORMAT_ISO } from '^/common/constants';
import { ScreeningsData } from '.';

type Props = Partial<ScreeningsData> & WithTranslation;

const formatDate = (date: string) =>
  moment(date, DATE_FORMAT_ISO).format(DATE_FORMAT_DISPLAY);

const ScreeningsHeader = (props: Props) => {
  const { t } = props;

  return (
    <div className="screenings-header">
      <h2 className="screenings-title">
        {t('trial-id')}:{' '}
        {props.global_trial_id ? props.global_trial_id : t('all-trials')}
      </h2>
      <div className="screenings-header-info">
        <p className="screenings-site">
          {props.site_name ? props.site_name : t('all-sites')}
        </p>
        {props.start_date && props.end_date && (
          <p className="screenings-date">
            {`${formatDate(props.start_date)} - ${formatDate(props.end_date)}`}
          </p>
        )}
      </div>
    </div>
  );
};

export { ScreeningsHeader as TestableScreeningsHeader };

export default withTranslation('reports')(ScreeningsHeader);
