import {
  dispatchGenericRequest,
  makeAsyncActionSet,
} from '@dabapps/redux-requests';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';

const ACCOUNTS_ENDPOINT = '/api/accounts';

export const RESET_PASSWORD = makeAsyncActionSet('RESET_PASSWORD');
export function resetPassword(data: Readonly<{ email: string }>) {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      RESET_PASSWORD,
      `${ACCOUNTS_ENDPOINT}/password-reset/`,
      'POST',
      data
    )(dispatch).catch(() => null);
  };
}

export const RESET_PASSWORD_CONFIRM = makeAsyncActionSet(
  'RESET_PASSWORD_CONFIRM'
);
export function resetPasswordConfirm(
  uid: string,
  token: string,
  data: Readonly<{ password: string }>
) {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      RESET_PASSWORD_CONFIRM,
      `${ACCOUNTS_ENDPOINT}/password-reset/confirm/`,
      'POST',
      Object.assign(
        {
          uid,
          token,
        },
        data
      )
    )(dispatch)
      .then(() => {
        return dispatch(push('/login'));
      })
      .catch(() => null);
  };
}
