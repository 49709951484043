import {
  dispatchGenericRequest,
  makeAsyncActionSet,
} from '@dabapps/redux-requests';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';

const ACCOUNTS_ENDPOINT = '/api/accounts';

export const LOGIN = makeAsyncActionSet('LOGIN');
export function login(
  data: Readonly<{ username: string; password: string }>,
  redirect: string = '/'
) {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      LOGIN,
      `${ACCOUNTS_ENDPOINT}/login/`,
      'POST',
      data
    )(dispatch)
      .then(() => {
        getLoggedInUser()(dispatch).then(() => {
          return dispatch(push(redirect));
        });
      })
      .catch(() => null);
  };
}

export const LOGOUT = makeAsyncActionSet('LOGOUT');
export function logout() {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      LOGOUT,
      `${ACCOUNTS_ENDPOINT}/logout/`,
      'POST'
    )(dispatch)
      .then(() => {
        return dispatch(push('/login'));
      })
      .catch(() => null);
  };
}

export const GET_LOGGED_IN_USER = makeAsyncActionSet('GET_LOGGED_IN_USER');
export function getLoggedInUser() {
  return (dispatch: Dispatch) => {
    return dispatchGenericRequest(
      GET_LOGGED_IN_USER,
      `${ACCOUNTS_ENDPOINT}/me/`,
      'GET'
    )(dispatch).catch(() => null);
  };
}
