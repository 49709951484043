import { Button } from '@dabapps/roe';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { InjectedFormProps, reduxForm } from 'redux-form';

import ErrorRenderer from '^/common/components/form/error-renderer';
import UserFormGroups from '^/users/components/user-form-group';
import { CREATE_USER } from '^/users/user-actions';
import { User } from '^/users/user-types';

const actions = [CREATE_USER];

interface OwnProps {
  onSubmit?: (values: User) => void;
}

type Props = OwnProps & InjectedFormProps<User, OwnProps> & WithTranslation;

export class CreateUserForm extends React.Component<Props> {
  public render() {
    const { t, handleSubmit, submitting, pristine } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <hr />
        <UserFormGroups />
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors']}
          showStatusErrors
        />
        <hr />
        <div className="form-buttons">
          <Button
            type="submit"
            className="primary pill"
            disabled={submitting || pristine}
          >
            {t('buttons.add-new-user')}
          </Button>
        </div>
      </form>
    );
  }
}

export default reduxForm<User, OwnProps>({
  form: 'createUserForm',
  destroyOnUnmount: true,
})(withTranslation('users')(CreateUserForm));
