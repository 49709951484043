import { AnyAction } from 'redux';

import { StoreState } from '^/common/types';
import { GET_USER_ROLES } from '^/roles/role-actions';

export function roles(
  state: StoreState['roles'] = null,
  action: AnyAction
): StoreState['roles'] | null {
  switch (action.type) {
    case GET_USER_ROLES.SUCCESS:
      if (!Array.isArray(action.payload.data)) {
        return state;
      }

      return action.payload.data;
    default:
      return state;
  }
}
