import { Button } from '@dabapps/roe';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { InjectedFormProps, reduxForm } from 'redux-form';

import ReportFormGroups from '^/reports/components/report-form-group';
import { REPORT_FORM_NAME } from '^/reports/report-constants';

type Props = InjectedFormProps<{}, {}> & WithTranslation;

export const ReportForm = (props: Props) => {
  const { t, handleSubmit, invalid, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <hr />
      <ReportFormGroups />
      <div className="form-buttons">
        <Button
          type="submit"
          className="primary pill"
          disabled={invalid || submitting}
        >
          {t('buttons.run-report')}
        </Button>
      </div>
    </form>
  );
};

export default reduxForm<{}, {}>({
  form: REPORT_FORM_NAME,
  destroyOnUnmount: true,
})(withTranslation('reports')(ReportForm));
