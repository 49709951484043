import { ContentBox, ContentBoxHeader } from '@dabapps/roe';
import { faFileDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';

export interface Props {
  header?: string;
  style?: string;
  link?: string;
}

export class DownloadCard extends React.PureComponent<Props> {
  public render() {
    const { header, style, link } = this.props;

    return (
      <a href={link}>
        <ContentBox className={classNames('button-card', style, {})}>
          <ContentBoxHeader className={classNames('card-header')}>
            <div className="card-header-button" />
            <h1>{header}</h1>
            <button className="card-header-button">
              <FontAwesomeIcon
                className="download-icon"
                size="2x"
                icon={faFileDownload}
              />
            </button>
          </ContentBoxHeader>
        </ContentBox>
      </a>
    );
  }
}

export default DownloadCard;
