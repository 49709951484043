import { AsyncActionSet } from '@dabapps/redux-requests';
import classNames from 'classnames';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WrappedFieldProps } from 'redux-form';

import ErrorRenderer from '^/common/components/form/error-renderer';
import { PatientStatus } from '^/patients/patient-types';

interface OwnProps {
  label: string;
  actions?: ReadonlyArray<AsyncActionSet>;
}

export type Props = OwnProps &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps &
  WithTranslation;

export class RenderStatusSelector extends React.PureComponent<Props> {
  public render() {
    const {
      t,
      input,
      label,
      actions,
      meta: { touched, error, warning },
    } = this.props;

    return (
      <div className="form-field">
        <label>{label}</label>
        <div
          className={classNames(
            'form-field-input',
            { error: touched && error },
            { warning: touched && warning }
          )}
        >
          <select
            {...input}
            className={classNames(
              'status-color-element',
              this.getStatusClass(input.value)
            )}
          >
            <option value="">{t('statuses.placeholder')}</option>
            <option value={PatientStatus.InProgress}>
              {t(`statuses.${PatientStatus.InProgress}`)}
            </option>
            <option value={PatientStatus.ScreeningBooked}>
              {t(`statuses.${PatientStatus.ScreeningBooked}`)}
            </option>
          </select>
        </div>
        {touched && (
          <ErrorRenderer
            actions={actions}
            fields={[input.name]}
            formErrors={[error, warning]}
          />
        )}
      </div>
    );
  }

  private getStatusClass(value: PatientStatus) {
    switch (value) {
      case PatientStatus.InProgress:
      case PatientStatus.ScreeningBooked:
        return 'in-progress';
      default:
        return 'cancelled';
    }
  }
}

export default withTranslation('patients')(RenderStatusSelector);
