import { FormGroup } from '@dabapps/roe';
import moment, { Moment } from 'moment';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { change, Field, formValueSelector } from 'redux-form';

import { validateDefined } from '^/common/components/form/date-picker-field';
import RenderDateField from '^/common/components/form/render-date-field';
import RenderDropdown from '^/common/components/form/render-dropdown';
import { DATE_FORMAT_ISO } from '^/common/constants';
import { StoreState } from '^/common/types';
import { REPORT_FORM_NAME } from '^/reports/report-constants';
import { DateOption } from '^/reports/reports-types';
import { Trial } from '^/trials/trial-types';

interface OwnProps {
  disabled?: boolean;
  startFieldName: string;
  endFieldName: string;
  filterDateName: string;
  selectedTrial?: Trial;
}

interface ConnectedProps {
  startDate?: string;
  endDate?: string;
  dateOption?: DateOption;
}

interface DispatchProps {
  change: typeof change;
}

export type Props = DispatchProps & OwnProps & ConnectedProps & WithTranslation;

class ReportDatePicker extends React.PureComponent<Props> {
  public render() {
    const { t, startFieldName, endFieldName, filterDateName } = this.props;
    const startDate = this.props.startDate
      ? moment(this.props.startDate, DATE_FORMAT_ISO)
      : undefined;
    const endDate = this.props.endDate
      ? moment(this.props.endDate, DATE_FORMAT_ISO)
      : undefined;
    const disabled =
      this.props.disabled ||
      (this.props.dateOption && this.props.dateOption !== DateOption.Custom);

    const options = [
      { label: t('fields.filter-date-custom'), value: DateOption.Custom },
      {
        label: t('fields.filter-date-past-month'),
        value: DateOption.PastMonth,
      },
      { label: t('fields.filter-date-past-week'), value: DateOption.PastWeek },
    ];

    if (this.props.selectedTrial && this.props.selectedTrial.fpfv_planned) {
      options.push({
        label: t('fields.filter-date-start-to-end'),
        value: DateOption.TrialStartToEnd,
      });
      options.push({
        label: t('fields.filter-date-start-to-today'),
        value: DateOption.TrialStartToToday,
      });
    }

    return (
      <>
        <FormGroup>
          <Field
            label={t('fields.filter-date')}
            options={options}
            name={filterDateName}
            component={RenderDropdown}
            onChange={this.onChangeDateFilter}
          />
          <Field
            label={t('fields.start-date')}
            placeholder={t('fields.start-date')}
            name={startFieldName}
            component={RenderDateField}
            disabled={disabled}
            validate={disabled ? undefined : validateDefined}
            maxDate={endDate}
          />
          <Field
            label={t('fields.end-date')}
            placeholder={t('fields.end-date')}
            name={endFieldName}
            component={RenderDateField}
            disabled={disabled}
            validate={disabled ? undefined : validateDefined}
            minDate={startDate}
          />
        </FormGroup>
      </>
    );
  }

  public setFormDates = (startDate: Moment, endDate?: Moment | null) => {
    this.props.change(
      REPORT_FORM_NAME,
      this.props.startFieldName,
      startDate.format(DATE_FORMAT_ISO)
    );

    this.props.change(
      REPORT_FORM_NAME,
      this.props.endFieldName,
      (endDate || moment()).format(DATE_FORMAT_ISO)
    );
  };

  public onChangeDateFilter = (
    _: React.ChangeEvent<HTMLInputElement> | undefined,
    value?: DateOption
  ) => {
    switch (value) {
      case DateOption.PastMonth: {
        this.setFormDates(moment().subtract(1, 'month'));
        break;
      }
      case DateOption.PastWeek: {
        this.setFormDates(moment().subtract(1, 'week'));
        break;
      }
      case DateOption.TrialStartToToday: {
        if (this.props.selectedTrial && this.props.selectedTrial.fpfv_planned) {
          this.setFormDates(this.props.selectedTrial.fpfv_planned);
        }
        break;
      }
      case DateOption.TrialStartToEnd: {
        if (this.props.selectedTrial && this.props.selectedTrial.fpfv_planned) {
          this.setFormDates(
            this.props.selectedTrial.fpfv_planned,
            this.props.selectedTrial.lpfv_planned
          );
        }
        break;
      }
      default:
        break;
    }
  };
}

export { ReportDatePicker as TestableReportDatePicker };

const formSelector = formValueSelector(REPORT_FORM_NAME);

export const mapStateToProps = (
  state: StoreState,
  ownProps: OwnProps
): ConnectedProps => ({
  startDate: formSelector(state, ownProps.startFieldName),
  endDate: formSelector(state, ownProps.endFieldName),
  dateOption: formSelector(state, ownProps.filterDateName),
});

export default compose<Props, OwnProps>(
  connect(mapStateToProps, {
    change,
  }),
  withTranslation('reports')
)(ReportDatePicker);
