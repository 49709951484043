import { faSignIn, faSignOut } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { StoreState } from '^/common/types';
import { getLoggedInUser, logout } from '^/login/login-actions';
import { getUserRoles } from '^/roles/role-actions';
import { User } from '^/users/user-types';

export interface DispatchProps {
  handleLogOut?: typeof logout;
  getUserRoles: typeof getUserRoles;
  getLoggedInUser: typeof getLoggedInUser;
}

export interface ConnectedProps {
  loggedInUser?: User;
}

export type LoginButtonProps = ConnectedProps &
  DispatchProps &
  RouteComponentProps<{}> &
  WithTranslation;

class LoginButton extends React.PureComponent<LoginButtonProps> {
  public componentDidMount() {
    if (this.props.loggedInUser && !this.props.loggedInUser.roles) {
      this.props.getLoggedInUser();
    }
  }

  public render() {
    const { t, location, loggedInUser } = this.props;

    if (!loggedInUser || loggedInUser.email === 'AnonymousUser') {
      const path = location.pathname || '/';

      return (
        <div className="user-panel">
          <Link to={`/login?next=${path}`}>
            <button className="login-button">
              <div className="user-panel-info">
                <span className="user-panel-name">{t('log-in')}</span>
              </div>
              <FontAwesomeIcon icon={faSignIn} />
            </button>
          </Link>
        </div>
      );
    }

    return (
      <div className="user-panel">
        <button className="login-button" onClick={this.props.handleLogOut}>
          <div className="user-panel-info">
            <span className="user-panel-name">
              {loggedInUser.full_name.length > 0
                ? loggedInUser.full_name
                : loggedInUser.email}
            </span>
            <span className="user-panel-role">
              {t(`users:roles.${loggedInUser.roles}`)}
            </span>
          </div>
          <FontAwesomeIcon icon={faSignOut} />
        </button>
      </div>
    );
  }
}

export { LoginButton as TestableLoginButton };

export const mapStateToProps = ({
  loggedInUser,
}: StoreState): ConnectedProps => {
  return {
    loggedInUser,
  };
};

export default withRouter(
  withTranslation('users')(
    connect(mapStateToProps, {
      handleLogOut: logout,
      getUserRoles,
      getLoggedInUser,
    })(LoginButton)
  )
);
