import { Column, Row } from '@dabapps/roe';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Field, formValueSelector } from 'redux-form';

import RenderField from '^/common/components/form/render-field';
import { StoreState } from '^/common/types';
import ReportFormFields from '^/reports/components/report-form-fields';
import { FIELDS, REPORT_FORM_NAME } from '^/reports/report-constants';

interface ConnectedProps {
  isComparison: boolean;
}

export type Props = ConnectedProps & WithTranslation;

export class ReportFormGroups extends React.PureComponent<Props> {
  public render() {
    const { t, isComparison } = this.props;

    return (
      <>
        <ReportFormFields fieldNames={FIELDS.SINGLE} />
        <Column xs={12}>
          <Row className="display-flex">
            <Column md={4}>
              <hr />
            </Column>
            <Column xs={12} md={4} className="compare-report">
              <Field
                name={FIELDS.IS_COMPARISON}
                component={RenderField}
                type="checkbox"
                sideLabel={t('compare-with-another')}
              />
            </Column>
            <Column md={4}>
              <hr />
            </Column>
          </Row>
        </Column>
        {isComparison && (
          <ReportFormFields fieldNames={FIELDS.COMPARISON} isComparison />
        )}
      </>
    );
  }
}

export const mapStateToProps = (state: StoreState): ConnectedProps => {
  return {
    isComparison: formValueSelector(REPORT_FORM_NAME)(
      state,
      FIELDS.IS_COMPARISON
    ),
  };
};

export default compose<Props, {}>(
  connect(mapStateToProps),
  withTranslation('reports')
)(ReportFormGroups);
