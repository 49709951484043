import { Button } from '@dabapps/roe';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React from 'react';

interface OwnProps {
  onClick?: () => void;
  progress: number;
}

export default class ProgressButton extends React.PureComponent<OwnProps> {
  public render() {
    const { progress } = this.props;
    const style = {
      width: progress > 0 ? progress + '%' : '0%',
    };

    return (
      <Button
        onClick={this.props.onClick}
        className={classnames(
          'progress-button',
          {
            complete: progress >= 100,
          },
          { declined: progress < 0 }
        )}
      >
        <div style={style}>
          <p>{this.props.children}</p>
          <FontAwesomeIcon size="1x" icon={faChevronDown} />
        </div>
      </Button>
    );
  }
}
