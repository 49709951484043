import { makeAsyncActionSet } from '@dabapps/redux-requests';
import { Column, Row } from '@dabapps/roe';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import compose from 'recompose/compose';

import {
  createWidget,
  InjectedWidgetProps,
} from '^/reports/components/hoc/create-widget';
import ScreeningsData from './screening-data';
import ScreeningsHeader from './screening-header';

export interface ScreeningsData {
  global_trial_id: string | null;
  site_name: string | null;
  start_date: string;
  end_date: string;
  screenings_booked: number;
  recruited: number;
  recruited_rate: number;
  all_time_total: number;
  conversion_rate: number;
  weekly_average: number;
  weekly_average_rate: number;
  screenings_completed: number;
}

type Props = InjectedWidgetProps<ScreeningsData> & WithTranslation;

export const ScreeningsWidget = (props: Props) => {
  const { t } = props;
  const {
    screenings_booked,
    all_time_total,
    screenings_completed,
    weekly_average,
    weekly_average_rate,
    conversion_rate,
    recruited,
    recruited_rate,
  } = props.data;

  if (isNaN(screenings_booked)) {
    return <p>{t('empty-state')}</p>;
  }

  return (
    <Row
      className={classNames('screenings', {
        'screenings-comparison': props.isComparison,
      })}
    >
      <Column className="screenings-row-wrapper" md={12} lg={6}>
        <h3 className="screenings-row-header">
          {t('summary.potential-recruits')}
        </h3>
        {props.isComparison && <ScreeningsHeader {...props.data} />}
        <ScreeningsData
          value={recruited}
          rate={recruited_rate}
          description={t('summary.labels.past-week')}
        />
        <ScreeningsData
          value={weekly_average}
          rate={weekly_average_rate}
          description={t('summary.labels.weekly-average')}
        />
        <ScreeningsData
          value={all_time_total}
          description={t('summary.labels.all-time-total')}
        />
      </Column>
      <Column className="screenings-row-wrapper" md={12} lg={6}>
        <h3 className="screenings-row-header screenings-color-alt">
          {t('summary.screenings')}
        </h3>
        <ScreeningsData
          value={screenings_booked}
          description={t('summary.labels.screenings-booked')}
          colorAlt
        />
        <ScreeningsData
          value={screenings_completed}
          description={t('summary.labels.screenings-complete')}
          colorAlt
        />
        <ScreeningsData
          value={`${conversion_rate}%`}
          description={t('summary.labels.conversion-rate')}
          subDescription={
            <span>
              <Trans i18nKey="reports:summary.labels.conversion-rate-subtitle">
                (POTENTIAL <FontAwesomeIcon icon={faArrowRight} /> SCREENING)
              </Trans>
            </span>
          }
          colorAlt
        />
      </Column>
    </Row>
  );
};

const SCREENINGS = makeAsyncActionSet('SCREENINGS');

export default compose<Props, {}>(
  createWidget<ScreeningsData>({
    icon: null,
    title: props =>
      props.isComparison ? (
        <h2>
          <Trans i18nKey="reports:summary.comparative-report-heading">
            Comparative Report
          </Trans>
        </h2>
      ) : props.data ? (
        <ScreeningsHeader {...props.data} />
      ) : (
        <Trans i18nKey="reports:summary.heading">Screening Report</Trans>
      ),
    url: '/api/reports/dashboard-report/',
    actionSet: SCREENINGS,
    halfWidth: false,
  }),
  withTranslation('reports')
)(ScreeningsWidget);
